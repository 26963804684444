import React from 'react';

const AlertMessage = ({ message, color }) => {
  const alertColor = color || 'red';
  return (
    <div className={`alert-message alert-message--${alertColor}`}>
      <span>{message}</span>
    </div>
  );
};

export default AlertMessage;
