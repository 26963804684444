import React from 'react';
import { MorphReplaceResize } from 'react-svg-morph';
import { Logo1, Logo2, Logo3 } from './logo';

function AnimatedLogo({ startTransition, transitionType }) {
  if (transitionType === 'transition1to2')
    return (
      <div className={`step-card ${startTransition ? 'step-card--show' : ''}`}>
        <MorphReplaceResize width={304} height={200} viewBox='0 40 304 200' rotation='none' preserveAspectRatio='none'>
          {!startTransition ? <Logo1 key='logo1' /> : <Logo2 key='logo2' />}
        </MorphReplaceResize>
      </div>
    );
  if (transitionType === 'transition2to3')
    return (
      <div className={`step-card ${startTransition ? 'step-card--show' : ''}`}>
        <MorphReplaceResize width={304} height={200} viewBox='0 40 304 200' rotation='none' preserveAspectRatio='none'>
          {startTransition ? <Logo2 key='logo2' /> : <Logo3 key='logo3' />}
        </MorphReplaceResize>
      </div>
    );
  if (transitionType === 'transition2to1')
    return (
      <div className={`step-card ${startTransition ? 'step-card--show' : ''}`}>
        <MorphReplaceResize width={304} height={200} viewBox='0 40 304 200' rotation='none' preserveAspectRatio='none'>
          {startTransition ? <Logo2 key='logo2' /> : <Logo1 key='logo1' />}
        </MorphReplaceResize>
      </div>
    );
  if (transitionType === 'transition3to1')
    return (
      <div className={`step-card ${startTransition ? 'step-card--show' : ''}`}>
        <MorphReplaceResize width={304} height={200} viewBox='0 40 304 200' rotation='none' preserveAspectRatio='none'>
          {startTransition ? <Logo3 key='logo3' /> : <Logo1 key='logo1' />}
        </MorphReplaceResize>
      </div>
    );
  if (transitionType === 'transition3to2')
    return (
      <div className={`step-card ${startTransition ? 'step-card--show' : ''}`}>
        <MorphReplaceResize width={304} height={200} viewBox='0 40 304 200' rotation='none' preserveAspectRatio='none'>
          {!startTransition ? <Logo3 key='logo3' /> : <Logo2 key='logo2' />}
        </MorphReplaceResize>
      </div>
    );
}

AnimatedLogo.defaultProps = {
  startTransition: true,
  transitionType: 'transition1to2',
};

export default AnimatedLogo;
