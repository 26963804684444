import React, { useContext, useEffect, useRef, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { IS_POLP_MEXICO } from '../../../config/constants';
import { getTranslation } from '../../../locale/texts';
import { getSchoolText, getSchools } from '../../../services/rosterDataService';
import { StoreContext } from '../../../store/store';
import InputText from '../../atoms/InputText';

function SignUpFormProfile({ initialValues, onFormChange, showLicenseInput }) {
  const [state, dispatch] = useContext(StoreContext);
  const surnameRef = useRef(null);
  const schoolRef = useRef(null);
  const [name, setName] = useState(initialValues.name);
  const [surname, setSurname] = useState(initialValues.surname);
  const [school, setSchool] = useState(initialValues.school);
  const [license, setLicense] = useState(initialValues?.license);

  useEffect(() => {
    onFormChange({ name, surname, school, license });
  }, [name, surname, school, license]);

  let schoolSearch = '';

  const schoolSearchChange = (value) => {
    schoolSearch = value;
  };

  const getSchoolsFromService = async (value) => {
    const schoolsResult = await getSchools(value);

    if (schoolsResult.error) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text: getTranslation('signup_form_profile_error_getting_schools'),
        },
      });

      return [];
    }

    return schoolsResult.schools;
  };

  // handle selection
  const schoolSelectedChange = (value) => {
    if (value === null) value = {};
    setSchool(value);
  };

  const nameEnterPressed = () => {
    surnameRef?.current.focus();
  };

  const surnameEnterPressed = () => {
    schoolRef?.current.focus();
  };

  return (
    <div className='sign-up-form-profile animated fade-in'>
      <div className='sign-up-form-profile__title'>{getTranslation('signup_form_profile_title')}</div>
      <div className='sign-up-form-profile__subtitle'>{getTranslation('signup_form_profile_subtitle')}</div>

      <div className='sign-up-form-profile__form'>
        <div className='sign-up-form-profile__input-wrapper'>
          <div className='sign-up-form-profile__input'>
            <InputText
              value={name}
              onChange={setName}
              label={getTranslation('signup_form_profile_name_label')}
              onEnterPressed={nameEnterPressed}
            />
          </div>
          <div className='sign-up-form-profile__input'>
            <InputText
              value={surname}
              onChange={setSurname}
              label={getTranslation('signup_form_profile_surname_label')}
              refFromParent={surnameRef}
              onEnterPressed={surnameEnterPressed}
            />
          </div>
        </div>
        {showLicenseInput && (
          <div className='sign-up-form-profile__input'>
            <InputText
              value={initialValues.license}
              onChange={setLicense}
              label={getTranslation('signup_form_profile_license_label')}
            />
          </div>
        )}

        {!IS_POLP_MEXICO && (
          <>
            <div className='sign-up-form-profile__school-title'>
              {getTranslation('signup_form_profile_school_title')}
            </div>
            <div className='sign-up-form-profile__school-search'>
              <AsyncSelect
                cacheOptions
                defaultOptions={Object.keys(school).length > 0 ? [school] : []}
                value={Object.keys(school).length === 0 ? null : school}
                getOptionLabel={(e) => getSchoolText(e)}
                getOptionValue={(e) => e.id}
                loadOptions={getSchoolsFromService}
                onInputChange={schoolSearchChange}
                onChange={schoolSelectedChange}
                className='school-async-select'
                classNamePrefix='select'
                isClearable={true}
                maxMenuHeight='200'
                menuPlacement='auto'
                placeholder={getTranslation('signup_form_profile_school_placeholder')}
                loadingMessage={() => getTranslation('signup_form_profile_school_loading_options')}
                noOptionsMessage={() => getTranslation('signup_form_profile_school_no_options')}
                ref={schoolRef}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default SignUpFormProfile;
