import en from './languages/en';
import es from './languages/es';

// Inlcuir en TEXTS todos los fichero de texto importados
const TEXTS = { es, en };

// Por defecto, si no puede obtener el idioma o no tiene textos en ese idioma se queda 'es' (español)
let NAVIGATOR_LANGUAGE = navigator && navigator.language ? navigator.language.split('-')[0] : 'es';
if (!TEXTS[NAVIGATOR_LANGUAGE]) NAVIGATOR_LANGUAGE = 'es';

const getLanguageCode = () => NAVIGATOR_LANGUAGE;

const getTranslation = (code) => {
  if (TEXTS[NAVIGATOR_LANGUAGE] && TEXTS[NAVIGATOR_LANGUAGE][code]) return TEXTS[NAVIGATOR_LANGUAGE][code];

  return code;
};

export { getLanguageCode, getTranslation };
