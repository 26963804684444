import React from 'react';
import Chica from '../../../assets/img/chica.png';
import Monstruo from '../../../assets/img/monstruo.png';
import Paloma from '../../../assets/img/paloma.png';
import Palomitas from '../../../assets/img/palomitas.png';

function BackgroundDecoration({ type }) {
  let image;

  switch (type) {
    case 'paloma':
      image = Paloma;
      break;
    case 'palomitas':
      image = Palomitas;
      break;
    case 'chica':
      image = Chica;
      break;
    case 'monstruo':
      image = Monstruo;
      break;
    default:
      image = Paloma;
  }

  return (
    <div className='background-decoration'>
      <div className={`background-decoration__image background-decoration__image--${type}`}>
        <img src={image} alt='' />
      </div>
    </div>
  );
}

export default BackgroundDecoration;
