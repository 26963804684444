import React, { createRef, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { getTranslation } from '../../../locale/texts';
import Checkbox from '../../atoms/Checkbox';

function PackagesItem({ packageData, value, setValue, id, getAppIcon }) {
  const packageContainerRef = useRef();
  const subjectsContainerRef = useRef();
  const subjectsRefs = useMemo(() => packageData.subjects.map(() => createRef()), []);
  const toogleVisibilityButton = useRef(null);
  const [showMore, setShowMore] = useState(false);
  const [hiddenStartIndex, setHiddenStartIndex] = useState(-1);
  const [appIcon, setAppIcon] = useState(null);

  useLayoutEffect(() => {
    setAppIcon(getAppIcon(packageData.app));
    setSubjectsVisibility();
  }, []);

  useLayoutEffect(() => {
    setSubjectsVisibilityDependingOnToogleButton();
  }, [hiddenStartIndex]);

  const setSubjectsVisibility = () => {
    // Obtener contendor para ver su límite horizontal (hay varios, pero son iguales, basta con el primero)
    const containerRightX = packageContainerRef.current.getBoundingClientRect().right;

    // Encontrar el índice de la primera asignatura a ocultar
    const subjectsLength = subjectsRefs.length;
    let firstHiddenSubject = -1;

    for (let s = 0, sMax = subjectsLength; s < sMax; s++) {
      const subjectElement = subjectsRefs[s].current;
      const subjectRect = subjectElement.getBoundingClientRect();

      if (subjectRect.left > containerRightX || subjectRect.right > containerRightX) {
        if (firstHiddenSubject === -1) {
          firstHiddenSubject = s;
        }
        subjectElement.style.display = 'none';
      }
    }

    setHiddenStartIndex(firstHiddenSubject);
  };

  const setSubjectsVisibilityDependingOnToogleButton = () => {
    if (hiddenStartIndex > 0) {
      // Obtener contendor para ver su límite horizontal (hay varios, pero son iguales, basta con el primero)
      const containerRightX = packageContainerRef.current.getBoundingClientRect().right;

      // Comprobar si el botón de mostrar/ocultar se ve bien
      const toogleElement = toogleVisibilityButton.current;
      const toogleRect = toogleElement.getBoundingClientRect();

      // Si no se ve entero se quita la asignatura previa
      // Esto hace que se cambie el estado y por el useEffect vuelva a entrar aquí
      if (toogleRect.left > containerRightX || toogleRect.right > containerRightX) {
        const newHiddenStartIndex = hiddenStartIndex - 1;
        subjectsRefs[newHiddenStartIndex].current.style.display = 'none';

        setHiddenStartIndex(newHiddenStartIndex);
      }
    }
  };

  const toogleSubjectsVisibility = (_showMore) => {
    for (let s = hiddenStartIndex, sMax = subjectsRefs.length; s < sMax; s++) {
      if (_showMore) subjectsRefs[s].current.style.display = 'block';
      else subjectsRefs[s].current.style.display = 'none';
    }
  };

  const toogleVisibility = () => {
    const newShowMore = !showMore;

    subjectsContainerRef.current.style.flex = newShowMore === true ? 'auto' : 'none';
    toogleSubjectsVisibility(newShowMore);
    setShowMore(newShowMore);
  };

  return (
    <div className='packages-item' ref={packageContainerRef}>
      <div className='packages-item__name'>
        <Checkbox
          value={value}
          onChange={setValue}
          id={id}
          label={packageData.name}
          checkmarkMiddle={true}
          icon={appIcon}
          iconTooltip={getTranslation(packageData.app)}
        />
      </div>

      <div className='packages-item__options' ref={subjectsContainerRef}>
        {/* Listado de asignaturas */}
        {packageData.subjects.map((item, index) => (
          <div key={index} ref={subjectsRefs[index]} className={'package-subject packages-item__option-tag'}>
            {item}
          </div>
        ))}
        {/* Botón Mostrar/Ocultar, si es necesario */}
        {hiddenStartIndex > -1 && (
          <div
            ref={toogleVisibilityButton}
            className='packages-item__option-tag packages-item__option-tag--show-more'
            onClick={() => toogleVisibility()}
          >
            {showMore
              ? getTranslation('demo_hide')
              : `${getTranslation('demo_show')} (${packageData.subjects.length - hiddenStartIndex})`}
          </div>
        )}
      </div>
    </div>
  );
}

export default PackagesItem;
