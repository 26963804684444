import React from 'react';

function SignUpSteps({ currentStep }) {
  return (
    <div className='sign-up-steps'>
      <div
        className={`sign-up-steps__button sign-up-steps__button--step${currentStep} ${
          currentStep === 1 ? 'sign-up-steps__button--active' : ''
        }`}
      >
        1
      </div>
      <div className='sign-up-steps__separator'></div>
      <div
        className={`sign-up-steps__button sign-up-steps__button--step${currentStep} ${
          currentStep === 2 ? 'sign-up-steps__button--active' : ''
        }`}
      >
        2
      </div>
      <div className='sign-up-steps__separator'></div>
      <div
        className={`sign-up-steps__button sign-up-steps__button--step${currentStep} ${
          currentStep === 3 ? 'sign-up-steps__button--active' : ''
        }`}
      >
        3
      </div>
    </div>
  );
}

export default SignUpSteps;
