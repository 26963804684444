import {
  ORACULO_API_AUTH,
  ORACULO_SSO_URL,
  POLP_API_URL,
  POLP_FEDERATE_APP_ID,
  POLP_JSON_WEB_TOKEN,
} from '../config/constants';
import { get, post } from './requestService';

// Login en Oraculo con credenciales (para que sea global)
export const loginOraculoWithCredentials = async (email, password, getRefreshToken) => {
  const sessionResult = await post(
    ORACULO_API_AUTH + 'sessions',
    { user: email, pass: password, refresh: getRefreshToken },
    { fid: POLP_FEDERATE_APP_ID }
  );

  if (sessionResult.networkError === true) return { error: 'network error' };

  if (!sessionResult.sid || !sessionResult.token_id) {
    if (sessionResult.error === 'user | pass') return { error: 'user and password mismatch' };

    return { error: JSON.stringify(sessionResult) };
  }

  return { sid: sessionResult.sid, tokenId: sessionResult.token_id, refreshToken: sessionResult?.refresh_token };
};

// Login en Oraculo con token y provider Google/Microsoft (para que sea global)
export const loginOraculoWithToken = async (token, provider) => {
  const sessionResult = await post(
    ORACULO_API_AUTH + 'sessions',
    { identity_provider: provider, token: token },
    { fid: POLP_FEDERATE_APP_ID }
  );

  if (sessionResult.networkError === true) return { error: 'network error' };

  if (sessionResult.error === 'identity_provider | token') return { error: 'user not found' };

  if (!sessionResult.sid || !sessionResult.token_id) return { error: JSON.stringify(sessionResult) };

  return { sid: sessionResult.sid, tokenId: sessionResult.token_id };
};

// Login en Polp para obtener SU JWToken
export const loginOauth = async (providerToken, identityProvider) => {
  const loginOauthResult = await post(POLP_API_URL + 'login-oauth', {
    identity_provider: identityProvider,
    token: providerToken,
  });

  if (loginOauthResult.networkError === true) return { error: 'network error' };

  if (loginOauthResult.status !== 'success') {
    const errorCode = loginOauthResult?.error?.code;
    const errorMessage = loginOauthResult?.error?.message;

    if (errorCode === 404 && errorMessage === 'User not found') return { error: 'user not found' };

    return { error: JSON.stringify(loginOauthResult) };
  }

  return { jwt: loginOauthResult.data.token };
};

// Cerrar sesión
export const logout = () => {
  try {
    window.makeLogout();
  } catch (error) {}

  localStorage.removeItem(POLP_JSON_WEB_TOKEN);
};

// Establece sesión global cargando cookie en dominio correspondiente (por eso el iframe)
export const setOraculoGlobalSession = (sid) => {
  const oraculoIframe = document.createElement('iframe');
  oraculoIframe.src = ORACULO_SSO_URL + sid;
  oraculoIframe.width = '0';
  oraculoIframe.height = '0';
  oraculoIframe['data-log'] = 'IFRAME GENERAR COOKIE GLOBAL';
  document.querySelector('body').appendChild(oraculoIframe);
};

// Obtener datos del usuario
export const getUserData = async (jsonWebToken) => {
  const whoAmIResult = await get(POLP_API_URL + 'whoami', null, {
    Authorization: jsonWebToken,
  });

  if (whoAmIResult.networkError === true) return null;

  if (whoAmIResult.status === 'success') return whoAmIResult.data;

  return null;
};
