import React, { createContext, useReducer } from 'react';
import loading from './loadingReducer';
import message from './messageReducer';
import social from './socialReducer';
import user from './userReducer';

// Tanto initialState como reducers hay que inicializarlos con lo que se vaya importando
const initialState = {
  [loading.key]: loading.initialState,
  [message.key]: message.initialState,
  [user.key]: user.initialState,
  [social.key]: social.initialState,
};

const reducers = {
  [loading.key]: loading.reducer,
  [message.key]: message.reducer,
  [user.key]: user.reducer,
  [social.key]: social.reducer,
};

const combineReducers = (slices) => (state, action) => {
  // console.log('### Action -> ' + action.type + ',  payload:', action.payload, '\n\n');

  return Object.keys(slices).reduce((acc, prop) => {
    return {
      ...acc,
      [prop]: slices[prop](acc[prop], action),
    };
  }, state);
};

const StoreContext = createContext();

const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(combineReducers(reducers), initialState);

  return <StoreContext.Provider value={[state, dispatch]}>{children}</StoreContext.Provider>;
};

export { StoreContext, StoreProvider };
