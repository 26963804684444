import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppEdv from '../../../assets/img/landing/app-edv.png';
import AppEdvPlus from '../../../assets/img/landing/app-edvplus.png';
import AppGoSteam from '../../../assets/img/landing/app-gosteam.png';
import AppTatum from '../../../assets/img/landing/app-tatum.png';
import ImgBici from '../../../assets/img/landing/landing_bici.png';
import ImgChica from '../../../assets/img/landing/landing_chica.png';
import ImgMac from '../../../assets/img/landing/landing_mac.png';
import ImgPaloma from '../../../assets/img/landing/landing_paloma.png';
import ImgPalomitas from '../../../assets/img/landing/landing_palomitas.png';
import ImgPhone from '../../../assets/img/landing/landing_phone.png';
import LogoAdditio from '../../../assets/img/landing/logos_additio.png';
import LogoByme from '../../../assets/img/landing/logos_byme.png';
import LogoEdpuzzle from '../../../assets/img/landing/logos_edpuzzle.png';
import LogoGenially from '../../../assets/img/landing/logos_genially.png';
import LogoGlobaleduca from '../../../assets/img/landing/logos_globaleduca.png';
import LogoMatific from '../../../assets/img/landing/logos_matific.png';
import PolpLogo from '../../../assets/img/landing/polp_logo_landing.png';
import PolpLogoHeader from '../../../assets/img/landing/polp_logo_landing_header.png';
import { IS_POLP_MEXICO } from '../../../config/constants';
import { getTranslation } from '../../../locale/texts';
import Button from '../../atoms/Button';
import FadeInSection from '../../components/FadeInSection';
import Footer from '../../components/Footer';
import SoonCard from '../../components/SoonCard';

function LandingView() {
  const navigate = useNavigate();
  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    document.addEventListener('scroll', trackScroll);

    return () => {
      document.removeEventListener('scroll', trackScroll);
    };
  }, []);

  const handleGoToHelp = () => {
    navigate('/help');
  };
  const handleGoToPolp = () => {
    navigate('/home');
  };

  const trackScroll = () => {
    setSticky(window.scrollY > 800);
  };

  return (
    <div className='landing-view'>
      <div className={`landing-view__header ${sticky ? 'sticky animated fade-in' : ''}`}>
        <img src={PolpLogoHeader} alt='POLP' />
        <Button text={getTranslation('landing_go_to_polp')} color='green' onClick={handleGoToPolp} />
      </div>
      <div className='landing-view__section'>
        <div className='landing-view__chica-img'>
          <FadeInSection fadeFrom='left'>
            <img src={ImgChica} alt='' />
          </FadeInSection>
        </div>

        <div className='landing-view__title-logo'>
          <img src={PolpLogo} alt='POLP' />
        </div>
        <FadeInSection>
          <div className='landing-view__subtitle'>{getTranslation('landing_subtitle')}</div>
          <div className='landing-view__btn'>
            <Button text={getTranslation('landing_go_to_polp')} color='green' onClick={handleGoToPolp} />
          </div>
        </FadeInSection>
      </div>
      <div className='landing-view__section'>
        <div className='landing-view__paloma-img'>
          <img src={ImgPaloma} alt='' />
        </div>

        <FadeInSection>
          <div className='landing-view__title'>{getTranslation('landing_section_1_title')}</div>
          <div className='landing-view__text'>{getTranslation('landing_section_1_text')}</div>
          <div className='landing-view__cta'>{getTranslation('landing_section_1_cta')}</div>
          <div className='landing-view__btn'>
            {!IS_POLP_MEXICO && (
              <Button text={getTranslation('landing_ask_for_demo')} color='blue' onClick={handleGoToPolp} />
            )}
          </div>
          <div className='landing-view__apps'>
            <div>
              <img src={AppEdv} alt='' />
            </div>
            <div>
              <img src={AppGoSteam} alt='' />
            </div>
            <div>
              <div className='landing-view__apps-tag'>NOVEDAD</div>
              <img src={AppEdvPlus} alt='' />
            </div>
            <div>
              <img src={AppTatum} alt='' />
            </div>
          </div>
        </FadeInSection>
      </div>
      <div className='landing-view__section'>
        <div className='landing-view__palomitas-img'>
          <FadeInSection fadeFrom='left'>
            <img src={ImgPalomitas} alt='' />
          </FadeInSection>
        </div>

        <FadeInSection>
          <div className='landing-view__section-content'>
            <div className='landing-view__title'>{getTranslation('landing_section_2_title')}</div>
            <div className='landing-view__text'>{getTranslation('landing_section_2_text')}</div>
          </div>
        </FadeInSection>

        <div className='landing-view__mac-img'>
          <FadeInSection fadeFrom='right'>
            <img src={ImgMac} alt='' />
          </FadeInSection>
        </div>
      </div>
      <div className='landing-view__section'>
        <div className='landing-view__phone-img'>
          <FadeInSection fadeFrom='left'>
            <img src={ImgPhone} alt='' />
          </FadeInSection>
        </div>

        <FadeInSection>
          <div className='landing-view__section-content'>
            <div className='landing-view__title'>{getTranslation('landing_section_3_title')}</div>
            <div className='landing-view__text'>{getTranslation('landing_section_3_text')}</div>
          </div>
        </FadeInSection>
        <div className='landing-view__bici-img'>
          <FadeInSection fadeFrom='right'>
            <img src={ImgBici} alt='' />
          </FadeInSection>
        </div>
      </div>
      <div className='landing-view__section'>
        <FadeInSection>
          <div className='landing-view__cta'>{getTranslation('landing_section_4_cta')}</div>
          <div className='landing-view__btn'>
            <Button text={getTranslation('landing_go_to_polp')} color='blue' onClick={handleGoToPolp} />
          </div>
        </FadeInSection>
        {!IS_POLP_MEXICO && (
          <FadeInSection>
            <div className='landing-view__soon'>{getTranslation('landing_soon')}</div>
            <div className='landing-view__soon-cards'>
              {<SoonCard logo={LogoAdditio} text={getTranslation('landing_soon_1_text')} />}
              <SoonCard logo={LogoEdpuzzle} text={getTranslation('landing_soon_2_text')} />
              <SoonCard logo={LogoGenially} text={getTranslation('landing_soon_3_text')} />
              <SoonCard logo={LogoMatific} text={getTranslation('landing_soon_4_text')} />
              <SoonCard logo={LogoGlobaleduca} text={getTranslation('landing_soon_5_text')} />
              {<SoonCard logo={LogoByme} text={getTranslation('landing_soon_6_text')} />}
            </div>
          </FadeInSection>
        )}
      </div>
      <div className='landing-view__footer'>
        <Footer type='full' onClickHelp={handleGoToHelp} />
      </div>
    </div>
  );
}

export default LandingView;
