import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import HelpIcon from '../../../assets/icons/help.svg';
import { IS_POLP_MEXICO } from '../../../config/constants';
import { getTranslation } from '../../../locale/texts';
import { StoreContext } from '../../../store/store';

// Types: home, section

function Footer({ type, onClickHelp }) {
  const navigate = useNavigate();
  const [state, dispatch] = useContext(StoreContext);

  const handleGoToLegal = () => {
    navigate('/legal');
  };

  const handleGoToPrivacy = () => {
    navigate('/privacy');
  };

  const handleGoToCookies = () => {
    navigate('/cookies');
  };

  const isTeacher = () => state?.user?.role && state.user.role.toLowerCase() === 'teacher';

  const showOnlyCredits = () => {
    switch (type) {
      case 'full':
        return false;

      case 'profile':
        return isTeacher();

      case 'help':
      default:
        return true;
    }
  };

  return (
    <div className='footer'>
      {!showOnlyCredits() && (
        <>
          {isTeacher() && (
            <div className='footer__help-btn' onClick={onClickHelp}>
              <img src={HelpIcon} alt='' />
              {getTranslation('home_footer_help')}
            </div>
          )}
          {!isTeacher() && <div></div>}

          <div className='footer__legal'>
            <div onClick={handleGoToLegal}>{getTranslation('home_footer_legal')}</div>
            <div onClick={handleGoToPrivacy}>{getTranslation('home_footer_privacy')}</div>
            <div onClick={handleGoToCookies}>{getTranslation('home_footer_cookies')}</div>
          </div>
        </>
      )}
      {showOnlyCredits() && <div></div>}
      <div className='footer__credits'>
        {getTranslation(IS_POLP_MEXICO ? 'home_footer_credits_mx' : 'home_footer_credits')}
      </div>
    </div>
  );
}

export default Footer;
