import React from 'react';
import SignUpView from '../../modules/views/SignUpView/SignUpView';

function SignUpPage() {
  return (
    <div className='sign-up-page'>
      <SignUpView />
    </div>
  );
}

export default SignUpPage;
