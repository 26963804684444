import React from 'react';
import ProfileView from '../../modules/views/ProfileView/ProfileView';

function ProfilePage({ logout }) {
  return (
    <div className='profile-page'>
      <ProfileView logout={logout} />
    </div>
  );
}

export default ProfilePage;
