import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { getTranslation } from '../../../locale/texts';
import { getGrades, getSubjects } from '../../../services/rosterDataService';
import { StoreContext } from '../../../store/store';
import TwoLevelsSelector from '../TwoLevelsSelector';

// TODO: Estilos Multiselects

function SignUpFormCourse({ initialValues, onFormChange }) {
  const [state, dispatch] = useContext(StoreContext);
  const [gradesOptions, setGradesOptions] = useState([]);
  const [subjectsOptions, setSubjectsOptions] = useState([]);
  const [selectedGrades, setSelectedGrades] = useState(initialValues.grades);
  const [selectedSubjects, setSelectedSubjects] = useState(initialValues.subjects);

  useEffect(() => {
    onFormChange({ grades: selectedGrades, subjects: selectedSubjects });
  }, [selectedGrades, selectedSubjects]);

  useEffect(() => {
    getGradesFromService();
    getSubjectsFromService();
  }, []);

  const getGradesFromService = async () => {
    const _grades = await getGrades();

    if (_grades.error) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text: getTranslation('signup_form_course_error_getting_grades'),
        },
      });

      return;
    }

    setGradesOptions(_grades);
  };

  const getSubjectsFromService = async () => {
    const _subjects = await getSubjects();

    if (_subjects.error) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text: getTranslation('signup_form_course_error_getting_subjects'),
        },
      });

      return;
    }

    setSubjectsOptions(_subjects);
  };

  const handlerSubjects = (values, event) => {
    setSelectedSubjects(values);
  };

  return (
    <div className='sign-up-form-course animated fade-in'>
      <div className='sign-up-form-course__title'>{getTranslation('signup_form_course_title')}</div>
      <div className='sign-up-form-course__subtitle'>{getTranslation('signup_form_course_subtitle')}</div>
      <div className='sign-up-form-course__form'>
        <div className='sign-up-form-course__select'>
          <TwoLevelsSelector
            selectedOptions={selectedGrades}
            setSelectedOptions={setSelectedGrades}
            value={selectedGrades}
            options={gradesOptions}
            reference={null}
            isSearchable={true}
            placeholder={getTranslation('signup_form_course_grades_select')}
            maxMenuHeight='200'
          />
        </div>

        <div className='sign-up-form-course__select'>
          <Select
            // defaultValue={[colourOptions[2], colourOptions[3]]}
            value={selectedSubjects}
            onChange={handlerSubjects}
            isMulti
            name='subjects'
            options={subjectsOptions}
            className='basic-multi-select'
            classNamePrefix='select'
            isSearchable={true}
            placeholder={getTranslation('signup_form_course_subjects_select')}
            components={{ IndicatorSeparator: false }}
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            maxMenuHeight='200'
            menuPlacement='auto'
          />
        </div>
      </div>
    </div>
  );
}

export default SignUpFormCourse;
