import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowIcon from '../../../assets/icons/arrow-sm-right.svg';
import PolpLogo from '../../../assets/polp_logo_00.svg';
import { ORACULO_PROVIDER, POLP_API_URL, POLP_JSON_WEB_TOKEN } from '../../../config/constants';
import { getTranslation } from '../../../locale/texts';
import {
  getUserData,
  loginOauth,
  loginOraculoWithCredentials,
  setOraculoGlobalSession
} from '../../../services/loginService';
import { post } from '../../../services/requestService';
import { validatePassword, validatePasswordLength } from '../../../services/validationService';
import { StoreContext } from '../../../store/store';
import CloseButton from '../../atoms/CloseButton';
import InputPassword from '../../atoms/InputPassword';

// TODO: Login layout component

function RecoverView() {
  const [state, dispatch] = useContext(StoreContext);
  const navigate = useNavigate();

  const [recoverToken, setRecoverToken] = useState('');
  const [recoverEmail, setRecoverEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isPasswordLengthValid, setIsPasswordLengthValid] = useState(false);
  const [isRepeatPasswordValid, setIsRepeatPasswordValid] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const searchToken = queryParams.get('token');
    const searchEmail = decodeURIComponent(queryParams.get('email'));

    if (!searchToken || !searchEmail) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text: getTranslation('recover_error_link'),
        },
      });
    } else {
      setRecoverToken(searchToken);
      setRecoverEmail(searchEmail);
    }
  }, []);

  // Al modificar la password se comprueba si es válida
  useEffect(() => {
    setIsPasswordValid(validatePassword(password));
    setIsPasswordLengthValid(validatePasswordLength(password));
    setIsRepeatPasswordValid(password === repeatPassword);
  }, [password, repeatPassword]);

  const autologinFromPolp = async () => {
    const oraculoResult = await loginOraculoWithCredentials(recoverEmail, password);

    if (oraculoResult.error) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text: getTranslation('login_error_logging'),
        },
      });

      setTimeout(() => {
        navigate('/login');
      }, 50);
      return;
    }

    const oauthResult = await loginOauth(oraculoResult.tokenId, ORACULO_PROVIDER);

    if (oauthResult.error) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text: getTranslation('login_error_logging'),
        },
      });

      setTimeout(() => {
        navigate('/login');
      }, 50);
      return;
    }

    const userData = await getUserData(oauthResult.jwt);

    if (!userData) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text: getTranslation('recover_error_recovering'),
        },
      });

      return;
    }

    dispatch({ type: 'USER_SET_VALUES', payload: { ...userData, logged: true } });
    localStorage.setItem(POLP_JSON_WEB_TOKEN, oauthResult.jwt);
    setOraculoGlobalSession(oraculoResult.sid);
    setTimeout(() => {
      navigate('/home');
    }, 100);
  };

  const postNewPassword = async () => {
    if (!isPasswordValid) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_invalid_password_title'),
          text: getTranslation('error_invalid_password_text'),
        },
      });

      return;
    }

    if (!isRepeatPasswordValid) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_invalid_repeat_password_title'),
          text: getTranslation('error_invalid_repeat_password_text'),
        },
      });

      return;
    }

    const postNewPasswordResult = await post(POLP_API_URL + 'recover-password', {
      token: recoverToken,
      password: password,
    });

    if (postNewPasswordResult.networkError === true) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text: getTranslation('error_network'),
        },
      });

      return;
    }

    if (postNewPasswordResult.status !== 'success') {
      console.log('postNewPasswordResult error', postNewPasswordResult);

      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text: getTranslation('recover_error_recovering'),
        },
      });

      return;
    }

    autologinFromPolp();
  };

  const onClose = () => navigate('/login');

  return (
    <div className='recover-view'>
      <div className='recover-view__close-btn'>
        <CloseButton onClick={onClose} />
      </div>
      <div className='recover-view__content'>
        <div className='recover-view__logo'>
          <img className='recover-view__logo-img' src={PolpLogo} alt='Polp' />
        </div>

        <div className='recover-view__title'>{getTranslation('recover_title')}</div>

        <div className='recover-view__subtitle'>{getTranslation('recover_subtitle')}</div>

        <div className='recover-view__form'>
          <div className='recover-view__password-input'>
            <InputPassword value={password} onChange={setPassword} label={getTranslation('recover_password')} />
          </div>

          <div className='recover-view__password-input'>
            <InputPassword
              value={repeatPassword}
              onChange={setRepeatPassword}
              label={getTranslation('recover_repeat_password')}
            />
          </div>

          <button
            className={
              'recover-view__button-recover' +
              (isPasswordLengthValid
                ? ' recover-view__button-recover-enabled'
                : ' recover-view__button-recover-disabled')
            }
            onClick={postNewPassword}
          >
            <img src={ArrowIcon} alt='' />
          </button>
        </div>
      </div>
    </div>
  );
}

export default RecoverView;
