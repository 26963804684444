import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DemoDecoration from '../../../assets/img/chica_heart.png';
import LogoDigitalPlus from '../../../assets/img/logo_digital_plus.png';
import LogoEdelvives from '../../../assets/img/logo_edelvives.png';
import LogoGosteam from '../../../assets/img/logo_gosteam.svg';
import LogoByMe from '../../../assets/img/logo_home_byme.png';
import LogoTatum from '../../../assets/img/logo_tatum.png';
import {
  APP_BY_ME,
  APP_EDELVIVES_DIGITAL,
  APP_EDELVIVES_DIGITAL_MX,
  APP_EDELVIVES_DIGITAL_PLUS,
  APP_EDELVIVES_DIGITAL_PLUS_MX,
  APP_GOSTEAM,
  APP_TATUM,
  CAMPUS_URL,
  FORM_URL,
  GO_STEAM_MORE_INFO_LINK,
  IS_POLP_MEXICO,
  OTHERS_URL,
  POLP_API_URL,
  POLP_JSON_WEB_TOKEN,
  POLP_JSON_WEB_TOKEN_EXPIRED,
  POLP_JSON_WEB_TOKEN_INVALID,
  TATUM_MORE_INFO_LINK,
} from '../../../config/constants';
import { RouterContext } from '../../../context/RouterContext';
import { useJWTokenFailedFeedback } from '../../../hooks/useJWTokenFailedFeedback';
import { useScrollLock } from '../../../hooks/useScrollLock';
import { getTranslation } from '../../../locale/texts';
import HomeLayout from '../../../modules/layout/HomeLayout';
import { get } from '../../../services/requestService';
import { StoreContext } from '../../../store/store';
import Button from '../../atoms/Button';
import Card from '../../components/Card/Card';
import Footer from '../../components/Footer';
import HomeHeader from '../../components/HomeHeader';
import AddLicensePopup from '../../popups/AddLicensePopup';
import DemoPopup from '../../popups/DemoPopup';

function HomeView({ logout }) {
  const navigate = useNavigate();
  const routerContext = useContext(RouterContext);
  const [state, dispatch] = useContext(StoreContext);
  const { stoppedForJWToken } = useJWTokenFailedFeedback();
  const [apps, setApps] = useState([]);
  const [showLicensePopup, setShowLicensePopup] = useState(false);
  const [showDemoPopup, setShowDemoPopup] = useState(false);
  const { lockScroll, unlockScroll } = useScrollLock();
  const [goToResources, setGoToResources] = useState(false);
  const [resourcesFromCard, setResourcesFromCard] = useState(false);

  useEffect(() => {
    getAppsForUser();
    userGotLicenses();
  }, []);

  useEffect(() => {
    if (routerContext.current === '/home' && routerContext.previous === '/signup') {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'info',
          title: getTranslation('home_info_popup_title'),
          text: getTranslation('home_info_popup_message'),
        },
      });
    }
  }, [routerContext]);

  const userGotLicenses = async () => {
    const polpJWT = localStorage.getItem(POLP_JSON_WEB_TOKEN);
    if (!polpJWT) {
      stoppedForJWToken();
      return;
    }
    const licensesResult = await get(POLP_API_URL + 'licenses', null, { Authorization: polpJWT });
    const licenses = licensesResult.data.filter((item) => item.licenses?.length > 0);

    setGoToResources(licenses.length > 0);
  };

  const handleDemoPopup = () => {
    const _showDemoPopup = !showDemoPopup;
    setShowDemoPopup(_showDemoPopup);
    _showDemoPopup ? lockScroll() : unlockScroll();
  };

  const handleLicensePopup = (e, fromCard) => {
    const _showLicensePopup = !showLicensePopup;
    setResourcesFromCard(!showLicensePopup && fromCard);
    setShowLicensePopup(_showLicensePopup);
    _showLicensePopup ? lockScroll() : unlockScroll();
  };

  const getAppsForUser = async () => {
    const polpJWT = localStorage.getItem(POLP_JSON_WEB_TOKEN);
    if (!polpJWT) {
      stoppedForJWToken();
      return;
    }

    const getAppsForUserResult = await get(POLP_API_URL + 'apps', null, { Authorization: polpJWT });

    if (getAppsForUserResult.networkError === true) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text: getTranslation('error_network'),
        },
      });

      return;
    }

    const errorName = getAppsForUserResult.name;
    if (errorName && (errorName === POLP_JSON_WEB_TOKEN_EXPIRED || errorName === POLP_JSON_WEB_TOKEN_INVALID)) {
      stoppedForJWToken();
      return;
    }

    if (getAppsForUserResult.status !== 'success' || !getAppsForUserResult.data || !getAppsForUserResult.data.apps) {
      console.log('getAppsForUserResult error', getAppsForUserResult);

      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text: getTranslation('home_apps_error'),
        },
      });
    }

    setApps(getAppsForUserResult.data.apps);
  };

  const openBrowserTab = (url) => {
    window.open(url, '_blank');
  };

  const getOraculoGlobalSession = async () => {
    try {
      window.checkGlobalSession();
    } catch (e) {
      console.log(e);
    }
  };

  const openApp = async (appName) => {
    for (let a = 0, aMax = apps.length; a < aMax; a++) {
      if (apps[a].name === appName) {
        await getOraculoGlobalSession();
        openBrowserTab(apps[a].domain);
        break;
      }
    }
  };

  const openSignUpPopUp = () => {
    window.open(FORM_URL, 'popup', 'width=500,height=710');
  };

  const handleGoToProfile = () => {
    navigate('/profile');
  };

  const handleGoToHelp = () => {
    navigate('/support');
  };

  return (
    <div className='home-view'>
      <HomeLayout
        header={
          <HomeHeader
            handleAddLicense={handleLicensePopup}
            handleGoToProfile={handleGoToProfile}
            userData={state.user}
            logout={logout}
          />
        }
        footer={<Footer type='full' onClickHelp={handleGoToHelp} />}
      >
        <div className={IS_POLP_MEXICO ? 'home-view__card-list__mx' : 'home-view__card-list'}>
          <Card
            color={'orange'}
            // tag={getTranslation('home_tag_new')}
            logo={LogoDigitalPlus}
            contentTag={getTranslation(IS_POLP_MEXICO ? 'home_tag_level_primary_mx' : 'home_tag_level_primary')}
            // contentDes={getTranslation('home_app_edelvives_digital_plus_description')}
            btnText={getTranslation('home_app_go_to')}
            btnColor={'white'}
            btnOnClick={() => openApp(IS_POLP_MEXICO ? APP_EDELVIVES_DIGITAL_PLUS_MX : APP_EDELVIVES_DIGITAL_PLUS)}
          />

          <Card
            color={'red-bg'}
            logo={LogoEdelvives}
            contentTag={getTranslation(IS_POLP_MEXICO ? 'home_tag_level_infant_bach' : 'home_tag_level_infant')}
            // contentDes={getTranslation('home_app_edelvives_digital_description')}
            btnText={getTranslation('home_app_go_to')}
            btnColor={'white'}
            btnOnClick={() => openApp(IS_POLP_MEXICO ? APP_EDELVIVES_DIGITAL_MX : APP_EDELVIVES_DIGITAL)}
          />

          <Card
            color={'yellow'}
            logo={LogoGosteam}
            contentDes={getTranslation('home_app_gosteam_description')}
            btnText={getTranslation('home_app_go_to')}
            btnColor={'darkgray'}
            btnOnClick={() => openApp(APP_GOSTEAM)}
            extraBtnText={getTranslation('home_app_information')}
            extraBtnColor={'transparent-black'}
            extraBtnOnClick={() => openBrowserTab(GO_STEAM_MORE_INFO_LINK)}
          />

          <Card
            color={'brown'}
            logo={LogoTatum}
            contentDes={getTranslation('home_app_tatum_description')}
            btnText={getTranslation('home_app_go_to')}
            btnColor={'white'}
            btnOnClick={() => openApp(APP_TATUM)}
            extraBtnText={getTranslation('home_app_information')}
            extraBtnColor={'transparent-white'}
            extraBtnOnClick={() => openBrowserTab(TATUM_MORE_INFO_LINK)}
          />

          {/* <Card
            color={'light-orange'}
            title={getTranslation('home_resources_title')}
            subtitle={EdelvivesText}
            contentDes={getTranslation('home_resources_description')}
            btnText={getTranslation('home_app_go_to')}
            btnColor={'white'}
            btnOnClick={goToResources ? () => openBrowserTab(EDELVIVES_RESOURCES) : (e) => handleLicensePopup(e, true)}
          />

          {!IS_POLP_MEXICO && (
            <Card
              color={'light-orange'}
              title={getTranslation('home_resources_title')}
              subtitle={BaulaText}
              contentDes={getTranslation('home_resources_description')}
              btnText={getTranslation('home_app_go_to')}
              btnColor={'white'}
              btnOnClick={
                goToResources ? () => openBrowserTab(EDELVIVES_RESOURCES_BAULA) : (e) => handleLicensePopup(e, true)
              }
            />
          )} */}

          {!IS_POLP_MEXICO && (
            <Card
              color={'red'}
              logo={LogoByMe}
              contentDes={getTranslation('home_app_byme_description')}
              btnText={getTranslation('home_app_go_to')}
              btnColor={'white'}
              btnOnClick={() => openApp(APP_BY_ME)}
            />
          )}

          {!IS_POLP_MEXICO && (
            <Card
              color={'blue'}
              title={getTranslation('home_campus_title')}
              contentDes={getTranslation('home_campus_description')}
              btnText={getTranslation('home_app_go_to')}
              btnColor={'white'}
              btnOnClick={(e) => openBrowserTab(CAMPUS_URL)}
            />
          )}

          {!IS_POLP_MEXICO && (
            <Card
              color={'blue'}
              title={getTranslation('home_others')}
              contentDes={getTranslation('home_others_description')}
              btnText={getTranslation('home_app_go_to')}
              btnColor={'white'}
              btnOnClick={(e) => openBrowserTab(OTHERS_URL)}
            />
          )}

          {!IS_POLP_MEXICO && (
            <div className='home-view__card home-view__card--demo'>
              <div className='home-view__card-header'>{getTranslation('home_demo_title')}</div>
              <div className='home-view__card-content'>{getTranslation('home_demo_description')}</div>
              <div className='home-view__card-footer'>
                <Button
                  text={getTranslation('home_demo_request_button')}
                  onClick={handleDemoPopup}
                  size='big'
                  color='white'
                />
              </div>
              <div className='home-view__card-decoration'>
                <img src={DemoDecoration} alt='' />
              </div>
            </div>
          )}
        </div>

        {showLicensePopup && (
          <AddLicensePopup
            onClickClose={handleLicensePopup}
            setShowLicensePopup={setShowLicensePopup}
            resourcesFromCard={resourcesFromCard}
          />
        )}
        {showDemoPopup && <DemoPopup openApp={openApp} onClickClose={handleDemoPopup} />}
      </HomeLayout>
    </div>
  );
}

export default HomeView;
