import React from 'react';

function SectionLayout({ header, footer, children }) {
  return (
    <div className='section-layout'>
      <div className='section-layout__header'>{header}</div>
      <div className='section-layout__content'>{children}</div>
      <div className='section-layout__footer'>{footer}</div>
    </div>
  );
}

export default SectionLayout;
