import React, { useEffect, useRef, useState } from 'react';
import { ORACULO_PROVIDER, POLP_URL } from '../../../config/constants';
import { getTranslation } from '../../../locale/texts';
import Checkbox from '../../atoms/Checkbox';
import InputPassword from '../../atoms/InputPassword';

function SignUpFormPassword({ initialValues, onFormChange, identityProvider }) {
  const repeatPasswordRef = useRef(null);
  const [password, setPassword] = useState(initialValues.password);
  const [repeatPassword, setRepeatPassword] = useState(initialValues.repeatPassword);
  const [privacy, setPrivacy] = useState(initialValues.privacy);
  const [consent, setConsent] = useState(initialValues.consent);

  useEffect(() => {
    onFormChange({ password, repeatPassword, privacy, consent });
  }, [password, repeatPassword, privacy, consent]);

  const passwordEnterPressed = () => {
    repeatPasswordRef?.current.focus();
  };

  const showPrivacyPolicy = () => {
    window.open(POLP_URL + 'legal', '_blank');
  };


  return (
    <div className='sign-up-form-password animated fade-in'>
      <div className='sign-up-form-password__title'>
        {identityProvider === ORACULO_PROVIDER
          ? getTranslation('signup_form_password_title')
          : getTranslation('signup_form_password_title_external_provider')}
      </div>
      <div className='sign-up-form-password__subtitle'>
        {identityProvider === ORACULO_PROVIDER
          ? getTranslation('signup_form_password_subtitle')
          : getTranslation('signup_form_password_subtitle_external_provider')}
      </div>
      <form className='sign-up-form-password__form'>
        {identityProvider === ORACULO_PROVIDER && (
          <div className='sign-up-form-password__password-input'>
            <InputPassword
              value={password}
              onChange={setPassword}
              label={getTranslation('signup_form_password_password')}
              onEnterPressed={passwordEnterPressed}
            />
          </div>
        )}
        {identityProvider === ORACULO_PROVIDER && (
          <div className='sign-up-form-password__password-input'>
            <InputPassword
              value={repeatPassword}
              onChange={setRepeatPassword}
              label={getTranslation('signup_form_password_repeat_password')}
              refFromParent={repeatPasswordRef}
            />
          </div>
        )}
        <div className='sign-up-form-password__checkbox-wrapper'>
          <div className='sign-up-form-password__checkbox'>
            <span>
              <Checkbox
                value={privacy}
                onChange={setPrivacy}
                id='privacy'
                label=''
              />              
            </span>
            <a href='#' onClick={showPrivacyPolicy}>{getTranslation('signup_form_password_privacy')}</a>
          </div>
          <div className='sign-up-form-password__checkbox'>
            <span>
            <Checkbox
              value={consent}
              onChange={setConsent}
              id='personal'
              label=''
            />
            </span>
            <span>{getTranslation('signup_form_password_consent')}</span>            
          </div>
        </div>
      </form>
    </div>
  );
}

export default SignUpFormPassword;
