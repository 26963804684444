import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PolpLogo from '../../../assets/polp_logo_popup.svg';
import {
  POLP_API_URL,
  POLP_JSON_WEB_TOKEN,
  POLP_JSON_WEB_TOKEN_EXPIRED,
  POLP_JSON_WEB_TOKEN_INVALID,
} from '../../../config/constants';
import { useJWTokenFailedFeedback } from '../../../hooks/useJWTokenFailedFeedback';
import { getTranslation } from '../../../locale/texts';
import { getAppIcon } from '../../../services/appsIcons';
import { get, post } from '../../../services/requestService';
import { StoreContext } from '../../../store/store';
import Button from '../../atoms/Button';
import CloseButton from '../../atoms/CloseButton';
import PackagesItem from '../../components/PackagesItem';

function DemoPopup({ openApp, onClickClose }) {
  const navigate = useNavigate();
  const [state, dispatch] = useContext(StoreContext);
  const { stoppedForJWToken } = useJWTokenFailedFeedback();
  const [packagesData, setPackagesData] = useState([]);
  const [packagesStatus, setPackagesStatus] = useState([]);
  const [drawerActions, setDrawerActions] = useState([]);

  useEffect(() => {
    getPackagesForUser();
  }, []);

  const _getPackagesForUser = async () => {
    const polpJWT = localStorage.getItem(POLP_JSON_WEB_TOKEN);
    if (!polpJWT) {
      stoppedForJWToken();
      return;
    }

    const obtainedPackagesResult = await get(POLP_API_URL + 'demos', null, { Authorization: polpJWT });

    if (obtainedPackagesResult.networkError === true) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text: getTranslation('error_network'),
        },
      });

      return;
    }

    const errorName = obtainedPackagesResult.name;
    if (errorName && (errorName === POLP_JSON_WEB_TOKEN_EXPIRED || errorName === POLP_JSON_WEB_TOKEN_INVALID)) {
      stoppedForJWToken();
      return;
    }

    if (obtainedPackagesResult.status !== 'success' || !obtainedPackagesResult.data) {
      console.log('obtainedPackagesResult error', obtainedPackagesResult);

      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text: getTranslation('demo_getting_packages_error'),
        },
      });

      return;
    }

    // console.log('obtainedPackagesResult', obtainedPackagesResult);
    const orderedPackages = obtainedPackagesResult.data.sort((a, b) => (a.name > b.name ? 1 : -1));
    setPackagesData(orderedPackages);
    setPackagesStatus(orderedPackages.map((_package) => ({ id: _package.id, active: false })));
  };

  const getPackagesForUser = async () => {
    dispatch({ type: 'LOADING_ON' });
    await _getPackagesForUser();
    dispatch({ type: 'LOADING_OFF' });
  };

  const toogleAppButton = (appName, enable) => {
    const _drawerActions = [...drawerActions];
    let appActionIndex = -1;

    for (let a = 0, aMax = _drawerActions.length; a < aMax; a++) {
      if (_drawerActions[a].id === appName) {
        appActionIndex = a;
        break;
      }
    }

    if (!enable && appActionIndex !== -1) {
      _drawerActions.splice(appActionIndex, 1);
    } else if (enable && appActionIndex === -1) {
      _drawerActions.push({
        id: appName,
        button: (
          <div style={{ display: 'flex', alignItems: 'center' }} key={appName} onClick={() => openApp(appName)}>
            <img style={{ height: '1.5em', paddingRight: '0.5em' }} src={getAppIcon(appName)} alt='' />
            <span>{getTranslation(appName)}</span>
          </div>
        ),
      });
    }

    const orderedDrawerActions = _drawerActions.sort((a, b) => (a.id > b.id ? 1 : -1));
    setDrawerActions(orderedDrawerActions);
  };

  const setPackageStatusForIndex = (packageIndex, newValue) => {
    const updatedPackagesStatus = packagesStatus.map((currentItem, index) => {
      if (packageIndex === index) currentItem.active = newValue;
      return currentItem;
    });

    toogleAppButton(packagesData[packageIndex].app, newValue);

    setPackagesStatus(updatedPackagesStatus);
  };

  const isDemoAccessButtonEnabled = () => packagesStatus.some((item) => item.active === true);

  const _requestDemoAccess = async () => {
    const selectedPackages = [];
    for (let p = 0, pMax = packagesStatus.length; p < pMax; p++) {
      if (packagesStatus[p].active === true) selectedPackages.push(packagesStatus[p].id);
    }

    const polpJWT = localStorage.getItem(POLP_JSON_WEB_TOKEN);
    if (!polpJWT) {
      stoppedForJWToken();
      return;
    }

    const redeemedpackagesResult = await post(
      POLP_API_URL + 'demos',
      { id: [...selectedPackages] },
      { Authorization: polpJWT }
    );

    if (redeemedpackagesResult.networkError === true) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text: getTranslation('error_network'),
        },
      });

      return;
    }

    const errorName = redeemedpackagesResult.name;
    if (errorName && (errorName === POLP_JSON_WEB_TOKEN_EXPIRED || errorName === POLP_JSON_WEB_TOKEN_INVALID)) {
      stoppedForJWToken();
      return false;
    }

    if (redeemedpackagesResult.status !== 'success') {
      console.log('redeemedpackagesResult error', redeemedpackagesResult);

      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text: getTranslation('demo_redeeming_packages_error'),
        },
      });

      return;
    }

    const drawerButtons = [];
    for (let a = 0, aMax = drawerActions.length; a < aMax; a++) {
      drawerButtons.push(drawerActions[a].button);
    }

    dispatch({
      type: 'MESSAGE_SHOW_DRAWER',
      payload: {
        type: 'success',
        title: getTranslation('demo_redeeming_packages_success_title'),
        text: getTranslation('demo_redeeming_packages_success_text'),
        buttons: drawerButtons,
      },
    });
  };

  const requestDemoAccess = async () => {
    dispatch({ type: 'LOADING_ON' });
    await _requestDemoAccess();
    dispatch({ type: 'LOADING_OFF' });
  };

  return (
    <div className='demo-popup'>
      <div className='demo-popup__logo'>
        <img src={PolpLogo} alt='POLP' />
      </div>
      <div className='demo-popup__close-btn'>
        <CloseButton onClick={onClickClose} />
      </div>

      <div className='demo-popup__content'>
        <div className='demo-popup__title'>{getTranslation('demo_title')}</div>
        <div className='demo-popup__subtitle'>{getTranslation('demo_subtitle')}</div>

        {packagesData.length > 0 && packagesStatus.length > 0 && (
          <>
            <div className='demo-popup__packages-box'>
              {packagesData.map((_package, index) => (
                <PackagesItem
                  key={index}
                  packageData={_package}
                  id={'package-' + _package.id}
                  value={packagesStatus[index].active}
                  setValue={(value) => setPackageStatusForIndex(index, value)}
                  getAppIcon={getAppIcon}
                />
              ))}
            </div>

            <div className='demo-popup__actions'>
              <Button
                text={getTranslation('demo_access')}
                size='big'
                color='darkgray'
                disabled={!isDemoAccessButtonEnabled()}
                onClick={(e) => {
                  e.preventDefault();
                  requestDemoAccess();
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default DemoPopup;
