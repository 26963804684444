import React from 'react';
import LandingHelpView from '../../modules/views/LandingHelpView/LandingHelpView';

function LandingHelpPage() {
  return (
    <div className='landing-help-page'>
      <LandingHelpView />
    </div>
  );
}

export default LandingHelpPage;
