import React from 'react';

function AnimatedBackgroundLayout({ currentStep, children }) {
  return (
    <div className='animated-background-layout'>
      <div className='animated-background-layout__content'>{children}</div>
      <div
        className={`animated-background-layout__background animated-background-layout__background--step-${currentStep}`}
      >
        <div className='animated-background-layout__background--layer1'></div>
        <div className='animated-background-layout__background--layer2'></div>
        <div className='animated-background-layout__background--layer3'></div>
      </div>
    </div>
  );
}

export default AnimatedBackgroundLayout;
