import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const RouterContext = createContext();
export const RouterProvider = ({ children }) => {
  const [routes, setRoutes] = useState({ previous: null, current: null });
  const location = useLocation();

  useEffect(() => {
    setRoutes({ previous: routes.current, current: location.pathname });
  }, [location]);

  return <RouterContext.Provider value={routes}>{children}</RouterContext.Provider>;
};
