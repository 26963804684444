import React from 'react';

function SoonCard({ logo, text }) {
  return (
    <div className='soon-card'>
      <div className='soon-card__img'>
        <img src={logo} alt='' />
      </div>
      <div className='soon-card__text'>{text}</div>
    </div>
  );
}

export default SoonCard;
