import React, { createRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { CUSTOMER_EXPERIENCE } from '../../../config/constants';
import useScrollIntoView from '../../../hooks/useScrollIntoView';
import { getTranslation } from '../../../locale/texts';
import Accordion from '../../components/Accordion';
import Footer from '../../components/Footer';
import LandingSectionHeader from '../../components/LandingSectionHeader';
import SectionLayout from '../../layout/SectionLayout';

function LandingHelpView() {
  const FAQ_LIST = [
    { title: getTranslation('help_faq_1_title'), content: getTranslation('help_faq_1_text') },
    { title: getTranslation('help_faq_2_title'), content: getTranslation('help_faq_2_text') },
    { title: getTranslation('help_faq_3_title'), content: getTranslation('help_faq_3_text') },
    { title: getTranslation('help_faq_4_title'), content: getTranslation('help_faq_4_text') },
    { title: getTranslation('help_faq_5_title'), content: getTranslation('help_faq_5_text') },
    { title: getTranslation('help_faq_6_title'), content: getTranslation('help_faq_6_text') },
  ];

  const navigate = useNavigate();

  const topElementRef = createRef();
  useScrollIntoView(topElementRef);

  const handleGoToPolp = () => navigate('/home');
  const handleGoHome = () => navigate('/');

  return (
    <div className='landing-help-view'>
      {/* Como no hay ningún elemento superior para anclar topElementRef, creo este auxiliar */}
      <div style={{ visibility: 'hidden' }} ref={topElementRef}></div>

      <SectionLayout
        header={<LandingSectionHeader handleGoHome={handleGoHome} handleGoToPolp={handleGoToPolp} />}
        footer={<Footer type='full' />}
      >
        <div className='landing-help-view__wrapper'>
          <div className='landing-help-view__title'>{getTranslation('help_faq_title')}</div>
          <div className='landing-help-view__subtitle'>{getTranslation('help_faq_subtitle')}</div>
          <div className='landing-help-view__faq'>
            {FAQ_LIST.map((question, index) => (
              <Accordion key={index} title={question.title} htmlContent={question.content} />
            ))}
          </div>

          <div className='landing-help-view__title'>{getTranslation('landing_contact_title')}</div>
          <div
            className='landing-help-view__subtitle'
            dangerouslySetInnerHTML={{
              __html:
                getTranslation('landing_contact_subtitle') +
                ' <a href="mailto:' +
                CUSTOMER_EXPERIENCE +
                '">' +
                CUSTOMER_EXPERIENCE +
                '</a>',
            }}
          />
        </div>
      </SectionLayout>
    </div>
  );
}

export default LandingHelpView;
