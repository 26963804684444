import React, { createRef, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useScrollIntoView from '../../../hooks/useScrollIntoView';
import { getTranslation } from '../../../locale/texts';
import { StoreContext } from '../../../store/store';
import Footer from '../../components/Footer';
import LandingSectionHeader from '../../components/LandingSectionHeader';
import SectionHeader from '../../components/SectionHeader';
import SectionLayout from '../../layout/SectionLayout';

function LandingLegalView({ logout, type }) {
  const navigate = useNavigate();

  const [state, dispatch] = useContext(StoreContext);

  const topElementRef = createRef();
  useScrollIntoView(topElementRef);

  const [title, setTitle] = useState('');
  const [text, setText] = useState('');

  useEffect(() => {
    switch (type) {
      case 'privacy':
        setTitle(getTranslation('static_privacy_title'));
        setText(getTranslation('static_privacy_text'));
        break;

      case 'cookies':
        setTitle(getTranslation('static_cookies_title'));
        setText(getTranslation('static_cookies_text'));
        break;

      case 'legal':
      default:
        setTitle(getTranslation('static_legal_title'));
        setText(getTranslation('static_legal_text'));
    }
  }, [type]);

  const isUserLogged = () => state.user.logged === true;

  const handleGoLanding = () => navigate('/');
  const handleGoToPolp = () => navigate('/home');

  const handleGoHome = () => navigate('/home');
  const handleGoToProfile = () => navigate('/profile');

  const handleGoToHelp = () => (!isUserLogged() ? navigate('/help') : navigate('/support'));

  return (
    <div className='landing-legal-view'>
      {/* Como no hay ningún elemento superior para anclar topElementRef, creo este auxiliar */}
      <div style={{ visibility: 'hidden' }} ref={topElementRef}></div>

      <SectionLayout
        header={
          !isUserLogged() ? (
            <LandingSectionHeader handleGoHome={handleGoLanding} handleGoToPolp={handleGoToPolp} />
          ) : (
            <SectionHeader
              handleGoHome={handleGoHome}
              handleGoToProfile={handleGoToProfile}
              userData={state.user}
              logout={logout}
            />
          )
        }
        footer={<Footer type='full' onClickHelp={handleGoToHelp} />}
      >
        <div className='landing-legal-view__wrapper'>
          <div className='landing-legal-view__title'>{title}</div>
          <div className='landing-legal-view__text' dangerouslySetInnerHTML={{ __html: text }} />
        </div>
      </SectionLayout>
    </div>
  );
}

export default LandingLegalView;
