import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { APP_VERSION, GOOGLE_CLIENT_ID } from './config/constants';
import { RouterProvider } from './context/RouterContext';
import Router from './router';
import { StoreProvider } from './store/store';

function App() {
  console.log(`%cVersion: ${APP_VERSION}`, 'background:#444; color: #bada55; padding:1em; border-radius:4px');

  return (
    <div className='App'>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <StoreProvider>
          <BrowserRouter>
            <RouterProvider>
              <Router />
            </RouterProvider>
          </BrowserRouter>
        </StoreProvider>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
