import React from 'react';

function Button({ text, size, color, onClick, disabled = false }) {
  return (
    <button
      disabled={disabled}
      className={`button button--size-${size} button--color-${color}${disabled ? ' button--disabled' : ''}`}
      onClick={onClick}
    >
      {text}
    </button>
  );
}

Button.defaultProps = {
  onClick: () => console.log('😎 Click!'),
  text: 'Click here',
};

export default Button;
