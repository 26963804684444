import { debounce } from 'lodash';
import React from 'react';
import { IS_POLP_MEXICO, POLP_API_URL } from '../config/constants';
import { get } from '../services/requestService';

const debouncedGetSchools = debounce((schoolSearch, resolve, reject) => {
  if (schoolSearch.length < 3) {
    resolve({ schools: [] });
    return;
  }

  get(POLP_API_URL + 'roster/schools', { search: schoolSearch })
    .then((response) => {
      if (response.status === 'success' && response.data && response.data.organizations) {
        resolve({ schools: response.data.organizations });
      } else {
        reject({ error: JSON.stringify(response) });
      }
    })
    .catch((error) => {
      reject({ error: JSON.stringify(error) });
    });
}, 1000);

export const getSchools = (schoolSearch) => {
  return new Promise((resolve, reject) => {
    debouncedGetSchools(schoolSearch, resolve, reject);
  });
};

export const getSchoolText = (school) => {
  if (!school) return '';
  const zipCode = school?.zipcode?.id || '';
  const address = school?.address || '';
  const city = school?.zipcode?.city?.name || '';

  const schoolFullAddress =
    address +
    ', ' +
    zipCode +
    /* ', ' + school.zipcode.city.name + */
    ', ' +
    city;

  return (
    <div
      className='option-text'
      dangerouslySetInnerHTML={{
        __html:
          '<div class="option-left-text">' +
          school.name +
          '</div>' +
          '<div class="option-right-text">' +
          schoolFullAddress +
          '</div>',
      }}
    ></div>
  );
};

// GRADES --------------------------------------------------------------
export const getGrades = () => {
  return get(POLP_API_URL + `roster/grades?country_guid[]=${IS_POLP_MEXICO ? 'mx' : 'es'}`)
    .then((response) => {
      if (response.status === 'success' && response.data && response.data.grades) {
        return _parseGrades(response.data.grades);
      }

      return { error: JSON.stringify(response) };
    })
    .catch((error) => {
      return { error: JSON.stringify(error) };
    });
};

const _parseGrades = (_levels) => {
  const _parsedLevels = [];

  for (let l = 0, lMax = _levels.length; l < lMax; l++) {
    const _level = _levels[l];
    const _levelGrades = [];
    for (let g = 0, gMax = _level.grades.length; g < gMax; g++) {
      const _grade = _level.grades[g];
      _levelGrades.push({ value: _grade.id, label: _grade.name });
    }
    _parsedLevels.push({ label: _level.name, options: _levelGrades });
  }

  return _parsedLevels;
};

// SUBJECTS ----------------------------------------------------------------------------
export const getSubjects = () => {
  return get(POLP_API_URL + `roster/subjects?country_guid[]=${IS_POLP_MEXICO ? 'mx' : 'es'}`)
    .then((response) => {
      if (response.status === 'success' && response.data && response.data.subjects) {
        return _parseSubjects(response.data.subjects);
      }

      return { error: JSON.stringify(response) };
    })
    .catch((error) => {
      return { error: JSON.stringify(error) };
    });
};

const _parseSubjects = (_subjects) => {
  const _parsedSubjects = [];

  for (let s = 0, sMax = _subjects.length; s < sMax; s++) {
    const _subject = _subjects[s];
    _parsedSubjects.push({ value: _subject.id, label: _subject.name });
  }

  _parsedSubjects.sort((a, b) => {
    const itemAname = a.label.toLocaleLowerCase();
    const itemBname = b.label.toLocaleLowerCase();
    if (itemAname > itemBname) return 1;
    if (itemAname < itemBname) return -1;
    return 0;
  });

  return _parsedSubjects;
};
