import React from 'react';
import HomeView from '../../modules/views/HomeView/HomeView';

function HomePage({ logout }) {
  return (
    <div className='home-page'>
      <HomeView logout={logout} />
    </div>
  );
}

export default HomePage;
