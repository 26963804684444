const initialState = { type: '', title: '', text: '', buttons: [], visible: false };

export default {
  key: 'message',

  initialState,

  reducer: (state, action) => {
    switch (action.type) {
      case 'MESSAGE_SHOW_DRAWER':
        return { ...action.payload, visible: true };

      case 'MESSAGE_HIDE_DRAWER':
        return initialState;

      case 'MESSAGE_SHOW_ALERT':
        return { ...action.payload, visible: true };

      default:
        return state;
    }
  },
};
