import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ORACULO_CDN_URL,
  ORACULO_PROVIDER,
  POLP_FEDERATE_APP_ID,
  POLP_FEDERATE_APP_NAME,
  POLP_FEDERATE_APP_SHARED_KEY,
  POLP_JSON_WEB_TOKEN,
} from '../config/constants';
import {
  getUserData,
  loginOauth,
  loginOraculoWithToken,
  logout,
  setOraculoGlobalSession,
} from '../services/loginService';
import { StoreContext } from '../store/store';

const useOnceUserData = (pathNeedUserData, doneManually) => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [isAlreadyUsed, setIsAlreadyUsed] = useState(false);
  const [state, dispatch] = useContext(StoreContext);

  const polpLogOut = (toWhere) => {
    navigate(toWhere);
    logout();
    dispatch({ type: 'USER_CLEAR_VALUES' });
  };

  useEffect(() => {
    if (pathNeedUserData && !doneManually && !isAlreadyUsed) {
      setIsAlreadyUsed(true);

      // Cargar script para gestión de Sesión global (tanto obtenerala como generarla)
      loadScript(ORACULO_CDN_URL, () => {
        window.setConfiguration({
          id: POLP_FEDERATE_APP_ID,
          name: POLP_FEDERATE_APP_NAME,
          sharedKey: POLP_FEDERATE_APP_SHARED_KEY,
        });
      });
      window.addEventListener(
        'isSignedIn',
        async () => {
          const isCurrentJWTokenValid = await checkCurrentJWT();
          if (!isCurrentJWTokenValid) tryGlobalSession();
        },
        false
      );
      window.addEventListener(
        'globalSessionChecked',
        async () => {
          const hasSession = window.getHasGlobalSession();
          if (!hasSession) {
            const oraculoTokenId = window.getTokenId();
            const oauthResult = await loginOraculoWithToken(oraculoTokenId, ORACULO_PROVIDER);
            if (oauthResult.error) {
              polpLogOut('/');
            }
            setOraculoGlobalSession(oauthResult.sid);
          }
        },
        false
      );
    }
  }, [pathNeedUserData]);

  const getOraculoGlobalSession = async () => {
    const isSignedGlobaly = window.isSignedIn ? window.isSignedIn() : false;

    if (isSignedGlobaly) {
      const oraculoTokenId = window.getTokenId();
      const oauthResult = await loginOauth(oraculoTokenId, ORACULO_PROVIDER);

      if (oauthResult.error) return null;

      return oauthResult.jwt;
    }

    return null;
  };

  const tryGlobalSession = async () => {
    const oraculoJWT = await getOraculoGlobalSession();

    if (oraculoJWT) {
      const _userData = await getUserData(oraculoJWT);
      if (_userData) {
        localStorage.setItem(POLP_JSON_WEB_TOKEN, oraculoJWT);
        setUserData({ ..._userData, logged: true });
      }
    } else {
      setUserData({ logged: false });
    }
  };

  const checkCurrentJWT = async () => {
    const jwt = localStorage.getItem(POLP_JSON_WEB_TOKEN);
    if (jwt) {
      const _userData = await getUserData(jwt);
      if (_userData) {
        setUserData({ ..._userData, logged: true });
        return true;
      }
    }

    return false;
  };

  const loadScript = (url, onLoad) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = false;
    script.onload = onLoad;
    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
  };

  return userData;
};

export default useOnceUserData;
