import React from 'react';
import ChangePasswordView from '../../modules/views/ChangePasswordView/ChangePasswordView';

function ChangePasswordPage() {
  return (
    <div className='change-password-page'>
      <ChangePasswordView />
    </div>
  );
}

export default ChangePasswordPage;
