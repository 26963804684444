const initialState = { isActive: false };

export default {
  key: 'loading',

  initialState,

  reducer: (state, action) => {
    switch (action.type) {
      case 'LOADING_ON':
        return { isActive: true };

      case 'LOADING_OFF':
        return { isActive: false };

      default:
        return state;
    }
  },
};
