import { DEVELOPMENT, DEVELOPMENT_MX } from './config.dev';
import { PRODUCTION, PRODUCTION_MX } from './config.prod';
import { ENV } from './environment';

const APP_VERSION = '1.0.0';
const ENVIRONMENT = ENV();

const ENVIRONMENTS = {
  LOCAL: 'local',
  DEVELOPMENT: 'development',
  DEVELOPMENT_MX: 'development-mx',
  PRODUCTION: 'production',
  PRODUCTION_MX: 'production-mx',
};

const CONFIG = {
  [ENVIRONMENTS.LOCAL]: DEVELOPMENT,
  [ENVIRONMENTS.DEVELOPMENT]: DEVELOPMENT,
  [ENVIRONMENTS.DEVELOPMENT_MX]: DEVELOPMENT_MX,
  [ENVIRONMENTS.PRODUCTION]: PRODUCTION,
  [ENVIRONMENTS.PRODUCTION_MX]: PRODUCTION_MX,
};

const IS_POLP_MEXICO = ENVIRONMENT === ENVIRONMENTS.PRODUCTION_MX || ENVIRONMENT === ENVIRONMENTS.DEVELOPMENT_MX;
const POLP_URL = CONFIG[ENVIRONMENT].POLP_URL;
const POLP_API_URL = POLP_URL + 'api/';
const REDIRECT_URI = POLP_URL;
const POLP_FEDERATE_APP_ID = CONFIG[ENVIRONMENT].POLP_FEDERATE_APP_ID;
const POLP_FEDERATE_APP_NAME = CONFIG[ENVIRONMENT].POLP_FEDERATE_APP_NAME;
const POLP_FEDERATE_APP_SHARED_KEY = CONFIG[ENVIRONMENT].POLP_FEDERATE_APP_SHARED_KEY;
const ORACULO_LIBRARIES_URL = CONFIG[ENVIRONMENT].ORACULO_LIBRARIES_URL;
const ORACULO_CDN_URL = ORACULO_LIBRARIES_URL + 'cdn.js';
const ORACULO_SSO_URL = ORACULO_LIBRARIES_URL + 'index.html?sid=';
const ORACULO_API_AUTH = CONFIG[ENVIRONMENT].ORACULO_API_AUTH;
const GOOGLE_CLIENT_ID = CONFIG[ENVIRONMENT].GOOGLE_CLIENT_ID;
const MICROSOFT_CLIENT_ID = CONFIG[ENVIRONMENT].MICROSOFT_CLIENT_ID;
const TATUM_MORE_INFO_LINK = CONFIG[ENVIRONMENT].TATUM_MORE_INFO_LINK;
const GO_STEAM_MORE_INFO_LINK = CONFIG[ENVIRONMENT].GO_STEAM_MORE_INFO_LINK;
const EDELVIVES_RESOURCES = IS_POLP_MEXICO
  ? 'https://www.edelvivesmexico.com/sl/recursos'
  : 'https://www.edelvives.com/es/recursos/';
const EDELVIVES_RESOURCES_BAULA = 'https://www.baula.com/ca/recursos/';
const HELP_TECH_EMAIL = CONFIG[ENVIRONMENT].HELP_TECH_EMAIL;
const HELP_SALES_EMAIL = CONFIG[ENVIRONMENT].HELP_SALES_EMAIL;
const SUPPORT_EMAIL = CONFIG[ENVIRONMENT].SUPPORT_EMAIL;
const CUSTOMER_EXPERIENCE = CONFIG[ENVIRONMENT].CUSTOMER_EXPERIENCE;

// ----------------------------------------------------------------------------------------------------
// Comunes --------------------------------------------------------------------------------------------
// Comunes - Varios
const POLP_JSON_WEB_TOKEN = 'polp_session_token';
const POLP_JSON_WEB_TOKEN_EXPIRED = 'TokenExpiredError';
const POLP_JSON_WEB_TOKEN_INVALID = 'JsonWebTokenError';

const ORACULO_PROVIDER = 'oraculo';
const GOOGLE_PROVIDER = 'google';
const GOOGLE_COOKIE_POLICY = 'single_host_origin';
const MICROSOFT_PROVIDER = 'microsoft';
const MICROSOFT_AUTHORITY = 'https://login.microsoftonline.com/common';

// Comunes - Login
const EMAIL_SIGN_UP_LINK = 'signup?token=[TOKEN]&email=';
const EMAIL_RECOVER_PASSWORD_LINK = 'recover?token=[TOKEN]&email=';

// Comunes - Home (Dashboard)
const APP_EDELVIVES_DIGITAL = 'EDELVIVES_DIGITAL';
const APP_EDELVIVES_DIGITAL_MX = 'EDELVIVES_DIGITAL_MX';
const APP_EDELVIVES_DIGITAL_PLUS = 'EDELVIVES_DIGITAL_PLUS';
const APP_EDELVIVES_DIGITAL_PLUS_MX = 'EDELVIVES_DIGITAL_PLUS_MX';

const APP_GOSTEAM = 'GOSTEAM';
const APP_TATUM = 'TATUM';
const APP_BY_ME = 'BYME';
const FORM_URL = 'https://docs.google.com/forms/d/e/1FAIpQLScJkJ__IsC8ksA-aBVH0sGpnf3CaORDphafMP9HiOpzojPgBQ/viewform';
const CAMPUS_URL = 'https://campus.grupoedelvives.com/login/index.php';
const OTHERS_URL = 'https://www.fundacionedelvives.org/es/formacion';

// Comunes - Help
const ISSUE_DESCRIPTION_MAX_LENGTH = 250;

export {
  APP_BY_ME,
  APP_EDELVIVES_DIGITAL,
  APP_EDELVIVES_DIGITAL_MX,
  APP_EDELVIVES_DIGITAL_PLUS,
  APP_EDELVIVES_DIGITAL_PLUS_MX,
  APP_GOSTEAM,
  APP_TATUM,
  APP_VERSION,
  CAMPUS_URL,
  CUSTOMER_EXPERIENCE,
  EDELVIVES_RESOURCES,
  EDELVIVES_RESOURCES_BAULA,
  EMAIL_RECOVER_PASSWORD_LINK,
  EMAIL_SIGN_UP_LINK,
  ENVIRONMENT,
  FORM_URL,
  GOOGLE_CLIENT_ID,
  GOOGLE_COOKIE_POLICY,
  GOOGLE_PROVIDER,
  GO_STEAM_MORE_INFO_LINK,
  HELP_SALES_EMAIL,
  HELP_TECH_EMAIL,
  ISSUE_DESCRIPTION_MAX_LENGTH,
  IS_POLP_MEXICO,
  MICROSOFT_AUTHORITY,
  MICROSOFT_CLIENT_ID,
  MICROSOFT_PROVIDER,
  ORACULO_API_AUTH,
  ORACULO_CDN_URL,
  ORACULO_PROVIDER,
  ORACULO_SSO_URL,
  OTHERS_URL,
  POLP_API_URL,
  POLP_FEDERATE_APP_ID,
  POLP_FEDERATE_APP_NAME,
  POLP_FEDERATE_APP_SHARED_KEY,
  POLP_JSON_WEB_TOKEN,
  POLP_JSON_WEB_TOKEN_EXPIRED,
  POLP_JSON_WEB_TOKEN_INVALID,
  POLP_URL,
  REDIRECT_URI,
  SUPPORT_EMAIL,
  TATUM_MORE_INFO_LINK,
};
