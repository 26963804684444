import React from 'react';
import Button from '../../atoms/Button';

function Card({
  color,
  tag,
  logo,
  title,
  subtitle,
  contentTag,
  contentDes,
  btnText,
  btnColor,
  btnOnClick,
  extraBtnText,
  extraBtnColor,
  extraBtnOnClick,
}) {
  return (
    <div className={`home-view__card home-view__card--${color}`}>
      {tag && <div className='home-view__card-tag'>{tag}</div>}
      <div className='home-view__card-header'>
        {logo && <img src={logo} alt='' />}
        {title}
        <div className='home-view__card-header-subtitle'>{subtitle && <img src={subtitle} alt='' />}</div>
      </div>
      {contentTag && <div className='home-view__card-content-tag'>{contentTag}</div>}

      <div className='home-view__card-content'>{contentDes}</div>
      <div className='home-view__card-footer'>
        <Button text={btnText} size='big' color={btnColor} onClick={btnOnClick} />
        {extraBtnText && <Button text={extraBtnText} size='big' color={extraBtnColor} onClick={extraBtnOnClick} />}
      </div>
    </div>
  );
}

export default Card;
