import React, { useState } from 'react';

function ContactTabs({ firstTabTitle, firstTabContent, secondTabTitle, secondTabContent }) {
  const [showFirstTab, setShowFirstTab] = useState(true);
  const [showSecondTab, setShowSecondTab] = useState(false);

  const handleSwitchTab = () => {
    setShowFirstTab(!showFirstTab);
    setShowSecondTab(!showSecondTab);
  };

  return (
    <div className='contact-tabs'>
      <div className='contact-tabs__tabs'>
        <div
          className={`contact-tabs-tab__button${showFirstTab ? ' contact-tabs-tab__button--active' : ''}`}
          onClick={() => handleSwitchTab()}
        >
          {firstTabTitle}
        </div>
        <div
          className={`contact-tabs-tab__button${showSecondTab ? ' contact-tabs-tab__button--active' : ''}`}
          onClick={() => handleSwitchTab()}
        >
          {secondTabTitle}
        </div>
      </div>
      {showFirstTab && <div className='contact-tabs__tab-content'>{firstTabContent}</div>}
      {showSecondTab && <div className='contact-tabs__tab-content'>{secondTabContent}</div>}
    </div>
  );
}

ContactTabs.defaultProps = {
  firstTabTitle: 'First Tab',
  firstTabContent:
    'Time it took us To where the water was That’s what the water gave me And time goes quicker Between the two of us Oh, my love, don’t forsake me Take what the water gave me',
  secondTabTitle: 'Second Tab',
  secondTabContent:
    'And oh, poor Atlas The world’s a beast of a burden You’ve been holding up a long time And all this longing And the ships are left to rust That’s what the water gave us',
};

export default ContactTabs;
