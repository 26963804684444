import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DEVELOPMENT, DEVELOPMENT_MX } from '../../../config/config.dev';
import { PRODUCTION, PRODUCTION_MX } from '../../../config/config.prod';
import {
  EMAIL_RECOVER_PASSWORD_LINK,
  EMAIL_SIGN_UP_LINK,
  GOOGLE_PROVIDER,
  IS_POLP_MEXICO,
  MICROSOFT_PROVIDER,
  ORACULO_PROVIDER,
  POLP_API_URL,
  POLP_JSON_WEB_TOKEN,
  POLP_URL,
} from '../../../config/constants';
import { ENV } from '../../../config/environment';
import { getLanguageCode, getTranslation } from '../../../locale/texts';
import { loginMicrosoft } from '../../../services/authService';
import {
  getUserData,
  loginOauth,
  loginOraculoWithCredentials,
  loginOraculoWithToken,
  setOraculoGlobalSession,
} from '../../../services/loginService';
import { get, post } from '../../../services/requestService';
import { validateEmail, validatePassword, validatePasswordLength } from '../../../services/validationService';
import { StoreContext } from '../../../store/store';
import { parseJwt } from '../../../utils/utils';
import useErrorHandler from './useErrorHandler';

export default function useLoginView() {
  const navigate = useNavigate();
  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const redirectUri = params.get('redirect_uri');
  const tokenUser = params.get('user_token');
  const [isTokenUser, setIsTokenUser] = useState(tokenUser);
  const [isAppOrigin, setIsAppOrigin] = useState(redirectUri && redirectUri.length > 0);
  const [state, dispatch] = useContext(StoreContext);
  const passwordRef = useRef(null);
  const [email, setEmail] = useState('');
  const [isPasswordEnable, setIsPasswordEnable] = useState(false);
  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isPasswordLengthValid, setIsPasswordLengthValid] = useState(false);
  const { handleDispatch, handleError, handleEmailResponse } = useErrorHandler(isAppOrigin, navigate);

  // Al modificar el correo tras su verificación se inabilita los elementos de password
  useEffect(() => {
    setIsPasswordEnable(false);
  }, [email]);

  // Al modificar la password se comprueba si es válida
  useEffect(() => {
    setIsPasswordValid(validatePassword(password));
    setIsPasswordLengthValid(validatePasswordLength(password));
  }, [password]);

  useEffect(() => {
    if (tokenUser) {
      socialLogin(tokenUser, ORACULO_PROVIDER);
    }
  }, [isTokenUser]);

  // Comprobar si el usuario está registrado
  const checkIfUserIsRegisteredByEmail = async () => {
    if (email === '' || !validateEmail(email)) {
      handleDispatch('alert', getTranslation('error_data_title'), getTranslation('error_invalid_email'));
      return;
    }

    const checkIfUserIsRegisteredByEmailResult = await get(POLP_API_URL + 'login-status', {
      username: email,
    });

    handleEmailResponse(checkIfUserIsRegisteredByEmailResult);

    if (checkIfUserIsRegisteredByEmailResult.status === 'success') {
      setIsPasswordEnable(true);

      setTimeout(() => {
        passwordRef.current?.focus();
      }, 100);
    } else {
      // En app, mostramos alerta, si no, enviamos email de registro
      if (isAppOrigin) {
        handleDispatch('alert', '', getTranslation('login_error_notfound'));
      } else {
        sendSignUpEmailToUser();
      }
    }
  };

  // Enviar correo de registro a usuario no registrado
  const sendSignUpEmailToUser = async (_email) => {
    if (!_email) _email = email;

    const sendSignUpEmailToUserResult = await post(POLP_API_URL + 'signup-start', {
      email: _email,
      link: POLP_URL + EMAIL_SIGN_UP_LINK + encodeURIComponent(_email),
      lang: getLanguageCode(),
    });

    handleEmailResponse(sendSignUpEmailToUser);

    if (sendSignUpEmailToUserResult.status !== 'success') {
      handleDispatch('alert', getTranslation('error_generic_title'), getTranslation('login_error_sending_email'));

      return;
    }

    navigate('/emailsent');
  };

  // Enviar correo para recuperar constaseña
  const sendEmailForRecoverPassword = async () => {
    const sendEmailForRecoverPasswordResult = await post(POLP_API_URL + 'recover', {
      email: email,
      link: POLP_URL + EMAIL_RECOVER_PASSWORD_LINK + encodeURIComponent(email),
      lang: getLanguageCode(),
    });

    handleEmailResponse(sendEmailForRecoverPasswordResult);

    if (sendEmailForRecoverPasswordResult.status !== 'success') {
      handleDispatch('alert', getTranslation('error_generic_title'), getTranslation('login_error_sending_email'));

      return;
    }

    handleDispatch(
      'success',
      getTranslation('login_recover_title'),
      isAppOrigin ? getTranslation('login_recover_message_short') : getTranslation('login_revover_message'),
      'green'
    );
  };

  const polpLogin = async () => {
    if (isAppOrigin) {
      const getRefreshToken = true;
      const oraculoResult = await loginOraculoWithCredentials(email, password, getRefreshToken);
      if (oraculoResult.error) {
        handleError(oraculoResult);
        return;
      }
      const tokenId = oraculoResult?.tokenId;
      const refreshToken = oraculoResult?.refreshToken;

      const path = redirectUri + '?token=' + tokenId + '&refresh_token=' + refreshToken;
      window.location.href = path;
    } else {
      const oraculoResult = await loginOraculoWithCredentials(email, password, false);
      if (oraculoResult.error) {
        handleError(oraculoResult);
        return;
      }
      generalLogin(oraculoResult, ORACULO_PROVIDER);
    }
  };

  const socialLogin = async (_token, _provider, socialEmail) => {
    const oraculoResult = await loginOraculoWithToken(_token, _provider);
    handleError(oraculoResult, _provider, _token, socialEmail);
    generalLogin(oraculoResult, _provider, _token, socialEmail);
  };

  const generalLogin = async (oraculoResult, _provider, socialToken, socialEmail) => {
    let errorText = '';
    switch (_provider) {
      case GOOGLE_PROVIDER:
        errorText = getTranslation('login_error_google');
        break;
      case MICROSOFT_PROVIDER:
        errorText = getTranslation('login_error_microsoftr_logging');
        break;
      default:
        errorText = getTranslation('login_error_logging');
        break;
    }

    const oauthResult = await loginOauth(oraculoResult.tokenId, ORACULO_PROVIDER);

    if (oauthResult.error) {
      handleError(oauthResult, _provider, socialToken, socialEmail, errorText);
    }

    const userData = await getUserData(oauthResult.jwt);
    console.log(userData?.country_guid, 'country-guid');

    if (!userData) {
      return;
    }

    dispatch({ type: 'USER_SET_VALUES', payload: { ...userData, logged: true } });
    localStorage.setItem(POLP_JSON_WEB_TOKEN, oauthResult.jwt);
    setOraculoGlobalSession(oraculoResult.sid);

    if (shouldRedirectToPolpMx(userData?.country_guid)) {
      const url = ENV().includes('production') ? PRODUCTION_MX.POLP_URL : DEVELOPMENT_MX.POLP_URL;
      setTimeout(() => {
        window.open(url + '?user_token=' + oraculoResult.tokenId, '_self');
      }, 100);
    } else if (shouldRedirectToPolpEs(userData?.country_guid)) {
      const url = ENV().includes('production') ? PRODUCTION.POLP_URL : DEVELOPMENT.POLP_URL;
      setTimeout(() => {
        window.open(url + '?user_token=' + oraculoResult.tokenId, '_self');
      }, 100);
    } else {
      setTimeout(() => {
        navigate('/home');
      }, 100);
    }
  };

  function shouldRedirectToPolpEs(countryGuid) {
    return ENV() !== 'local' && (countryGuid === 'es' || countryGuid === undefined || !countryGuid) && IS_POLP_MEXICO;
  }

  function shouldRedirectToPolpMx(countryGuid) {
    return ENV() !== 'local' && countryGuid === 'mx' && !IS_POLP_MEXICO;
  }

  // Manejador para login con Google
  const googleHandler = async (response) => {
    const { email } = parseJwt(response.credential);
    console.log(response);
    if (!response.credential) {
      handleError(response, '', '', '', getTranslation('login_error_google'));
    } else {
      socialLogin(response.credential, GOOGLE_PROVIDER, email);
    }
  };

  // Manejador para login con Microsoft
  const microsoftHandler = async () => {
    loginMicrosoft(
      (succesResult) => {
        socialLogin(succesResult.sso_token, MICROSOFT_PROVIDER, succesResult.email);
      },
      (errorResult) => {
        if (errorResult.errorCode === 'user_cancelled') {
          handleDispatch(
            'info',
            getTranslation('information_generic_title'),
            getTranslation('microsoft_closed_by_user')
          );
        } else {
          handleDispatch('alert', getTranslation('error_generic_title'), getTranslation('login_error_microsoft'));
        }
      }
    );
  };

  return {
    isAppOrigin,
    navigate,
    setEmail,
    checkIfUserIsRegisteredByEmail,
    isPasswordEnable,
    passwordRef,
    password,
    setPassword,
    polpLogin,
    sendEmailForRecoverPassword,
    isPasswordLengthValid,
    microsoftHandler,
    googleHandler,
    state,
    dispatch,
  };
}
