import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import useOnceUserData from '../hooks/useOnceUserData';
import Drawer from '../modules/components/Drawer';
import Loading from '../modules/components/Loading';
import useLoginView from '../modules/views/LoginView/useLoginView';
import ChangePassword from '../pages/ChangePasswordPage/ChangePasswordPage';
// Pages
import EmailSent from '../pages/EmailSentPage/EmailSentPage';
import Help from '../pages/HelpPage/HelpPage';
import Home from '../pages/HomePage/HomePage';
import LandingHelp from '../pages/LandingHelpPage/LandingHelpPage';
import LandingLegal from '../pages/LandingLegalPage/LandingLegalPage';
import Landing from '../pages/LandingPage/LandingPage';
import Login from '../pages/LoginPage/LoginPage';
import Profile from '../pages/ProfilePage/ProfilePage';
import Recover from '../pages/RecoverPage/RecoverPage';
import SignUp from '../pages/SignUpPage/SignUpPage';
import { logout as _polpLogout } from '../services/loginService';
import { StoreContext } from '../store/store';

const Router = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, dispatch] = useContext(StoreContext);
  const [pathNeedUserData, setPathNeedUserData] = useState(false);
  const [doneManually, setDoneManually] = useState(false);

  // Obtner datos de usuario si hay/es valido el jwt
  // Sino intentar login global
  const userData = useOnceUserData(pathNeedUserData, doneManually);
  useEffect(() => {
    // console.log('index state.user.logged', state.user.logged);
    if (userData && state.user.logged !== userData.logged) {
      // console.log('index userData', userData);
      dispatch({ type: 'USER_SET_VALUES', payload: userData });
    }
  }, [userData]);

  useEffect(() => {
    if (isUserLogged()) setDoneManually(true);
  }, [state.user.logged]);

  useEffect(() => {
    if (isAnUserDataNeededPath(location.pathname)) setPathNeedUserData(true);
  }, [location]);

  // Identifica si la ruta actual no implica realizar acciones adicionales
  const isAnUserDataNeededPath = (path) => {
    switch (path) {
      case '/login': // case '/emailsent':
      case '/recover': // case '/signup':
      case '/home':
      case '/profile':
      case '/change-password':
      case '/support':
        return true;

      default:
        return false;
    }
  };

  const isUserLogged = () => state.user.logged === true;
  const isUserTeacher = () => state.user.role.toLowerCase() === 'teacher';
  const goTo = (where) => <Navigate replace to={where} />;
  const goToTeacher = (where) => (isUserTeacher() ? where : goTo('/profile'));
  const { isAppOrigin } = useLoginView();

  const logout = (toWhere) => {
    navigate(toWhere);
    _polpLogout();
    dispatch({ type: 'USER_CLEAR_VALUES' });
  };

  if (isAnUserDataNeededPath(location.pathname) && state.user.logged === undefined) {
    return (
      <div style={{ backgroundColor: '#fff' }}>
        <Loading />
      </div>
    );
  } else {
    return (
      <>
        <Routes>
          <Route path='/' element={<Landing />} />
          <Route path='/help' element={<LandingHelp />} />
          <Route path='/legal' element={<LandingLegal type='legal' logout={() => logout('/')} />} />
          <Route path='/privacy' element={<LandingLegal type='privacy' logout={() => logout('/')} />} />
          <Route path='/cookies' element={<LandingLegal type='cookies' logout={() => logout('/')} />} />

          <Route path='/login' element={!isUserLogged() ? <Login /> : goTo(isUserTeacher() ? '/home' : '/profile')} />
          <Route
            path='/emailsent'
            element={!isUserLogged() ? <EmailSent /> : goTo(isUserTeacher() ? '/home' : '/profile')}
          />
          <Route
            path='/recover'
            element={!isUserLogged() ? <Recover /> : goTo(isUserTeacher() ? '/home' : '/profile')}
          />
          <Route path='/signup' element={!isUserLogged() ? <SignUp /> : goTo(isUserTeacher() ? '/home' : '/profile')} />

          <Route
            path='/home'
            element={isUserLogged() ? goToTeacher(<Home logout={() => logout('/')} />) : goTo('/login')}
          />
          <Route path='/profile' element={isUserLogged() ? <Profile logout={() => logout('/')} /> : goTo('/login')} />
          <Route path='/change-password' element={isUserLogged() ? goToTeacher(<ChangePassword />) : goTo('/login')} />
          <Route
            path='/support'
            element={isUserLogged() ? goToTeacher(<Help logout={() => logout('/')} />) : goTo('/login')}
          />

          <Route path='*' element={goTo('/')} />
        </Routes>

        {/* Mensajes al usuario */}
        {state.message.visible && !isAppOrigin && (
          <Drawer
            type={state.message.type}
            title={state.message.title}
            text={state.message.text}
            buttons={state.message.buttons}
            onClose={() => {
              dispatch({ type: 'MESSAGE_HIDE_DRAWER' });
            }}
          />
        )}

        {/* Capa vista cargando */}
        {state.loading.isActive === true && <Loading />}
      </>
    );
  }
};

export default Router;
