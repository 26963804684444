import React from 'react';
import LandingLegalView from '../../modules/views/LandingLegalView/LandingLegalView';

function LandingLegalPage({ logout, type }) {
  return (
    <div className='landing-legal-page'>
      <LandingLegalView logout={logout} type={type} />
    </div>
  );
}

export default LandingLegalPage;
