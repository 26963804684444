import React, { useContext } from 'react';
import { getTranslation } from '../../../locale/texts';
import { StoreContext } from '../../../store/store';
import Button from '../../atoms/Button';

function LandingSectionHeader({ handleGoHome, handleGoToPolp }) {
  const [state, dispatch] = useContext(StoreContext);

  return (
    <div className='landing-section-header'>
      <div className='landing-section-header__content'>
        <div className='landing-section-header__back-button' onClick={handleGoHome}>
          {getTranslation('home_header_back')}
        </div>
        <div className='landing-section-header__button'>
          <Button text={getTranslation('landing_go_to_polp')} color='green' onClick={handleGoToPolp} />
        </div>
      </div>
      <div className='landing-section-header__separator'>
        <div className='line line--orange'></div>
        <div className='line line--blue'></div>
        <div className='line line--pink'></div>
        <div className='line line--green'></div>
      </div>
    </div>
  );
}

export default LandingSectionHeader;
