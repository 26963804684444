import React from 'react';
import RecoverView from '../../modules/views/RecoverView/RecoverView';

function RecoverPage() {
  return (
    <div className='recover-page'>
      <RecoverView />
    </div>
  );
}

export default RecoverPage;
