import React from 'react';
import Select, { components } from 'react-select';

function TwoLevelsSelector({
  selectedOptions,
  setSelectedOptions,
  options,
  value,
  placeholder = '',
  reference,
  isSearchable = true,
  maxMenuHeight = '200',
}) {
  const handlerOnChange = (values, event) => {
    setSelectedOptions(values);
  };

  const _checkAlreadyselectedOptions = (_optionsClicked) => {
    const checkedData = { alreadySelectedCount: 0, optionsClickedNoSelected: [] };

    for (let c = 0, cMax = _optionsClicked.length; c < cMax; c++) {
      let isSelected = false;

      for (let s = 0, sMax = selectedOptions.length; s < sMax; s++) {
        if (_optionsClicked[c].value === selectedOptions[s].value) {
          isSelected = true;
          checkedData.alreadySelectedCount++;
          break;
        }
      }

      if (!isSelected) checkedData.optionsClickedNoSelected.push(_optionsClicked[c]);
    }

    return checkedData;
  };

  // handleOptionsHeaderClick
  // Si tenía todos quitarlos sino poner todos (o los que falten)
  const handleOptionsHeaderClick = (props, actionMeta) => {
    const optionsClicked = props.data.options;
    const newOptionsSelected = [];

    // Comprobar los que estan ya seleccionados
    const checkedOptionsData = _checkAlreadyselectedOptions(optionsClicked);

    // Tenía todos: quitarlos de la selección
    if (optionsClicked.length === checkedOptionsData.alreadySelectedCount) {
      for (let s = 0, sMax = selectedOptions.length; s < sMax; s++) {
        const isInClickedOptions = optionsClicked.some((item) => selectedOptions[s].value === item.value);
        if (!isInClickedOptions) newOptionsSelected.push(selectedOptions[s]);
      }
    }
    // No estaban todos o incuso ninguno: incluirlos en la selección
    else {
      for (let o = 0, oMax = options.length; o < oMax; o++) {
        for (let s = 0, sMax = options[o].options.length; s < sMax; s++) {
          const isAlredySelected = selectedOptions.some((item) => options[o].options[s].value === item.value);
          if (isAlredySelected) newOptionsSelected.push(options[o].options[s]);
          else {
            const isNewlySelected = optionsClicked.some((item) => options[o].options[s].value === item.value);
            if (isNewlySelected) newOptionsSelected.push(options[o].options[s]);
          }
        }
      }
    }

    setSelectedOptions(newOptionsSelected);
  };

  const CustomGroupHeading = (props) => {
    return (
      <div className='group-heading-wrapper' onClick={() => handleOptionsHeaderClick(props)}>
        <components.GroupHeading {...props} />
      </div>
    );
  };

  return (
    <Select
      options={options}
      value={value}
      placeholder={placeholder}
      ref={reference}
      onChange={handlerOnChange}
      isMulti
      isSearchable={isSearchable}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      components={{ GroupHeading: CustomGroupHeading, IndicatorSeparator: false }}
      className='two-levels-selector'
      classNamePrefix='select'
      maxMenuHeight={maxMenuHeight}
      menuPlacement='auto'
    />
  );
}

export default TwoLevelsSelector;
