import React from 'react';
import LoginView from '../../modules/views/LoginView/LoginView';

function LoginPage() {
  return (
    <div className='login-page'>
      <LoginView />
    </div>
  );
}

export default LoginPage;
