import React, { useState } from 'react';
import AlertIcon from '../../../assets/icons/drawer_alert.svg';
import SuccessIcon from '../../../assets/icons/drawer_success.svg';
import CloseButton from '../../atoms/CloseButton';

function Drawer({ type, title, text, buttons, onClose }) {
  //  Types
  //  - info
  //  - success
  //  - alert

  const [animateOut, setAnimateOut] = useState(false);

  const handlerClose = () => {
    setAnimateOut(true);

    if (onClose) {
      setTimeout(() => {
        onClose();
      }, 450); // Tiempo que tarda la animación enterminar. TODO mejorar esto
    }
  };

  return (
    <div className={`drawer drawer--${type} animated ${animateOut ? 'bounceOutDown' : 'bounceInUp'}`}>
      <div className='drawer__close-btn'>
        <CloseButton onClick={handlerClose} />
      </div>
      <div className='drawer__content'>
        {type === 'success' && (
          <div className='drawer__icon'>
            <img src={SuccessIcon} alt='' />
          </div>
        )}
        {type === 'alert' && (
          <div className='drawer__icon'>
            <img src={AlertIcon} alt='' />
          </div>
        )}
        <div className='drawer__title'>{title}</div>
        <div className='drawer__text'>{text}</div>
        {buttons && buttons.length && buttons.length > 0 && (
          <div className='drawer__buttons'>{buttons.map((item, index) => item)}</div>
        )}
      </div>
    </div>
  );
}

Drawer.defaultProps = {
  type: 'info',
  title: 'This is a drawer',
  text: "I walked through the door with you, the air was cold, but something about it felt like home somehow and I, left my scarf there at your sister's house and you've still got it in your drawer even now. And you call me up again just to break me like a promise. So casually cruel in the name of being honest.",
  actions: null,
};

export default Drawer;
