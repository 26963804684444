import { useContext } from 'react';
import { getTranslation } from '../../../locale/texts';
import { StoreContext } from '../../../store/store';

export default function useErrorHandler(isAppOrigin, navigate) {
  const [state, dispatch] = useContext(StoreContext);

  // Cambiamos el estilo de los errores si viene de app
  const alertType = isAppOrigin ? 'MESSAGE_SHOW_ALERT' : 'MESSAGE_SHOW_DRAWER';

  const handleDispatch = (type, title, text, color) => {
    dispatch({
      type: alertType,
      payload: {
        type: type,
        title: title || '',
        text: text || '',
        color: color || '',
      },
    });
  };

  const handleError = (response, _provider, _token, socialEmail, customText) => {
    switch (response.error) {
      case 'network error':
        handleDispatch('alert', getTranslation('error_generic_title'), getTranslation('error_network'));
        break;
      case 'user not found':
        dispatch({
          type: 'SOCIAL_SET_VALUES',
          payload: { provider: _provider, token: _token, email: socialEmail },
        });
        setTimeout(() => {
          navigate('/signup');
        }, 50);
        break;
      case 'user and password mismatch':
        handleDispatch(
          'alert',
          isAppOrigin ? '' : getTranslation('error_data_title'),
          getTranslation('login_error_incorrect_password')
        );
        break;
      case 'popup_closed_by_user':
        handleDispatch('info', getTranslation('information_generic_title'), getTranslation('google_closed_by_user'));
        break;
      case null:
        break;
      case undefined:
        break;
      default:
        break;
    }
  };

  const handleEmailResponse = (response) => {
    if (response.networkError === true) {
      handleDispatch('alert', getTranslation('error_generic_title'), getTranslation('error_network'));
    }
  };
  return { handleDispatch, handleError, handleEmailResponse };
}
