const initialState = {
  username: '', // Es el correo
  name: '',
  lastname: '',
  roster: { school: null, grades: [], subjects: [] },
  id: null,
  oauth_accounts: null,
  logged: undefined,
  role: undefined,
};

export default {
  key: 'user',

  initialState: initialState,

  reducer: (state, action) => {
    switch (action.type) {
      case 'USER_SET_VALUES':
        return { ...state, ...action.payload };

      case 'USER_CLEAR_VALUES':
        return { ...initialState, logged: false };

      default:
        return state;
    }
  },
};
