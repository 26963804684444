import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import {
  HELP_TECH_EMAIL,
  ISSUE_DESCRIPTION_MAX_LENGTH,
  POLP_API_URL,
  POLP_JSON_WEB_TOKEN,
  POLP_JSON_WEB_TOKEN_EXPIRED,
  POLP_JSON_WEB_TOKEN_INVALID,
} from '../../../config/constants';
import { useJWTokenFailedFeedback } from '../../../hooks/useJWTokenFailedFeedback';
import { getTranslation } from '../../../locale/texts';
import { post } from '../../../services/requestService';
import { StoreContext } from '../../../store/store';
import Button from '../../atoms/Button';

function HelpFormTech() {
  const ISSUE_OPTIONS = [
    { value: '1', label: getTranslation('help_tech_issue_options_account') },
    { value: '2', label: getTranslation('help_tech_issue_options_license') },
    { value: '3', label: getTranslation('help_tech_issue_options_issue') },
    { value: '4', label: getTranslation('help_tech_issue_options_information') },
    { value: '5', label: getTranslation('help_tech_issue_options_other') },
  ];

  const DEVICE_OPTIONS = [
    { value: '1', label: getTranslation('help_tech_device_options_computer') },
    { value: '2', label: getTranslation('help_tech_device_options_chromebook') },
    { value: '3', label: getTranslation('help_tech_device_options_tablet') },
    { value: '4', label: getTranslation('help_tech_device_options_other') },
  ];

  const OS_OPTIONS = [
    { value: '1', label: getTranslation('help_tech_os_options_windows') },
    { value: '2', label: getTranslation('help_tech_os_options_mac') },
    { value: '3', label: getTranslation('help_tech_os_options_android') },
    { value: '4', label: getTranslation('help_tech_os_options_ios') },
  ];

  const BROWSER_OPTIONS = [
    { value: '1', label: getTranslation('help_tech_browser_options_chrome') },
    { value: '2', label: getTranslation('help_tech_browser_options_firefox') },
    { value: '3', label: getTranslation('help_tech_browser_options_edge') },
    { value: '4', label: getTranslation('help_tech_browser_options_explorer') },
    { value: '5', label: getTranslation('help_tech_browser_options_opera') },
    { value: '6', label: getTranslation('help_tech_browser_options_safari') },
  ];

  const navigate = useNavigate();
  const [state, dispatch] = useContext(StoreContext);
  const { stoppedForJWToken } = useJWTokenFailedFeedback();
  const [selectedIssue, setSelectedIssue] = useState(null);
  const [issueDescription, setIssueDescription] = useState('');
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedOs, setSelectedOs] = useState(null);
  const [selectedBrowser, setSelectedBrowser] = useState(null);
  const [selectedScreencapName, setSelectedScreencapName] = useState(null);
  const [selectedScreencapFile, setSelectedScreencapFile] = useState(null);
  const [browserSelectionActive, setBrowserSelectionActive] = useState(false);
  const [isSendButtonEnabled, setIsSendButtonEnabled] = useState(false);

  // Se comprueba si etán los campos necesarios para realizar la solicitud
  useEffect(() => {
    let _sendButtonEnabled = true;

    if (!selectedIssue || !selectedDevice || !selectedOs || !selectedBrowser || !issueDescription) {
      _sendButtonEnabled = false;
    }

    setIsSendButtonEnabled(_sendButtonEnabled);
  }, [selectedIssue, issueDescription, selectedDevice, selectedOs, selectedBrowser]);

  const handleIssue = (value) => {
    setSelectedIssue(value);
    setBrowserSelectionActive(false);
  };

  const handleIssueDescription = (value) => {
    setIssueDescription(value.trim());
  };

  const handleDevice = (value) => {
    setSelectedDevice(value);
    setBrowserSelectionActive(false);
  };

  const handleOs = (value) => {
    setSelectedOs(value);
    setBrowserSelectionActive(false);
  };

  const handleBrowser = (value) => {
    setSelectedBrowser(value);
    setBrowserSelectionActive(false);
  };

  const handleScreencap = (file) => {
    setSelectedScreencapName(file.name);
    setSelectedScreencapFile(file);
  };

  const sendIssue = async () => {
    // Obtener contenido para mandar al correo
    const lineStart = '  <tr><td style="padding:0.5em 1em;"><b>';
    const lineMiddle = '</b></td><td>';
    const lineEnd = '</td></tr>\n';

    let htmlIssueContent = '<h2>' + getTranslation('help_tech_email_issue_introduction') + '</h2>\n<table>\n';

    htmlIssueContent +=
      lineStart + getTranslation('help_tech_email_issue_who') + lineMiddle + state.user.username + lineEnd;

    htmlIssueContent +=
      lineStart + getTranslation('help_tech_email_issue_type') + lineMiddle + selectedIssue.label + lineEnd;

    htmlIssueContent +=
      '  <tr style="vertical-align: baseline;"><td style="padding:0.5em 1em;"><b>' +
      getTranslation('help_tech_email_issue_description') +
      lineMiddle +
      issueDescription.replace(/(?:\r\n|\r|\n)/g, '<br>') +
      lineEnd;

    htmlIssueContent +=
      lineStart + getTranslation('help_tech_email_issue_device') + lineMiddle + selectedDevice.label + lineEnd;
    htmlIssueContent +=
      lineStart + getTranslation('help_tech_email_issue_os') + lineMiddle + selectedOs.label + lineEnd;
    htmlIssueContent +=
      lineStart + getTranslation('help_tech_email_issue_browser') + lineMiddle + selectedBrowser.label + lineEnd;

    if (selectedScreencapName) {
      htmlIssueContent +=
        lineStart + getTranslation('help_tech_email_issue_attached') + lineMiddle + selectedScreencapName + lineEnd;
    }

    htmlIssueContent += '</table>';

    const formData = new FormData();
    formData.append('to', HELP_TECH_EMAIL);
    formData.append('subject', getTranslation('help_tech_email_issue_subject'));
    formData.append('message_html', htmlIssueContent);
    formData.append('upfile1', selectedScreencapFile);

    const polpJWT = localStorage.getItem(POLP_JSON_WEB_TOKEN);
    if (!polpJWT) {
      stoppedForJWToken();
      return false;
    }

    const sendEmailResult = await post(POLP_API_URL + 'sendmail', formData, { Authorization: polpJWT }, true);

    if (sendEmailResult.networkError === true) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text: getTranslation('error_network'),
        },
      });

      return false;
    }

    const errorName = sendEmailResult.name;
    if (errorName && (errorName === POLP_JSON_WEB_TOKEN_EXPIRED || errorName === POLP_JSON_WEB_TOKEN_INVALID)) {
      stoppedForJWToken();
      return false;
    }

    if (sendEmailResult.status !== 'success') {
      console.log('sendEmailResult error', sendEmailResult);

      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text: getTranslation('help_tech_email_issue_error_text'),
        },
      });

      return false;
    }

    // Enviar
    dispatch({
      type: 'MESSAGE_SHOW_DRAWER',
      payload: {
        type: 'success',
        title: getTranslation('help_tech_email_issue_success_title'),
        text: getTranslation('help_tech_email_issue_success_text'),
      },
    });
  };

  return (
    <div className='help-form-tech'>
      <div className='help-form-tech__form'>
        <form>
          <div className='help-form-tech__label'>{getTranslation('help_tech_label_issue')}</div>
          <div className='help-form-tech__element'>
            <Select
              value={selectedIssue}
              onChange={handleIssue}
              name='issue'
              options={ISSUE_OPTIONS}
              className='basic-select'
              classNamePrefix='select'
              placeholder={getTranslation('help_select_placeholder')}
              components={{ IndicatorSeparator: false }}
              maxMenuHeight='200'
              menuPlacement='auto'
              isSearchable={false}
              onMenuOpen={() => {
                setBrowserSelectionActive(true);
              }}
              onMenuClose={() => {
                setBrowserSelectionActive(false);
              }}
            />
          </div>

          <div className='help-form-tech__label'>{getTranslation('help_tech_label_device')}</div>
          <div className='help-form-tech__element'>
            <Select
              onChange={handleDevice}
              name='device'
              options={DEVICE_OPTIONS}
              className='basic-select'
              classNamePrefix='select'
              placeholder={getTranslation('help_select_placeholder')}
              components={{ IndicatorSeparator: false }}
              maxMenuHeight='200'
              menuPlacement='auto'
              isSearchable={false}
              onMenuOpen={() => {
                setBrowserSelectionActive(true);
              }}
              onMenuClose={() => {
                setBrowserSelectionActive(false);
              }}
            />
          </div>

          <div className='help-form-tech__label'>{getTranslation('help_tech_label_os')}</div>
          <div className='help-form-tech__element'>
            <Select
              onChange={handleOs}
              name='os'
              options={OS_OPTIONS}
              className='basic-select'
              classNamePrefix='select'
              placeholder={getTranslation('help_select_placeholder')}
              components={{ IndicatorSeparator: false }}
              maxMenuHeight='200'
              menuPlacement='auto'
              isSearchable={false}
              onMenuOpen={() => {
                setBrowserSelectionActive(true);
              }}
              onMenuClose={() => {
                setBrowserSelectionActive(false);
              }}
            />
          </div>

          <div className='help-form-tech__label'>{getTranslation('help_tech_label_browser')}</div>
          <div className='help-form-tech__element'>
            <Select
              onChange={handleBrowser}
              name='browser'
              options={BROWSER_OPTIONS}
              className='basic-select'
              classNamePrefix='select'
              placeholder={getTranslation('help_select_placeholder')}
              components={{ IndicatorSeparator: false }}
              maxMenuHeight='200'
              menuPlacement='auto'
              isSearchable={false}
              onMenuOpen={() => {
                setBrowserSelectionActive(true);
              }}
              onMenuClose={() => {
                setBrowserSelectionActive(false);
              }}
            />
          </div>

          <label className='help-form-tech__label' htmlFor='description'>
            {getTranslation('help_textarea_placeholder')}
          </label>
          <div className='help-form-tech__element'>
            <textarea
              name='issueDescription'
              placeholder={getTranslation('help_textarea_placeholder')}
              maxLength={ISSUE_DESCRIPTION_MAX_LENGTH}
              onChange={(e) => {
                e.preventDefault();
                handleIssueDescription(e.target.value);
              }}
            ></textarea>
          </div>

          <div className='help-form-tech__label'>{getTranslation('help_tech_label_screencap')}</div>
          <div className='help-form-tech__element'>
            <div className='input-file' style={{ pointerEvents: browserSelectionActive ? 'none' : 'auto' }}>
              <input
                className='input-file__input'
                type='file'
                id='screencap'
                name='screencap'
                accept='image/png, image/jpeg, image/jpg'
                onChange={(event) => {
                  handleScreencap(event.target.files[0]);
                }}
              />
              <label className='input-file__label' htmlFor='screencap'>
                {!selectedScreencapName ? (
                  <>
                    <span>{getTranslation('help_file_placeholder_drag')}</span>
                    <span>{getTranslation('help_file_placeholder_select')}</span>
                  </>
                ) : (
                  <p>{selectedScreencapName}</p>
                )}
              </label>
            </div>
          </div>

          <div className='help-form-tech__actions'>
            <Button
              text={getTranslation('help_send_button')}
              size='big'
              color='black'
              disabled={!isSendButtonEnabled}
              onClick={(e) => {
                e.preventDefault();
                sendIssue();
              }}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default HelpFormTech;
