import React, { useContext } from 'react';
import { getTranslation } from '../locale/texts';
import { StoreContext } from '../store/store';

export const useJWTokenFailedFeedback = () => {
  const [state, dispatch] = useContext(StoreContext);

  const stoppedForJWToken = () => {
    dispatch({
      type: 'MESSAGE_SHOW_DRAWER',
      payload: {
        type: 'alert',
        title: getTranslation('error_generic_title'),
        text: getTranslation('error_jwtoken'),
        buttons: [
          <div
            style={{ padding: '0.5em 1em' }}
            key='1'
            onClick={() => {
              dispatch({ type: 'MESSAGE_HIDE_DRAWER' });
              dispatch({ type: 'USER_CLEAR_VALUES' });
            }}
          >
            <span>{'LOGIN'}</span>
          </div>,
        ],
      },
    });
  };

  return { stoppedForJWToken };
};
