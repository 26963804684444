import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import {
  HELP_SALES_EMAIL,
  ISSUE_DESCRIPTION_MAX_LENGTH,
  POLP_API_URL,
  POLP_JSON_WEB_TOKEN,
  POLP_JSON_WEB_TOKEN_EXPIRED,
  POLP_JSON_WEB_TOKEN_INVALID,
} from '../../../config/constants';
import { useJWTokenFailedFeedback } from '../../../hooks/useJWTokenFailedFeedback';
import { getTranslation } from '../../../locale/texts';
import { post } from '../../../services/requestService';
import { getGrades, getSubjects } from '../../../services/rosterDataService';
import { StoreContext } from '../../../store/store';
import Button from '../../atoms/Button';
import InputText from '../../atoms/InputText';
import TwoLevelsSelector from '../TwoLevelsSelector';

function HelpFormSales() {
  const REASON_OPTIONS = [
    { value: '1', label: getTranslation('help_sales_reason_business_visit') },
    { value: '2', label: getTranslation('help_sales_reason_telephone_business_contact') },
    { value: '3', label: getTranslation('help_sales_reason_request_demo') },
    { value: '4', label: getTranslation('help_sales_reason_other') },
  ];

  const navigate = useNavigate();
  const [state, dispatch] = useContext(StoreContext);
  const { stoppedForJWToken } = useJWTokenFailedFeedback();
  const [selectedReason, setSelectedReason] = useState(null);
  const [description, setDescription] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [gradesOptions, setGradesOptions] = useState([]);
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState(null);
  const [project, setProject] = useState('');
  const [isSendButtonEnabled, setIsSendButtonEnabled] = useState(false);

  useEffect(() => {
    getGradesFromService();
    getSubjectsFromService();
  }, []);

  // Se comprueba si etán los campos necesarios para realizar la solicitud
  useEffect(() => {
    // Aquí además se "limpia" la descripción y/o el teléfono si dejan de ser necesarios
    //  el hecho de limpiarlos hace que se vuelva a entra en ese useEffect, con lo que no se realiza la comprobación
    //  la siguiente vez
    if (!isDescriptionNeeded() && description !== '') {
      setDescription('');
    } else if (selectedReason?.value !== '2' && phoneNumber !== '') {
      setPhoneNumber('');
    } else {
      let _sendButtonEnabled = true;

      // La razón es obligatoria y común para cualquier envio
      switch (selectedReason?.value) {
        case undefined: {
          _sendButtonEnabled = false;
          break;
        }

        case '1': {
          const hasSelectedGrades = selectedGrades && selectedGrades.length > 0;
          const hasSelectedSubjects = selectedSubjects && selectedSubjects.length > 0;
          if (!hasSelectedGrades || (!hasSelectedSubjects && project === '')) _sendButtonEnabled = false;
          break;
        }

        case '2': {
          if (description === '' || phoneNumber === '') _sendButtonEnabled = false;
          break;
        }

        default: {
          if (description === '') _sendButtonEnabled = false;
        }
      }

      setIsSendButtonEnabled(_sendButtonEnabled);
    }
  }, [selectedReason, description, phoneNumber, selectedGrades, selectedSubjects, project]);

  const isDescriptionNeeded = () => {
    return !(selectedReason?.value === '1');
  };

  const getGradesFromService = async () => {
    const _grades = await getGrades();

    if (_grades.error) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text: getTranslation('help_sales_error_getting_grades'),
        },
      });

      return;
    }

    setGradesOptions(_grades);
  };

  const getSubjectsFromService = async () => {
    const _subjects = await getSubjects();

    if (_subjects.error) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text: getTranslation('help_sales_error_getting_subjects'),
        },
      });

      return;
    }

    setSubjectOptions(_subjects);
  };

  const handleReason = (value) => {
    setSelectedReason(value);
  };

  const handleDescription = (value) => {
    setDescription(value.trim());
  };

  const handleSubjects = (value) => {
    setSelectedSubjects(value);
  };

  const handleProject = (value) => {
    setProject(value);
  };

  const handlePhoneNumber = (value) => {
    setPhoneNumber(value);
  };

  const sendIssue = async () => {
    // Obtener contenido para mandar al correo
    const lineStart = '  <tr><td style="padding:0.5em 1em;"><b>';
    const lineStartMultiline = '  <tr style="vertical-align: baseline;"><td style="padding:0.5em 1em;"><b>';
    const lineMiddle = '</b></td><td>';
    const lineEnd = '</td></tr>\n';

    let htmlIssueContent = '<h2>' + getTranslation('help_sales_email_introduction') + '</h2>\n<table>\n';

    htmlIssueContent += lineStart + getTranslation('help_sales_email_who') + lineMiddle + state.user.username + lineEnd;

    htmlIssueContent +=
      lineStart + getTranslation('help_sales_email_reason') + lineMiddle + selectedReason.label + lineEnd;

    switch (selectedReason.value) {
      case '1': {
        htmlIssueContent +=
          lineStartMultiline +
          getTranslation('help_sales_email_courses') +
          lineMiddle +
          selectedGrades.map((e) => e.label).join(', ') +
          lineEnd;

        if (selectedSubjects && selectedSubjects.length > 0) {
          htmlIssueContent +=
            lineStartMultiline +
            getTranslation('help_sales_email_study_subjects') +
            lineMiddle +
            selectedSubjects.map((e) => e.label).join(', ') +
            lineEnd;
        }

        if (project !== '') {
          htmlIssueContent +=
            lineStartMultiline + getTranslation('help_sales_email_projects') + lineMiddle + project + lineEnd;
        }
        break;
      }

      case '2': {
        htmlIssueContent +=
          lineStart + getTranslation('help_sales_email_phone_number') + lineMiddle + phoneNumber + lineEnd;
        htmlIssueContent +=
          lineStartMultiline +
          getTranslation('help_sales_email_description') +
          lineMiddle +
          description.replace(/(?:\r\n|\r|\n)/g, '<br>') +
          lineEnd;
        break;
      }

      default: {
        htmlIssueContent +=
          lineStartMultiline +
          getTranslation('help_sales_email_description') +
          lineMiddle +
          description.replace(/(?:\r\n|\r|\n)/g, '<br>') +
          lineEnd;
      }
    }

    htmlIssueContent += '</table>';

    const formData = new FormData();
    formData.append('to', HELP_SALES_EMAIL);
    formData.append('subject', getTranslation('help_sales_email_subject_text'));
    formData.append('message_html', htmlIssueContent);

    const polpJWT = localStorage.getItem(POLP_JSON_WEB_TOKEN);
    if (!polpJWT) {
      stoppedForJWToken();
      return false;
    }

    const sendEmailResult = await post(POLP_API_URL + 'sendmail', formData, { Authorization: polpJWT }, true);

    if (sendEmailResult.networkError === true) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text: getTranslation('error_network'),
        },
      });

      return false;
    }

    const errorName = sendEmailResult.name;
    if (errorName && (errorName === POLP_JSON_WEB_TOKEN_EXPIRED || errorName === POLP_JSON_WEB_TOKEN_INVALID)) {
      stoppedForJWToken();
      return false;
    }

    if (sendEmailResult.status !== 'success') {
      console.log('sendEmailResult error', sendEmailResult);

      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text: getTranslation('help_sales_email_error_text'),
        },
      });

      return false;
    }

    // Enviar
    dispatch({
      type: 'MESSAGE_SHOW_DRAWER',
      payload: {
        type: 'success',
        title: getTranslation('help_sales_email_success_title'),
        text: getTranslation('help_sales_email_success_text'),
      },
    });
  };

  return (
    <div className='help-form-sales'>
      <div className='help-form-sales__form'>
        <form>
          <div className='help-form-sales__label'>{getTranslation('help_sales_reason_label')}</div>
          <div className='help-form-sales__element'>
            <Select
              value={selectedReason}
              onChange={handleReason}
              name='reason'
              options={REASON_OPTIONS}
              className='basic-select'
              classNamePrefix='select'
              placeholder={getTranslation('help_select_placeholder')}
              components={{ IndicatorSeparator: false }}
              maxMenuHeight='200'
              menuPlacement='auto'
              isSearchable={false}
            />
          </div>

          {selectedReason?.value === '1' && (
            <div>
              <div className='help-form-sales__label'>{getTranslation('help_sales_courses_label')}</div>
              <div className='help-form-sales__element'>
                <TwoLevelsSelector
                  selectedOptions={selectedGrades}
                  setSelectedOptions={setSelectedGrades}
                  value={selectedGrades}
                  options={gradesOptions}
                  reference={null}
                  isSearchable={true}
                  placeholder={getTranslation('help_sales_project_placehoder')}
                  maxMenuHeight='200'
                />
              </div>

              <div className='help-form-sales__label'>{getTranslation('help_sales_subjects_label')}</div>
              <div className='help-form-sales__element'>
                <Select
                  value={selectedSubjects}
                  onChange={handleSubjects}
                  options={subjectOptions}
                  placeholder={getTranslation('help_sales_subjects_placehoder')}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  isSearchable={true}
                  components={{ IndicatorSeparator: false }}
                  name='subject'
                  className='basic-select'
                  classNamePrefix='select'
                  maxMenuHeight='200'
                  menuPlacement='auto'
                />
              </div>

              <div className='help-form-sales__label'>{getTranslation('help_sales_project_label')}</div>
              <div className='help-form-sales__element'>
                <InputText
                  value={project}
                  onChange={handleProject}
                  label={getTranslation('help_sales_project_placehoder')}
                  onEnterPressed={(e) => {
                    e.preventDefault();
                  }}
                />
              </div>
            </div>
          )}
          {selectedReason?.value === '2' && (
            <>
              <div className='help-form-sales__label'>{getTranslation('help_sales_phone_number_label')}</div>
              <div className='help-form-sales__element'>
                <InputText
                  value={phoneNumber}
                  onChange={handlePhoneNumber}
                  label={getTranslation('help_sales_phone_number_placehoder')}
                  onEnterPressed={(e) => {
                    e.preventDefault();
                  }}
                />
              </div>
            </>
          )}
          {selectedReason && selectedReason?.value !== '1' && (
            <div className='help-form-sales__element'>
              <textarea
                name='issueDescription'
                placeholder={getTranslation('help_textarea_placeholder')}
                maxLength={ISSUE_DESCRIPTION_MAX_LENGTH}
                onChange={(e) => {
                  e.preventDefault();
                  handleDescription(e.target.value);
                }}
              ></textarea>
            </div>
          )}

          <div className='help-form-sales__actions'>
            <Button
              text={getTranslation('help_send_button')}
              size='big'
              color='black'
              disabled={!isSendButtonEnabled}
              onClick={(e) => {
                e.preventDefault();
                sendIssue();
              }}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default HelpFormSales;
