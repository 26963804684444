import React from 'react';
import ChevronDown from '../../../assets//icons/chevron_down.svg';
import { getTranslation } from '../../../locale/texts';

const hideBackButton = (onProfile, role) => onProfile && role.toLowerCase() !== 'teacher';

function SectionHeader({ handleGoHome, handleGoToProfile, userData, logout, isProfile }) {
  return (
    <div className='section-header'>
      <div className='section-header__content'>
        {hideBackButton(isProfile === true, userData.role) ? (
          <div></div>
        ) : (
          <div className='section-header__back-button' onClick={handleGoHome}>
            {getTranslation('home_header_back')}
          </div>
        )}
        <div className='section-header__profile-menu'>
          <div className='section-header__profile-menu-button'>
            <span className='truncate'>{userData && userData.name ? userData.name : ''}</span>
            <img src={ChevronDown} alt='' />
          </div>
          <div className='section-header__profile-menu-content'>
            {isProfile !== true && (
              <div className='section-header__profile-menu-item' onClick={handleGoToProfile}>
                {getTranslation('home_header_edit_profile')}
              </div>
            )}
            <div className='section-header__profile-menu-item' onClick={logout}>
              {getTranslation('home_header_sign_out')}
            </div>
          </div>
        </div>
      </div>
      <div className='section-header__separator'>
        <div className='line line--orange'></div>
        <div className='line line--blue'></div>
        <div className='line line--pink'></div>
        <div className='line line--green'></div>
      </div>
    </div>
  );
}

export default SectionHeader;
