import React from 'react';

function Checkbox({ label, value, onChange, checkmarkMiddle, icon, iconTooltip, ...props }) {
  return (
    <div className='checkbox'>
      <label className='checkbox__container'>
        <div className='checkbox__label'>
          {icon && !iconTooltip && <img src={icon} alt='' />}

          {icon && iconTooltip && (
            <div className='checkbox__label-tooltip-wrap'>
              <img src={icon} alt='' />
              <span className='checkbox__label-tooltip-text'>{iconTooltip}</span>
            </div>
          )}

          <span>{label}</span>
        </div>
        <input
          className='checkbox__checkbox'
          type='checkbox'
          checked={value}
          {...props}
          onChange={(event) => onChange(event.target.checked)}
        />
        <span
          className={'checkbox__checkmark' + (checkmarkMiddle === true ? ' checkbox__checkmark-vertical-middle' : '')}
        ></span>
      </label>
    </div>
  );
}

Checkbox.defaultProps = {
  label: 'Label',
};

export default Checkbox;
