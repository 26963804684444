// Correo
const emailRegex =
  /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;

export const validateEmail = (email) => emailRegex.test(email);

// Contraseña
const PASSWORD_MINIMUN_LENGTH = 8;
export const validatePassword = (password) => {
  // Logitud mínima
  if (password.length < PASSWORD_MINIMUN_LENGTH) return false;

  // Debe tener minúsculas
  if (password.toUpperCase() === password) return false;

  // Debe tener mayúsculas
  if (password.toLowerCase() === password) return false;

  // Debe tener numeros
  if (!/\d/.test(password)) return false;

  return true;
};

export const validatePasswordLength = (password) => {
  if (password.length < PASSWORD_MINIMUN_LENGTH) return false;

  return true;
};
