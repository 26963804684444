import React from 'react';
import CloseBtn from '../../../assets/icons/close.svg';

function CloseButton({ onClick }) {
  return (
    <button className='close-button' onClick={onClick}>
      <img src={CloseBtn} alt='' />
    </button>
  );
}

CloseButton.defaultProps = {
  onClick: () => console.log('❌ Click close!'),
};

export default CloseButton;
