import React from 'react';
import HomeView from '../../modules/views/LandingView/LandingView';

function LandingPage() {
  return (
    <div className='landing-page'>
      <HomeView />
    </div>
  );
}

export default LandingPage;
