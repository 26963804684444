import EdelvivesDigitalPlusIcon from '../assets/icons/ed-digital-plus.svg';
import EdelvivesDigitalIcon from '../assets/icons/ed-digital.svg';
import GoSteamIcon from '../assets/icons/go-steam.svg';
import TatumIcon from '../assets/icons/tatum.svg';
import { APP_EDELVIVES_DIGITAL, APP_EDELVIVES_DIGITAL_PLUS, APP_GOSTEAM, APP_TATUM } from '../config/constants';

export const getAppIcon = (appName) => {
  switch (appName) {
    case APP_EDELVIVES_DIGITAL:
      return EdelvivesDigitalIcon;

    case APP_EDELVIVES_DIGITAL_PLUS:
      return EdelvivesDigitalPlusIcon;

    case APP_GOSTEAM:
      return GoSteamIcon;

    case APP_TATUM:
      return TatumIcon;
  }

  return null;
};
