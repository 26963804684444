import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowIcon from '../../../assets/icons/arrow-sm-right.svg';
import Check from '../../../assets/icons/check-big.svg';
import PolpLogo from '../../../assets/polp_logo_popup.svg';
import {
  POLP_API_URL,
  POLP_JSON_WEB_TOKEN,
  POLP_JSON_WEB_TOKEN_EXPIRED,
  POLP_JSON_WEB_TOKEN_INVALID,
} from '../../../config/constants';
import { useJWTokenFailedFeedback } from '../../../hooks/useJWTokenFailedFeedback';
import { getTranslation } from '../../../locale/texts';
import { put } from '../../../services/requestService';
import { validatePassword, validatePasswordLength } from '../../../services/validationService';
import { StoreContext } from '../../../store/store';
import Button from '../../atoms/Button';
import CloseButton from '../../atoms/CloseButton';
import InputPassword from '../../atoms/InputPassword';

function ChangePasswordView() {
  const navigate = useNavigate();
  const [state, dispatch] = useContext(StoreContext);
  const { stoppedForJWToken } = useJWTokenFailedFeedback();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');
  const [isNewPasswordValid, setIsPasswordValid] = useState(false);
  const [isNewPasswordLengthValid, setIsPasswordLengthValid] = useState(false);
  const [isRepeatPasswordValid, setIsRepeatPasswordValid] = useState(false);

  const [passwordChanged, setPasswordChanged] = useState(false);

  // Al modificar la password se comprueba si es válida
  useEffect(() => {
    setIsPasswordValid(validatePassword(newPassword));
    setIsPasswordLengthValid(validatePasswordLength(newPassword));
    setIsRepeatPasswordValid(newPassword === repeatNewPassword);
  }, [newPassword, repeatNewPassword]);

  const postNewPassword = async () => {
    if (!isNewPasswordValid) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_invalid_password_title'),
          text: getTranslation('error_invalid_password_text'),
        },
      });

      return;
    }

    if (!isRepeatPasswordValid) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_invalid_repeat_password_title'),
          text: getTranslation('error_invalid_repeat_password_text'),
        },
      });

      return;
    }

    const polpJWT = localStorage.getItem(POLP_JSON_WEB_TOKEN);
    if (!polpJWT) {
      stoppedForJWToken();
      return false;
    }

    const postNewPasswordResult = await put(
      POLP_API_URL + 'change-password',
      { current: currentPassword, incoming: newPassword },
      { Authorization: polpJWT }
    );

    if (postNewPasswordResult.networkError === true) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text: getTranslation('error_network'),
        },
      });

      return;
    }

    const errorName = postNewPasswordResult.name;
    if (errorName && (errorName === POLP_JSON_WEB_TOKEN_EXPIRED || errorName === POLP_JSON_WEB_TOKEN_INVALID)) {
      stoppedForJWToken();
      return;
    }

    if (postNewPasswordResult.status !== 'success') {
      console.log('postNewPasswordResult error', postNewPasswordResult);

      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text: getTranslation('change_password_error_changing_password'),
        },
      });

      return;
    }

    setPasswordChanged(true);
  };

  const closeChangePasswordView = () => {
    navigate('/profile');
  };

  return (
    <div className='change-password-view'>
      <div className='change-password-view__logo'>
        <img src={PolpLogo} alt='POLP' />
      </div>
      <div className='change-password-view__close-btn'>
        <CloseButton
          onClick={(e) => {
            e.preventDefault();
            closeChangePasswordView();
          }}
        />
      </div>
      {passwordChanged === false && (
        <div className='change-password-view__content'>
          <div className='change-password-view__title'>{getTranslation('change_password_title')}</div>

          <div className='change-password-view__subtitle'>
            {getTranslation('change_password_current_password_text')}
          </div>

          <div className='change-password-view__form'>
            <div className='change-password-view__password-input'>
              <InputPassword
                value={currentPassword}
                onChange={setCurrentPassword}
                label={getTranslation('change_password_current_password_placeholder')}
              />
            </div>
          </div>

          <div className='change-password-view__subtitle'>{getTranslation('change_password_new_password_text')}</div>

          <div className='change-password-view__form'>
            <div className='change-password-view__password-input'>
              <InputPassword
                value={newPassword}
                onChange={setNewPassword}
                label={getTranslation('change_password_new_password_placeholder')}
              />
            </div>

            <div className='change-password-view__password-input'>
              <InputPassword
                value={repeatNewPassword}
                onChange={setRepeatNewPassword}
                label={getTranslation('change_password_repeat_new_password_placeholder')}
              />
            </div>

            <button
              className={
                'change-password-view__button-change-password' +
                (isNewPasswordLengthValid && isRepeatPasswordValid
                  ? ' change-password-view__button-change-password-enabled'
                  : ' change-password-view__button-change-password-disabled')
              }
              onClick={postNewPassword}
            >
              <img src={ArrowIcon} alt='' />
            </button>
          </div>
        </div>
      )}
      {passwordChanged === true && (
        <div className='change-password-view__content'>
          <div className='change-password-view__check'>
            <img className='change-password-view__check-img' src={Check} alt='' />
          </div>
          <div className='change-password-view__title'>{getTranslation('change_password_success_text')}</div>
          <div className='change-password-view__actions'>
            <Button
              text={getTranslation('change_password_success_button')}
              size='big'
              color='darkgray'
              onClick={closeChangePasswordView}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ChangePasswordView;
