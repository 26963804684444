import React, { useEffect, useRef, useState } from 'react';

function FadeInSection({ children, fadeFrom }) {
  const [isVisible, setVisible] = useState(false);
  const domRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });

    observer.observe(domRef.current);

    return () => observer.disconnect();
  }, []);

  return (
    <div className={`fade-in-section ${isVisible ? 'is-visible' : ''} fade-in-section--${fadeFrom}`} ref={domRef}>
      {children}
    </div>
  );
}

export default FadeInSection;
