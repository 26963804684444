import React from 'react';

export function Logo1() {
  return (
    <svg width='304' height='107' viewBox='0 40 304 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M47.3208 20.7416C47.3898 23.3653 46.9311 25.9761 45.9718 28.4193C45.0124 30.8625 43.572 33.0884 41.7359 34.965C37.6503 39.2386 32.2113 41.3704 25.4189 41.3603H17.0264C16.6941 41.3603 16.3755 41.4922 16.1405 41.727C15.9056 41.9618 15.7736 42.2802 15.7736 42.6122V105.75C15.7736 106.082 15.6416 106.401 15.4066 106.636C15.1717 106.87 14.853 107.002 14.5208 107.002H1.25283C0.920559 107.002 0.601897 106.87 0.366946 106.636C0.131994 106.401 0 106.082 0 105.75L0 1.35976C0 1.02774 0.131994 0.709309 0.366946 0.474532C0.601897 0.239754 0.920559 0.107858 1.25283 0.107858H25.4038C28.4224 -0.0278527 31.4359 0.471267 34.2493 1.57292C37.0626 2.67457 39.6132 4.3542 41.7359 6.50312C43.5739 8.38156 45.0154 10.6099 45.9748 13.0559C46.9341 15.5018 47.3919 18.1155 47.3208 20.7416ZM31.5472 20.7416C31.5493 19.9 31.3835 19.0663 31.0595 18.2894C30.7356 17.5125 30.2599 16.8078 29.6604 16.2167C29.0103 15.5704 28.2337 15.0654 27.3791 14.7331C26.5246 14.4009 25.6105 14.2487 24.6943 14.286H17.0264C16.6941 14.286 16.3755 14.4179 16.1405 14.6527C15.9056 14.8875 15.7736 15.2059 15.7736 15.5379V26.0056C15.7736 26.3377 15.9056 26.6561 16.1405 26.8909C16.3755 27.1257 16.6941 27.2576 17.0264 27.2576H24.6943C25.612 27.2957 26.5274 27.1424 27.3825 26.8074C28.2375 26.4724 29.0133 25.963 29.6604 25.3118C30.2668 24.7164 30.7465 24.0046 31.0708 23.2192C31.395 22.4339 31.5571 21.5911 31.5472 20.7416Z'
        fill='#1D1D1B'
      />
      <path
        d='M116.815 45.6269C116.165 49.9324 114.18 53.9258 111.139 57.0449C108.838 59.367 106.085 61.1938 103.05 62.413C100.016 63.6321 96.7635 64.2178 93.4939 64.134C90.2244 64.2169 86.9725 63.6308 83.9381 62.4117C80.9036 61.1926 78.1507 59.3663 75.8486 57.0449C72.8129 53.9244 70.8332 49.9309 70.1883 45.6269C69.7042 36.5835 69.7042 27.5207 70.1883 18.4773C70.8392 14.184 72.8185 10.2019 75.8486 7.08946C78.1507 4.76811 80.9036 2.94175 83.9381 1.72264C86.9725 0.503533 90.2244 -0.0825647 93.4939 0.000373883C96.7635 -0.0834568 100.016 0.502232 103.05 1.72139C106.085 2.94055 108.838 4.76739 111.139 7.08946C114.18 10.2086 116.165 14.2019 116.815 18.5074C117.283 27.5412 117.283 36.5931 116.815 45.6269ZM102.007 31.675C102.007 23.6055 101.207 19.6085 99.6977 17.6929C98.9125 16.8282 97.9548 16.1373 96.8864 15.6644C95.818 15.1916 94.6624 14.9473 93.4939 14.9473C92.3254 14.9473 91.1698 15.1916 90.1014 15.6644C89.033 16.1373 88.0754 16.8282 87.2902 17.6929C85.7807 19.6688 85.0109 23.6055 84.9958 31.675V32.4292C84.9958 40.4735 85.7354 45.4661 87.2147 47.4068C87.9869 48.3237 88.9503 49.0609 90.0376 49.5666C91.1249 50.0723 92.3097 50.3343 93.509 50.3343C94.7083 50.3343 95.8931 50.0723 96.9804 49.5666C98.0677 49.0609 99.0312 48.3237 99.8034 47.4068C101.313 45.5214 101.977 40.2724 102.022 32.4292L102.007 31.675Z'
        fill='#1D1D1B'
      />
      <path
        d='M230.612 64.2395H147.699C147.356 64.2356 147.028 64.0977 146.785 63.8553C146.542 63.6129 146.404 63.2852 146.401 62.9424V1.38797C146.401 1.04794 146.536 0.72184 146.776 0.481405C147.017 0.240971 147.343 0.105896 147.684 0.105896H160.861C161.209 0.105896 161.543 0.244149 161.789 0.490241C162.036 0.736333 162.174 1.07011 162.174 1.41813V48.8849C162.174 49.2303 162.31 49.5618 162.553 49.8075C162.796 50.0531 163.127 50.1931 163.472 50.1971H230.612C230.956 50.1971 231.286 50.3338 231.53 50.577C231.773 50.8203 231.91 51.1502 231.91 51.4943V62.9424C231.906 63.2852 231.768 63.6129 231.525 63.8553C231.283 64.0977 230.955 64.2356 230.612 64.2395Z'
        fill='#1D1D1B'
      />
      <path
        d='M304.001 20.7394C304.068 23.3641 303.607 25.9756 302.645 28.4188C301.683 30.8621 300.239 33.0875 298.401 34.9629C294.325 39.2314 288.876 41.3581 282.099 41.3581H273.721C273.553 41.3581 273.387 41.3914 273.233 41.456C273.078 41.5206 272.937 41.6153 272.819 41.7345C272.701 41.8538 272.608 41.9952 272.545 42.1507C272.483 42.3062 272.451 42.4725 272.453 42.6402V62.9723C272.449 63.3097 272.312 63.6319 272.072 63.8691C271.832 64.1063 271.508 64.2393 271.17 64.2393H257.948C257.611 64.2393 257.289 64.1058 257.051 63.8682C256.813 63.6306 256.68 63.3083 256.68 62.9723V1.37266C256.68 1.03663 256.813 0.714369 257.051 0.476763C257.289 0.239157 257.611 0.105671 257.948 0.105671H282.099C285.112 -0.0276527 288.121 0.472685 290.929 1.57432C293.737 2.67595 296.282 4.35435 298.401 6.50093C300.238 8.38064 301.68 10.6089 302.642 13.0543C303.603 15.4997 304.065 18.1128 304.001 20.7394ZM288.227 20.7394C288.23 19.8963 288.063 19.0613 287.736 18.2839C287.409 17.5066 286.929 16.8028 286.325 16.2145C285.678 15.5687 284.904 15.0637 284.052 14.7315C283.2 14.3992 282.288 14.2468 281.374 14.2838H273.721C273.554 14.2818 273.388 14.3132 273.234 14.3762C273.079 14.4391 272.938 14.5324 272.82 14.6504C272.702 14.7685 272.609 14.909 272.546 15.0636C272.483 15.2182 272.451 15.3839 272.453 15.5508V25.9884C272.453 26.3244 272.587 26.6467 272.825 26.8843C273.062 27.1219 273.385 27.2554 273.721 27.2554H281.374C282.289 27.2932 283.203 27.1397 284.055 26.8047C284.908 26.4696 285.681 25.9604 286.325 25.3096C286.95 24.7236 287.448 24.0156 287.788 23.2295C288.128 22.4433 288.303 21.5959 288.302 20.7394H288.227Z'
        fill='#1D1D1B'
      />
    </svg>
  );
}

export function Logo2() {
  return (
    <svg width='304' height='125' viewBox='0 40 304 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M54.8497 48.8572C54.9321 51.9034 54.4016 54.9352 53.2895 57.7724C52.1775 60.6096 50.5066 63.1944 48.3762 65.3733C45.9178 67.8637 42.9643 69.8108 39.7065 71.0886C36.4487 72.3664 32.9591 72.9465 29.4631 72.7916H19.7354C19.3503 72.7916 18.9809 72.9446 18.7086 73.2169C18.4362 73.4892 18.2832 73.8586 18.2832 74.2437V97.8807C18.2786 98.2628 18.1236 98.6277 17.8518 98.8963C17.5799 99.1648 17.2132 99.3154 16.8311 99.3154H1.45216C1.07004 99.3154 0.7033 99.1648 0.431465 98.8963C0.159629 98.6277 0.00460391 98.2628 0 97.8807L0 26.3749C0.00460391 25.9928 0.159629 25.6279 0.431465 25.3594C0.7033 25.0908 1.07004 24.9402 1.45216 24.9402H29.4456C37.3188 24.9402 43.6232 27.413 48.3587 32.3585C50.4901 34.5339 52.163 37.1152 53.278 39.9493C54.393 42.7834 54.9275 45.8126 54.8497 48.8572ZM36.5665 48.8572C36.5709 47.8806 36.3796 46.913 36.004 46.0115C35.6284 45.1101 35.076 44.2929 34.3795 43.6084C33.6294 42.853 32.7302 42.2622 31.7391 41.8736C30.7481 41.485 29.6869 41.3072 28.6233 41.3514H19.7354C19.3503 41.3514 18.9809 41.5044 18.7086 41.7767C18.4362 42.0491 18.2832 42.4184 18.2832 42.8036V54.9457C18.2832 55.3309 18.4362 55.7002 18.7086 55.9726C18.9809 56.2449 19.3503 56.3979 19.7354 56.3979H28.6233C29.6853 56.4412 30.7447 56.2646 31.7353 55.8793C32.7258 55.4939 33.626 54.908 34.3795 54.1584C35.0808 53.4665 35.6358 52.6407 36.0115 51.7299C36.3872 50.8192 36.5759 49.8423 36.5665 48.8572Z'
        fill='#1D1D1B'
      />
      <path
        d='M135.401 77.7261C134.654 82.7276 132.353 87.3673 128.822 90.988C126.157 93.6849 122.967 95.8061 119.449 97.2206C115.931 98.635 112.16 99.3125 108.37 99.2111C104.579 99.3114 100.809 98.6335 97.2911 97.2191C93.7732 95.8047 90.5828 93.684 87.9169 90.988C84.3953 87.3703 82.1001 82.7371 81.3559 77.7436C80.7961 74.7343 80.7961 24.5209 81.3559 21.4941C82.1 16.4955 84.3949 11.8566 87.9169 8.23223C90.5853 5.53955 93.7762 3.42103 97.2934 2.00691C100.811 0.592789 104.58 -0.0870639 108.37 0.00914192C112.159 -0.0880986 115.929 0.591279 119.446 2.00546C122.964 3.41964 126.155 5.53871 128.822 8.23223C132.347 11.8503 134.647 16.4824 135.401 21.4766C135.943 24.5034 135.943 74.7168 135.401 77.7261ZM118.237 36.7506C118.237 27.3903 117.31 21.5291 115.56 19.2547C114.65 18.2517 113.54 17.4502 112.302 16.9017C111.064 16.3532 109.724 16.0698 108.37 16.0698C107.015 16.0698 105.676 16.3532 104.437 16.9017C103.199 17.4502 102.089 18.2517 101.179 19.2547C99.4292 21.5466 98.5369 27.4078 98.5194 36.7506V62.3996C98.5194 71.7774 99.4117 77.5511 101.091 79.7731C101.986 80.8367 103.103 81.6918 104.363 82.2784C105.624 82.865 106.997 83.1689 108.387 83.1689C109.777 83.1689 111.151 82.865 112.411 82.2784C113.671 81.6918 114.788 80.8367 115.683 79.7731C117.433 77.5861 118.202 71.4975 118.255 62.3996L118.237 36.7506Z'
        fill='#1D1D1B'
      />
      <path
        d='M217.702 99.3156H171.198C170.802 99.3109 170.423 99.1504 170.145 98.8687C169.867 98.587 169.71 98.2069 169.71 97.8109V26.4275C169.71 26.0331 169.867 25.6548 170.146 25.3759C170.425 25.097 170.803 24.9403 171.198 24.9403H186.472C186.671 24.938 186.868 24.9752 187.053 25.0498C187.237 25.1244 187.405 25.2349 187.547 25.3748C187.688 25.5148 187.801 25.6814 187.877 25.8651C187.954 26.0488 187.994 26.2459 187.994 26.445V81.5047C187.994 81.9054 188.152 82.2899 188.433 82.5749C188.715 82.8598 189.098 83.0222 189.498 83.0268H217.719C218.118 83.0268 218.501 83.1854 218.783 83.4675C219.065 83.7497 219.224 84.1324 219.224 84.5315V97.8109C219.226 98.0106 219.188 98.2088 219.113 98.3936C219.037 98.5784 218.924 98.746 218.782 98.8864C218.64 99.0269 218.471 99.1373 218.286 99.211C218.1 99.2847 217.901 99.3203 217.702 99.3156Z'
        fill='#1D1D1B'
      />
      <path
        d='M303.992 48.858C304.074 51.9043 303.544 54.936 302.432 57.7732C301.319 60.6105 299.649 63.1953 297.518 65.3742C295.06 67.8646 292.106 69.8116 288.849 71.0894C285.591 72.3672 282.101 72.9474 278.605 72.7925H268.895C268.505 72.7925 268.131 72.9473 267.856 73.2229C267.58 73.4985 267.425 73.8723 267.425 74.2621V122.656C267.421 123.044 267.264 123.415 266.99 123.69C266.715 123.965 266.344 124.121 265.956 124.126H250.612C250.222 124.126 249.848 123.971 249.572 123.695C249.297 123.419 249.142 123.046 249.142 122.656V26.4283C249.14 26.2346 249.176 26.0424 249.249 25.8631C249.322 25.6837 249.43 25.5208 249.567 25.3838C249.704 25.2469 249.867 25.1387 250.046 25.0657C250.226 24.9927 250.418 24.9563 250.612 24.9586H278.605C286.478 24.9586 292.783 27.4314 297.518 32.3769C299.644 34.5517 301.313 37.1308 302.425 39.9616C303.537 42.7925 304.069 45.8176 303.992 48.858ZM285.708 48.858C285.713 47.8814 285.522 46.9139 285.146 46.0124C284.77 45.1109 284.218 44.2938 283.521 43.6093C282.77 42.8556 281.871 42.266 280.88 41.8775C279.889 41.489 278.829 41.3102 277.765 41.3523H268.895C268.507 41.3568 268.135 41.5131 267.861 41.7878C267.586 42.0624 267.43 42.4336 267.425 42.8219V54.9291C267.43 55.3175 267.586 55.6887 267.861 55.9633C268.135 56.2379 268.507 56.3942 268.895 56.3988H277.765C278.827 56.44 279.886 56.2624 280.876 55.8772C281.866 55.4919 282.767 54.9072 283.521 54.1593C284.223 53.4674 284.778 52.6415 285.153 51.7308C285.529 50.8201 285.718 49.8432 285.708 48.858Z'
        fill='#1D1D1B'
      />
    </svg>
  );
}

export function Logo3() {
  return (
    <svg width='304' height='117' viewBox='0 40 304 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M42.8757 18.7094C42.9382 21.0883 42.5226 23.4556 41.6534 25.671C40.7842 27.8863 39.479 29.9046 37.8154 31.6063C34.1136 35.4813 29.1855 37.4142 23.0311 37.4051H15.427C15.126 37.4051 14.8372 37.5247 14.6244 37.7376C14.4115 37.9504 14.2919 38.2392 14.2919 38.5402V57.0171C14.2919 57.3182 14.1723 57.6069 13.9594 57.8198C13.7465 58.0326 13.4578 58.1522 13.1567 58.1522H1.13514C0.834086 58.1522 0.545357 58.0326 0.332476 57.8198C0.119595 57.6069 0 57.3182 0 57.0171L0 1.13514C0 0.834086 0.119595 0.545357 0.332476 0.332476C0.545357 0.119595 0.834086 0 1.13514 0L23.0175 0C29.1719 0 34.0999 1.93294 37.8017 5.79881C39.4695 7.50093 40.7781 9.52095 41.6498 11.7388C42.5214 13.9567 42.9383 16.3272 42.8757 18.7094ZM28.5838 18.7094C28.5857 17.9462 28.4355 17.1903 28.1419 16.4859C27.8484 15.7814 27.4174 15.1425 26.8742 14.6064C26.2852 14.0205 25.5815 13.5625 24.8073 13.2613C24.033 12.96 23.2048 12.822 22.3747 12.8559H15.427C15.126 12.8559 14.8372 12.9755 14.6244 13.1883C14.4115 13.4012 14.2919 13.6899 14.2919 13.991V23.4825C14.2919 23.7835 14.4115 24.0722 14.6244 24.2851C14.8372 24.498 15.126 24.6176 15.427 24.6176H22.3747C23.2061 24.6522 24.0356 24.5132 24.8103 24.2094C25.585 23.9056 26.2879 23.4438 26.8742 22.8533C27.4237 22.3134 27.8583 21.668 28.1521 20.9559C28.4459 20.2438 28.5927 19.4797 28.5838 18.7094Z'
        fill='#1D1D1B'
      />
      <path
        d='M105.842 99.5235C105.253 103.427 103.454 107.048 100.7 109.877C98.6095 111.972 96.1132 113.619 93.3639 114.715C90.6146 115.811 87.6703 116.333 84.7117 116.25C81.7494 116.325 78.803 115.794 76.0536 114.688C73.3041 113.583 70.8098 111.927 68.724 109.822C65.9734 106.992 64.1797 103.371 63.5953 99.4688C63.1577 97.1028 63.1577 19.0924 63.5953 16.7264C64.1906 12.8432 65.9836 9.24285 68.724 6.42799C70.8098 4.32314 73.3041 2.66712 76.0536 1.56171C78.803 0.456299 81.7494 -0.0751373 84.7117 6.60932e-05C87.6742 -0.0759461 90.6209 0.455119 93.3704 1.56057C96.1199 2.66603 98.6141 4.32249 100.7 6.42799C103.454 9.25621 105.253 12.8771 105.842 16.7811C106.266 19.1471 106.266 97.1028 105.842 99.5235ZM92.4253 28.7206C92.4253 21.4037 91.7004 16.8221 90.3328 15.0442C89.6213 14.2601 88.7536 13.6336 87.7856 13.2048C86.8175 12.7761 85.7705 12.5546 84.7117 12.5546C83.653 12.5546 82.606 12.7761 81.6379 13.2048C80.6699 13.6336 79.8022 14.2601 79.0907 15.0442C77.7231 16.8358 77.0256 21.4174 77.0119 28.7206V87.5293C77.0119 94.8462 77.7094 99.3594 79.0223 101.11C79.722 101.941 80.595 102.61 81.5801 103.068C82.5653 103.527 83.6388 103.764 84.7254 103.764C85.8121 103.764 86.8856 103.527 87.8707 103.068C88.8559 102.61 89.7289 101.941 90.4285 101.11C91.7962 99.4004 92.3979 94.641 92.4389 87.5293L92.4253 28.7206Z'
        fill='#1D1D1B'
      />
      <path
        d='M170.176 58.1522H133.824C133.513 58.1487 133.216 58.0236 132.996 57.8038C132.777 57.584 132.652 57.2869 132.648 56.9761V1.1625C132.648 0.854184 132.77 0.558498 132.988 0.340488C133.206 0.122477 133.502 0 133.81 0L145.75 0C145.906 0 146.061 0.0307764 146.205 0.090572C146.35 0.150368 146.481 0.238011 146.591 0.348499C146.702 0.458987 146.789 0.590155 146.849 0.734514C146.909 0.878874 146.94 1.0336 146.94 1.18985V44.2296C146.94 44.5416 147.064 44.8407 147.284 45.0613C147.505 45.2819 147.804 45.4058 148.116 45.4058H170.176C170.332 45.4058 170.486 45.4367 170.629 45.4966C170.773 45.5566 170.903 45.6444 171.013 45.7551C171.122 45.8658 171.208 45.9971 171.267 46.1413C171.325 46.2856 171.354 46.44 171.352 46.5956V56.9761C171.352 57.288 171.228 57.5872 171.008 57.8077C170.787 58.0283 170.488 58.1522 170.176 58.1522Z'
        fill='#1D1D1B'
      />
      <path
        d='M295.78 18.7094C295.843 21.0883 295.427 23.4556 294.558 25.671C293.689 27.8863 292.384 29.9046 290.72 31.6063C287.018 35.4813 282.09 37.4142 275.936 37.4051H210.193C209.89 37.4086 209.599 37.5308 209.385 37.7455C209.17 37.9602 209.048 38.2503 209.044 38.5539V57.0034C209.041 57.307 208.919 57.5972 208.704 57.8118C208.489 58.0265 208.199 58.1487 207.896 58.1522H195.901C195.597 58.1522 195.304 58.0312 195.089 57.8158C194.873 57.6003 194.752 57.3081 194.752 57.0034V1.14882C194.752 0.844135 194.873 0.551928 195.089 0.336482C195.304 0.121036 195.597 0 195.901 0L275.936 0C282.09 0 287.018 1.93294 290.72 5.79881C292.385 7.50206 293.692 9.52259 294.561 11.7404C295.43 13.9582 295.845 16.3281 295.78 18.7094ZM281.489 18.7094C281.491 17.9449 281.34 17.1877 281.044 16.4829C280.748 15.7781 280.313 15.1399 279.765 14.6064C279.179 14.0209 278.478 13.563 277.706 13.2617C276.934 12.9604 276.107 12.8223 275.279 12.8559H210.193C209.888 12.8559 209.596 12.9769 209.381 13.1923C209.165 13.4078 209.044 13.7 209.044 14.0047V23.4551C209.044 23.7611 209.165 24.0547 209.38 24.2723C209.595 24.4899 209.887 24.614 210.193 24.6176H275.279C276.109 24.6519 276.936 24.5128 277.709 24.209C278.481 23.9052 279.182 23.4434 279.765 22.8533C280.319 22.316 280.758 21.6714 281.054 20.9589C281.35 20.2464 281.498 19.481 281.489 18.7094Z'
        fill='#1D1D1B'
      />
    </svg>
  );
}
