const initialState = { provider: '', token: '', email: '' };

export default {
  key: 'social',

  initialState,

  reducer: (state, action) => {
    switch (action.type) {
      case 'SOCIAL_SET_VALUES':
        return { ...state, ...action.payload };

      case 'SOCIAL_CLEAR_VALUES':
        return initialState;

      default:
        return state;
    }
  },
};
