import React from 'react';

function Accordion({ title, htmlContent }) {
  return (
    <div className='accordion'>
      <details>
        <summary>{title}</summary>
        <span dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </details>
    </div>
  );
}

Accordion.defaultProps = {
  title: 'This is an accordion',
  htmlContent:
    "<p>I walked through the door with you, the air was cold, but something about it felt like home somehow and I, left my scarf there at your sister's house and you've still got it in your drawer even now. And you call me up again just to break me like a promise. So casually cruel in the name of being honest.</p>",
};

export default Accordion;
