import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Check from '../../../assets/icons/check-big.svg';
import PolpLogo from '../../../assets/polp_logo_popup.svg';
import {
  POLP_API_URL,
  POLP_JSON_WEB_TOKEN,
  POLP_JSON_WEB_TOKEN_EXPIRED,
  POLP_JSON_WEB_TOKEN_INVALID,
} from '../../../config/constants';
import { useJWTokenFailedFeedback } from '../../../hooks/useJWTokenFailedFeedback';
import { getTranslation } from '../../../locale/texts';
import { post } from '../../../services/requestService';
import { StoreContext } from '../../../store/store';
import Button from '../../atoms/Button';
import CloseButton from '../../atoms/CloseButton';
import InputLicense from '../../atoms/InputLicense';

function AddLicensePopup({ onClickClose, setShowLicensePopup, resourcesFromCard }) {
  const navigate = useNavigate();
  const [state, dispatch] = useContext(StoreContext);
  const { stoppedForJWToken } = useJWTokenFailedFeedback();
  const [currentStep, setCurrentStep] = useState('step1');
  const [licenseCode, setLicenseCode] = useState('');

  useEffect(() => {
    dispatch({ type: 'MESSAGE_HIDE_DRAWER' });
  }, [licenseCode]);

  const redeemLicense = async () => {
    if (licenseCode === '') {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_data_title'),
          text: getTranslation('home_license_missing_code'),
        },
      });

      return false;
    }

    const polpJWT = localStorage.getItem(POLP_JSON_WEB_TOKEN);
    if (!polpJWT) {
      stoppedForJWToken();
      return false;
    }

    const redeemLicenseResult = await post(POLP_API_URL + 'redeem', { code: licenseCode }, { Authorization: polpJWT });

    if (redeemLicenseResult.networkError === true) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text: getTranslation('error_network'),
        },
      });

      return false;
    }

    const errorName = redeemLicenseResult.name;
    if (errorName && (errorName === POLP_JSON_WEB_TOKEN_EXPIRED || errorName === POLP_JSON_WEB_TOKEN_INVALID)) {
      stoppedForJWToken();
      return false;
    }

    if (redeemLicenseResult.status !== 'success') {
      console.log('redeemLicenseResult error', redeemLicenseResult);

      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_data_title'),
          text: getTranslation('home_license_invalid_code'),
        },
      });

      return false;
    }

    return true;
  };

  const handleActivateButton = async () => {
    const isRedeemed = await redeemLicense();
    if (isRedeemed) setCurrentStep('step2');
  };

  const handleNewLicenseButton = () => {
    setCurrentStep('step1');
  };

  const handleEnterButton = () => {
    onClickClose();
  };

  return (
    <div className='add-license-popup'>
      <div className='add-license-popup__logo'>
        <img src={PolpLogo} alt='POLP' />
      </div>
      <div className='add-license-popup__close-btn'>
        <CloseButton onClick={onClickClose} />
      </div>
      {currentStep === 'step1' && (
        <div className='add-license-popup__content'>
          <div className='add-license-popup__title'>{getTranslation('home_license_title')}</div>
          <div className='info-popup__subtitle'>
            {getTranslation(resourcesFromCard ? 'home_license_subtitle_card' : 'home_license_subtitle')}
          </div>
          <div className='info-popup__actions'>
            <InputLicense
              placeholder={getTranslation('home_license_placeholder')}
              onChange={setLicenseCode}
              onClick={handleActivateButton}
            />
          </div>
        </div>
      )}
      {currentStep === 'step2' && (
        <div className='add-license-popup__content'>
          <div className='add-license-popup__check'>
            <img className='add-license-popup__check-img' src={Check} alt='' />
          </div>
          <div className='add-license-popup__title'>{getTranslation('home_license_activated')}</div>
          <div className='info-popup__actions'>
            <Button
              text={getTranslation('home_license_enter')}
              size='big'
              color='darkgray'
              onClick={handleEnterButton}
            />
            <Button
              text={getTranslation('home_license_active_other')}
              size='big'
              color='transparent-black'
              onClick={handleNewLicenseButton}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default AddLicensePopup;
