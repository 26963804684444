import React from 'react';
import HelpView from '../../modules/views/HelpView/HelpView';

function HelpPage({ logout }) {
  return (
    <div className='help-page'>
      <HelpView logout={logout} />
    </div>
  );
}

export default HelpPage;
