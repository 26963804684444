import React from 'react';
import EmailSentView from '../../modules/views/EmailSentView/EmailSentView';

function EmailSentPage() {
  return (
    <div className='email-sent-page'>
      <EmailSentView />
    </div>
  );
}

export default EmailSentPage;
