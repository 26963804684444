import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import {
  GOOGLE_PROVIDER,
  IS_POLP_MEXICO,
  MICROSOFT_PROVIDER,
  POLP_API_URL,
  POLP_JSON_WEB_TOKEN,
  POLP_JSON_WEB_TOKEN_EXPIRED,
  POLP_JSON_WEB_TOKEN_INVALID,
} from '../../../config/constants';
import { useJWTokenFailedFeedback } from '../../../hooks/useJWTokenFailedFeedback';
import { useScrollLock } from '../../../hooks/useScrollLock';
import { getTranslation } from '../../../locale/texts';
import SectionLayout from '../../../modules/layout/SectionLayout';
import { getAppIcon } from '../../../services/appsIcons';
import { conectGoogle, loginMicrosoft } from '../../../services/authService';
import { getUserData } from '../../../services/loginService';
import { delete_, get, patch, post, put } from '../../../services/requestService';
import { getGrades, getSchoolText, getSchools, getSubjects } from '../../../services/rosterDataService';
import { validateEmail } from '../../../services/validationService';
import { StoreContext } from '../../../store/store';
import Button from '../../atoms/Button';
import InputText from '../../atoms/InputText';
import SocialButton from '../../atoms/SocialButton';
import Footer from '../../components/Footer';
import SectionHeader from '../../components/SectionHeader';
import TwoLevelsSelector from '../../components/TwoLevelsSelector';

function ProfileView({ logout }) {
  const [state, dispatch] = useContext(StoreContext);
  const { lockScroll, unlockScroll } = useScrollLock();
  const navigate = useNavigate();
  const { stoppedForJWToken } = useJWTokenFailedFeedback();

  const [email, setEmail] = useState(''); // Se usa con user.username
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [selectedSchool, setSelectedSchool] = useState({});
  const [gradesOptions, setGradesOptions] = useState([]);
  const [subjectsOptions, setSubjectsOptions] = useState([]);
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [isProfileUnchanged, setIsProfileUnchanged] = useState(true);
  const [hasMissingFields, setHasMissingFields] = useState(true);

  const [googleEmail, setGoogleEmail] = useState('');
  const [microsoftEmail, setMicrosoftEmail] = useState('');

  const [userLicenses, setUserLicenses] = useState([]);

  const [canCheckChanges, setCanCheckChanges] = useState(false);

  const nameRef = useRef(null);
  const lastnameRef = useRef(null);
  const schoolRef = useRef(null);
  const gradesRef = useRef(null);

  // Inicialización
  useEffect(() => {
    conectGoogle(document.querySelector('#social-login-google'), googleHandler, googleHandler);

    setEmail(state.user.username);
    setName(state.user.name);
    setLastname(state.user.lastname);

    // Iniciar selector de colegio con el que tenga el usuario
    setSelectedSchool(state.user.roster.school);

    // Obtener cursos y asignaturas (los disponibles para elegir)
    getGradesFromService();
    getSubjectsFromService();

    // Iniciar selector de cursos con los que tenga el usuario
    const userGrades = state.user.roster.grades;
    const parsedUserGrades = [];
    for (let g = 0, gMax = userGrades.length; g < gMax; g++) {
      parsedUserGrades.push({ value: userGrades[g].id, label: userGrades[g].name });
    }
    setSelectedGrades(parsedUserGrades);

    // Iniciar selector de asignaturas con las que tenga el usuario
    const userSubjects = state.user.roster.subjects;
    const parsedUserSubjects = [];
    for (let s = 0, sMax = userSubjects.length; s < sMax; s++) {
      parsedUserSubjects.push({ value: userSubjects[s].id, label: userSubjects[s].name });
    }
    setSelectedSubjects(parsedUserSubjects);

    // Establecer vinculaciones con Google y Microsoft
    const linkedAccounts = state.user.oauth_accounts?.length ?? 0;
    if (linkedAccounts > 0) {
      for (let a = 0; a < linkedAccounts; a++) {
        const account = state.user.oauth_accounts[a];

        switch (account.provider) {
          case GOOGLE_PROVIDER: {
            setGoogleEmail(account.email);
            break;
          }
          case MICROSOFT_PROVIDER: {
            setMicrosoftEmail(account.email);
            break;
          }
        }
      }
    }

    getUserLicenses();

    setHasMissingFields(checkIfMissingFields());
    setCanCheckChanges(true);
  }, []);

  useEffect(() => {
    if (canCheckChanges) {
      setHasMissingFields(checkIfMissingFields());
      setIsProfileUnchanged(!checkIfProfileChanged());
    }
  }, [state.user, email, name, lastname, selectedSchool, selectedGrades, selectedSubjects]);

  const showErrorNetwork = () => {
    dispatch({
      type: 'MESSAGE_SHOW_DRAWER',
      payload: {
        type: 'alert',
        title: getTranslation('error_generic_title'),
        text: getTranslation('error_network'),
      },
    });
  };

  const checkIfMissingFields = () => {
    if (email.trim() === '') return true;
    if (name.trim().length < 2) return true;
    if (lastname.trim().length < 2) return true;
    if (selectedSchool.length === 0) return true;
    if (selectedGrades.length === 0) return true;
    if (selectedSubjects.length === 0) return true;

    return false;
  };

  const isMainAccoutFromGoogle = () => state.user.username === googleEmail;
  const isMainAccoutFromMicrosoft = () => state.user.username === microsoftEmail;

  // Comprobar si hay cambios en los cursos
  const hasGradesChanged = () => {
    if (state.user.roster.grades.length !== selectedGrades.length) return true;

    for (let g = 0, gMax = selectedGrades.length; g < gMax; g++) {
      const id = selectedGrades[g].value;
      const isGradeschanged = !state.user.roster.grades.some((item) => item.id === id);
      if (isGradeschanged) return true;
    }

    return false;
  };

  // Comprobar si hay cambios en las asignaturas
  const hasSubjectsChanged = () => {
    if (state.user.roster.subjects.length !== selectedSubjects.length) return true;

    for (let s = 0, sMax = selectedSubjects.length; s < sMax; s++) {
      const id = selectedSubjects[s].value;
      const isSubjectsChanged = !state.user.roster.subjects.some((item) => item.id === id);
      if (isSubjectsChanged) return true;
    }

    return false;
  };

  const checkIfProfileChanged = () => {
    const profile = state.user;
    if (
      profile.username !== email ||
      profile.name !== name ||
      profile.lastname !== lastname ||
      profile.roster.school.id !== selectedSchool.id
    ) {
      return true;
    }

    if (hasGradesChanged()) return true;
    if (hasSubjectsChanged()) return true;

    return false;
  };

  let schoolSearch = '';

  const schoolSearchChange = (value) => {
    schoolSearch = value;
  };

  const getSchoolsFromService = async (value) => {
    const schoolsResult = await getSchools(value);

    if (schoolsResult.error) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text: getTranslation('profile_error_getting_schools'),
        },
      });

      return [];
    }

    return schoolsResult.schools;
  };

  const schoolSelectedChange = (value) => {
    if (value === null) value = {};
    else gradesRef.current?.focus();
    setSelectedSchool(value);
  };

  const getGradesFromService = async () => {
    const _grades = await getGrades();

    if (_grades.error) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text: getTranslation('profile_error_getting_grades'),
        },
      });

      return;
    }

    setGradesOptions(_grades);
  };

  const getSubjectsFromService = async () => {
    const _subjects = await getSubjects();

    if (_subjects.error) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text: getTranslation('profile_error_getting_subjects'),
        },
      });

      return;
    }

    setSubjectsOptions(_subjects);
  };

  const handlerSubjects = (values, event) => {
    setSelectedSubjects(values);
  };

  const getUserDataFromAPI = async (jwt, errorTitle, errorText) => {
    const userData = await getUserData(jwt);

    if (!userData) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: errorTitle,
          text: errorText,
        },
      });

      return false;
    }

    dispatch({ type: 'USER_SET_VALUES', payload: { ...userData, logged: true } });
    dispatch({
      type: 'MESSAGE_SHOW_DRAWER',
      payload: {
        type: 'success',
        title: getTranslation('profile_updating_success_title'),
        text: getTranslation('profile_updating_success_text'),
      },
    });

    return true;
  };

  const showLoading = () => {
    lockScroll();
    dispatch({ type: 'LOADING_ON' });
  };

  const hideLoading = () => {
    dispatch({ type: 'LOADING_OFF' });
    unlockScroll();
  };

  const saveUpdatedProfile = async () => {
    if (email === '' || !validateEmail(email)) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_data_title'),
          text: getTranslation('error_invalid_email'),
        },
      });

      return;
    }

    showLoading();
    await _saveUpdatedProfile();
    hideLoading();
  };

  const _saveUpdatedProfile = async () => {
    const polpJWT = localStorage.getItem(POLP_JSON_WEB_TOKEN);
    if (!polpJWT) {
      stoppedForJWToken();
      return false;
    }

    const profile = state.user;

    // Datos básisos de perfil
    const updatedUserInformation = {};
    if (profile.username !== email || profile.name !== name || profile.lastname !== lastname) {
      updatedUserInformation.username = email;
      updatedUserInformation.name = name;
      updatedUserInformation.lastname = lastname;
    }

    if (Object.keys(updatedUserInformation).length > 0) {
      const profileUpdateResult = await patch(
        POLP_API_URL + 'profile',
        { ...updatedUserInformation },
        { Authorization: polpJWT }
      );

      if (profileUpdateResult.networkError === true) {
        showErrorNetwork();

        return;
      }

      const errorName = profileUpdateResult.name;
      if (errorName && (errorName === POLP_JSON_WEB_TOKEN_EXPIRED || errorName === POLP_JSON_WEB_TOKEN_INVALID)) {
        stoppedForJWToken();
        return;
      }

      if (profileUpdateResult.status !== 'success') {
        dispatch({
          type: 'MESSAGE_SHOW_DRAWER',
          payload: {
            type: 'alert',
            title: getTranslation('error_generic_title'),
            text: getTranslation('profile_error_updating_basic_information'),
          },
        });

        return;
      }
    }

    // Datos de roster
    if (profile.roster.school.id !== selectedSchool.id || hasGradesChanged() || hasSubjectsChanged()) {
      const updatedUserRoster = {
        school: selectedSchool.id,
        grades: selectedGrades.map((grade) => grade.value),
        subjects: selectedSubjects.map((subject) => subject.value),
      };

      const rosterUdateResult = await put(
        POLP_API_URL + 'roster',
        { ...updatedUserRoster },
        { Authorization: polpJWT }
      );

      if (rosterUdateResult.networkError === true) {
        showErrorNetwork();

        return;
      }

      const errorName = rosterUdateResult.name;
      if (errorName && (errorName === POLP_JSON_WEB_TOKEN_EXPIRED || errorName === POLP_JSON_WEB_TOKEN_INVALID)) {
        stoppedForJWToken();
        return;
      }

      if (rosterUdateResult.status !== 'success') {
        dispatch({
          type: 'MESSAGE_SHOW_DRAWER',
          payload: {
            type: 'alert',
            title: getTranslation('error_generic_title'),
            text: getTranslation('profile_error_updating_roster'),
          },
        });

        return;
      }
    }

    // Solicito datos a la API para asegurar que lo que se muestra al es realmente lo que se tiene almacenado
    await getUserDataFromAPI(
      polpJWT,
      getTranslation('error_generic_title'),
      getTranslation('profile_error_getting_user_data')
    );
  };

  const linkAccount = async (token, provider, _email) => {
    showLoading();
    await _linkAccount(token, provider, _email);
    hideLoading();
  };

  const _linkAccount = async (token, provider, _email) => {
    const polpJWT = localStorage.getItem(POLP_JSON_WEB_TOKEN);
    if (!polpJWT) {
      stoppedForJWToken();
      return false;
    }

    const linkingResult = await post(
      POLP_API_URL + 'oauth-account',
      { token, identity_provider: provider },
      { Authorization: polpJWT }
    );

    if (linkingResult.networkError === true) {
      showErrorNetwork();

      return;
    }

    const errorName = linkingResult.name;
    if (errorName && (errorName === POLP_JSON_WEB_TOKEN_EXPIRED || errorName === POLP_JSON_WEB_TOKEN_INVALID)) {
      stoppedForJWToken();
      return;
    }

    if (linkingResult.status !== 'success') {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text:
            provider === GOOGLE_PROVIDER
              ? getTranslation('profile_error_updating_google')
              : getTranslation('profile_error_updating_microsoft'),
        },
      });

      return;
    }

    switch (provider) {
      case GOOGLE_PROVIDER: {
        setGoogleEmail(_email);
        // Solicito datos a la API para asegurar que lo que se muestra al es realmente lo que se tiene almacenado
        await getUserDataFromAPI(
          polpJWT,
          getTranslation('error_generic_title'),
          getTranslation('profile_error_updating_google')
        );
        break;
      }

      case MICROSOFT_PROVIDER: {
        setMicrosoftEmail(_email);
        // Solicito datos a la API para asegurar que lo que se muestra al es realmente lo que se tiene almacenado
        await getUserDataFromAPI(
          polpJWT,
          getTranslation('error_generic_title'),
          getTranslation('profile_error_updating_microsoft')
        );
        break;
      }
    }
  };

  const unlinkAccount = async (_email, provider) => {
    showLoading();
    await _unlinkAccount(_email, provider);
    hideLoading();
  };

  const _unlinkAccount = async (_email, provider) => {
    const polpJWT = localStorage.getItem(POLP_JSON_WEB_TOKEN);
    if (!polpJWT) {
      stoppedForJWToken();
      return false;
    }

    const unlinkingResult = await delete_(
      POLP_API_URL + 'oauth-account',
      { email: _email, identity_provider: provider },
      { Authorization: polpJWT }
    );

    if (unlinkingResult.networkError === true) {
      showErrorNetwork();

      return;
    }

    const errorName = unlinkingResult.name;
    if (errorName && (errorName === POLP_JSON_WEB_TOKEN_EXPIRED || errorName === POLP_JSON_WEB_TOKEN_INVALID)) {
      stoppedForJWToken();
      return false;
    }

    if (unlinkingResult.status !== 'success') {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text:
            provider === GOOGLE_PROVIDER
              ? getTranslation('profile_error_updating_google')
              : getTranslation('profile_error_updating_microsoft'),
        },
      });

      return;
    }

    switch (provider) {
      case GOOGLE_PROVIDER: {
        setGoogleEmail('');
        // Solicito datos a la API para asegurar que lo que se muestra al es realmente lo que se tiene almacenado
        await getUserDataFromAPI(
          polpJWT,
          getTranslation('error_generic_title'),
          getTranslation('profile_error_updating_google')
        );
        break;
      }

      case MICROSOFT_PROVIDER: {
        setMicrosoftEmail('');
        // Solicito datos a la API para asegurar que lo que se muestra al es realmente lo que se tiene almacenado
        await getUserDataFromAPI(
          polpJWT,
          getTranslation('error_generic_title'),
          getTranslation('profile_error_updating_microsoft')
        );
        break;
      }
    }
  };

  const googleButtonHandler = (event) => {
    event.preventDefault();

    if (googleEmail !== '') {
      // Desvincular
      unlinkAccount(googleEmail, GOOGLE_PROVIDER);
    } else {
      // Vincular:
      // Se dispara click en el botón asociado al api de Google (no visible). Esto llamará a 'googleHandler'
      document.querySelector('#social-login-google').click();
    }
  };

  // Manejador para Google
  const googleHandler = async (response) => {
    if (response.error) {
      console.log('google error', response);

      if (response.error === 'popup_closed_by_user') {
        dispatch({
          type: 'MESSAGE_SHOW_DRAWER',
          payload: {
            type: 'info',
            title: getTranslation('information_generic_title'),
            text: getTranslation('google_closed_by_user'),
          },
        });
      } else {
        dispatch({
          type: 'MESSAGE_SHOW_DRAWER',
          payload: {
            type: 'alert',
            title: getTranslation('error_generic_title'),
            text: getTranslation('profile_error_updating_google'),
          },
        });
      }
    } else {
      // Vincular cuenta de Google
      linkAccount(response.sso_token, GOOGLE_PROVIDER, response.email);
    }
  };

  const microsoftButtonHandler = (event) => {
    event.preventDefault();

    if (microsoftEmail !== '') {
      // Eliminar vinculo
      unlinkAccount(microsoftEmail, MICROSOFT_PROVIDER);
    } else {
      microsoftHandler();
    }
  };

  // Manejador para Microsoft
  const microsoftHandler = async () => {
    loginMicrosoft(
      (succesResult) => {
        // Vincular cuenta de Microsoft
        linkAccount(succesResult.sso_token, MICROSOFT_PROVIDER, succesResult.email);
      },
      (errorResult) => {
        console.log('microsoft error', errorResult);

        if (errorResult.errorCode === 'user_cancelled') {
          dispatch({
            type: 'MESSAGE_SHOW_DRAWER',
            payload: {
              type: 'info',
              title: getTranslation('information_generic_title'),
              text: getTranslation('microsoft_closed_by_user'),
            },
          });
        } else {
          dispatch({
            type: 'MESSAGE_SHOW_DRAWER',
            payload: {
              type: 'alert',
              title: getTranslation('error_generic_title'),
              text: getTranslation('profile_error_updating_microsoft'),
            },
          });
        }
      }
    );
  };

  const changePassword = () => {
    navigate('/change-password');
  };

  const handleGoToProfile = () => {
    navigate('/profile');
  };

  const handleGoHome = () => {
    navigate('/home');
  };

  const parseUserLicensesData = (_userLicenses) => {
    const parsedLicenses = [];

    for (let a = 0, aMax = _userLicenses.length; a < aMax; a++) {
      if (_userLicenses[a].licenses && _userLicenses[a].licenses.length > 0) {
        const appNameCode = _userLicenses[a].application_name;
        const auxLicenses = _userLicenses[a].licenses;

        for (let l = 0, lMax = auxLicenses.length; l < lMax; l++) {
          const auxLicense = auxLicenses[l];

          parsedLicenses.push({
            icon: getAppIcon(appNameCode),
            name: getTranslation(appNameCode),
            // code: 'FAKE_CODE_123',
            isbn: auxLicense.isbn ? auxLicense.isbn : [],
            expirationDate: new Date(auxLicense.expiration_date),
          });
        }
      }
    }

    return parsedLicenses;
  };

  const getUserLicenses = async () => {
    const polpJWT = localStorage.getItem(POLP_JSON_WEB_TOKEN);
    if (!polpJWT) {
      stoppedForJWToken();
      return;
    }

    showLoading();
    const licensesResult = await get(POLP_API_URL + 'licenses', null, { Authorization: polpJWT });
    hideLoading();

    if (licensesResult.networkError === true) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text: getTranslation('error_network'),
        },
      });

      return;
    }

    const errorName = licensesResult.name;
    if (errorName && (errorName === POLP_JSON_WEB_TOKEN_EXPIRED || errorName === POLP_JSON_WEB_TOKEN_INVALID)) {
      stoppedForJWToken();
      return;
    }

    if (licensesResult.status !== 'success') {
      console.log('licensesResult error', licensesResult);

      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text: getTranslation('profile_error_getting_licenses'),
        },
      });
    }

    const parsedUsersLicenses = parseUserLicensesData(licensesResult.data);
    setUserLicenses(parsedUsersLicenses);
  };

  return (
    <div className='profile-view'>
      <SectionLayout
        header={
          <SectionHeader
            handleGoHome={handleGoHome}
            handleGoToProfile={handleGoToProfile}
            userData={state.user}
            logout={logout}
            isProfile={true}
          />
        }
        footer={<Footer type='profile' />}
      >
        <div className='profile-view__wrapper'>
          <div className='profile-view__title'>{getTranslation('profile_title')}</div>
          <div className='profile-view__content'>
            <form>
              {/* Fila 1 - Usuario */}
              <div className='profile-view__content-row'>
                {/* Datos personales  */}
                <div className='profile-view__form-card'>
                  <div className='profile-view__form-label'>{getTranslation('profile_your_info_label')}</div>
                  <InputText
                    value={state.user.username}
                    label={getTranslation('email')}
                    disabled={isMainAccoutFromGoogle() || isMainAccoutFromMicrosoft()}
                    onChange={setEmail}
                    onEnterPressed={() => {
                      nameRef.current?.focus();
                    }}
                  />
                  <div className='profile-view__column-wrapper'>
                    <InputText
                      value={state.user.name}
                      label={getTranslation('signup_form_profile_name_label')}
                      onChange={setName}
                      refFromParent={nameRef}
                      onEnterPressed={() => {
                        lastnameRef.current?.focus();
                      }}
                    />
                    <InputText
                      value={state.user.lastname}
                      label={getTranslation('signup_form_profile_surname_label')}
                      onChange={setLastname}
                      refFromParent={lastnameRef}
                      onEnterPressed={() => {
                        schoolRef.current?.focus();
                      }}
                    />
                  </div>

                  <div className='profile-view__form-label'>{getTranslation('profile_link_accounts_label')}</div>
                  <div className='profile-view__column-wrapper'>
                    <SocialButton
                      type='google'
                      disabled={state.user.username === googleEmail}
                      isLinked={googleEmail !== ''}
                      label={
                        googleEmail !== ''
                          ? getTranslation('profile_unlink_google')
                          : getTranslation('profile_link_google')
                      }
                      onClick={googleButtonHandler}
                    />
                    {/* Este div sirve como enlace al botón de google NO QUITAR */}
                    <div id='social-login-google' style={{ display: 'none', width: '1px', height: '1px' }}></div>
                    <SocialButton
                      type='microsoft'
                      disabled={state.user.username === microsoftEmail}
                      isLinked={microsoftEmail !== ''}
                      label={
                        microsoftEmail !== ''
                          ? getTranslation('profile_unlink_microsoft')
                          : getTranslation('profile_link_microsoft')
                      }
                      onClick={microsoftButtonHandler}
                    />
                  </div>
                </div>

                {/* Datos centro escolar, cursos y asignaturas  */}
                <div className='profile-view__form-card'>
                  {!IS_POLP_MEXICO && (
                    <>
                      <div className='profile-view__form-label'>{getTranslation('profile_school_label')}</div>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions={Object.keys(selectedSchool).length > 0 ? [selectedSchool] : []}
                        value={Object.keys(selectedSchool).length === 0 ? null : selectedSchool}
                        getOptionLabel={(e) => getSchoolText(e)}
                        getOptionValue={(e) => e.id}
                        loadOptions={getSchoolsFromService}
                        onInputChange={schoolSearchChange}
                        onChange={schoolSelectedChange}
                        className='school-async-select'
                        classNamePrefix='select'
                        isClearable={false}
                        maxMenuHeight='200'
                        menuPlacement='auto'
                        placeholder={getTranslation('profile_school_select')}
                        loadingMessage={() => getTranslation('signup_form_profile_school_loading_options')}
                        noOptionsMessage={() => getTranslation('signup_form_profile_school_no_options')}
                        ref={schoolRef}
                        onKeyDown={(e) => {
                          if (e.key.toLowerCase() === 'enter') e.preventDefault();
                        }}
                      />
                    </>
                  )}

                  <div className='profile-view__form-label'>{getTranslation('profile_course_label')}</div>
                  <TwoLevelsSelector
                    selectedOptions={selectedGrades}
                    setSelectedOptions={setSelectedGrades}
                    value={selectedGrades}
                    options={gradesOptions}
                    reference={gradesRef}
                    isSearchable={true}
                    placeholder={getTranslation('profile_grades_select')}
                    maxMenuHeight='200'
                  />

                  <Select
                    value={selectedSubjects}
                    onChange={handlerSubjects}
                    isMulti
                    name='subjects'
                    options={subjectsOptions}
                    className='basic-multi-select'
                    classNamePrefix='select'
                    isSearchable={true}
                    placeholder={getTranslation('profile_subjects_select')}
                    components={{ IndicatorSeparator: false }}
                    hideSelectedOptions={false}
                    closeMenuOnSelect={false}
                    maxMenuHeight='200'
                    menuPlacement='auto'
                  />
                </div>
              </div>

              {/* Fila 2 - Licencias */}
              {userLicenses.length > 0 && (
                <div className='profile-view__content-row'>
                  {/* Licencias */}
                  <div className='profile-view__form-card'>
                    <div className='profile-view__licenses-table'>
                      {/* Licencias cabecera */}
                      <div className='profile-view__licenses-table-head profile-view__licenses-table-row'>
                        <div className='profile-view__app-column profile-view__form-label'>
                          {getTranslation('profile_licenses_license_label')}
                        </div>
                        {/*
                        Se iba a mostrar el código de la licencia, pero finalmente no
                        <div className='profile-view__license-code-column profile-view__form-label'>{getTranslation('profile_licenses_code_label')}</div>
                        */}
                        <div className='profile-view__isbn-column profile-view__form-label'>
                          {getTranslation('profile_licenses_isbn_label')}
                        </div>
                        <div className='profile-view__expiration-date-column profile-view__form-label'>
                          {getTranslation('profile_licenses_expiration_date_label')}
                        </div>
                      </div>
                      {/* Licencias fila */}
                      {userLicenses.map((_license, index) => (
                        <div key={index} className='profile-view__licenses-table-row'>
                          <div
                            className='profile-view__app-column'
                            data-label={getTranslation('profile_licenses_license_label')}
                          >
                            <span className='profile-view__app-content'>
                              <img src={_license.icon} alt='' />
                              <span>{_license.name}</span>
                            </span>
                          </div>
                          {/*
                          Se iba a mostrar el código de la licencia, pero finalmente no
                          <div className='profile-view__license-code-column' data-label={getTranslation('profile_licenses_code_label')}>{_license.code}</div>
                          */}
                          <div
                            className='profile-view__isbn-column'
                            data-label={getTranslation('profile_licenses_isbn_label')}
                          >
                            <div className='profile-view__licenses-isbn'>
                              {_license.isbn.map((_isbn, index) => (
                                <div key={index}>{_isbn}</div>
                              ))}
                            </div>
                          </div>
                          <div
                            className='profile-view__expiration-date-column'
                            data-label={getTranslation('profile_licenses_expiration_date_label')}
                          >
                            {_license.expirationDate.toLocaleDateString(undefined, {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                            })}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {/* Fila 3 - Contraseña  */}
              {!isMainAccoutFromGoogle() && !isMainAccoutFromMicrosoft() && (
                <>
                  <div className='profile-view__content-row'>
                    <div className='profile-view__form-card'>
                      <div className='profile-view__form-label'>{getTranslation('profile_change_password_label')}</div>
                      <Button
                        text={getTranslation('profile_new_password')}
                        size='big'
                        color='black'
                        onClick={(e) => {
                          e.preventDefault();
                          changePassword();
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              {/* Fila 4 - Acciones  */}
              <div className='profile-view__content-row'>
                <div className='profile-view__actions'>
                  <Button
                    text={getTranslation('profile_save_changes')}
                    size='big'
                    color='black'
                    disabled={hasMissingFields || isProfileUnchanged}
                    onClick={(e) => {
                      e.preventDefault();
                      saveUpdatedProfile();
                    }}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </SectionLayout>
    </div>
  );
}

export default ProfileView;
