import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowIcon from '../../../assets/icons/arrow-sm-right.svg';
import {
  GOOGLE_PROVIDER,
  IS_POLP_MEXICO,
  MICROSOFT_PROVIDER,
  ORACULO_PROVIDER,
  POLP_API_URL,
  POLP_JSON_WEB_TOKEN,
} from '../../../config/constants';
import { getTranslation } from '../../../locale/texts';
import AnimatedBackgroundLayout from '../../../modules/layout/AnimatedBackgroundLayout';
import { checkLicense } from '../../../services/licenseService';
import {
  getUserData,
  loginOauth,
  loginOraculoWithCredentials,
  loginOraculoWithToken,
  setOraculoGlobalSession,
} from '../../../services/loginService';
import { post } from '../../../services/requestService';
import { validatePassword } from '../../../services/validationService';
import { StoreContext } from '../../../store/store';
import AnimatedLogo from '../../atoms/AnimatedLogo';
import BackgroundDecoration from '../../atoms/BackgroundDecoration';
import SignUpFormCourse from '../../components/SignUpFormCourse';
import SignUpFormPassword from '../../components/SignUpFormPassword';
import SignUpFormProfile from '../../components/SignUpFormProfile';
import SignUpSteps from '../../components/SignUpSteps/SignUpSteps';

function SignUpView() {
  const [state, dispatch] = useContext(StoreContext);

  const navigate = useNavigate();

  const [step, setStep] = useState(1);
  const [logoTransition, setLogoTransition] = useState('transition1to2');
  const [startLogoTransition, setStartLogoTransition] = useState(false);

  const [signUpProvider, setSignUpProvider] = useState(ORACULO_PROVIDER);
  const [signUpToken, setSignUpToken] = useState('');
  const [signUpEmail, setSignUpEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [privacy, setPrivacy] = useState(false);
  const [consent, setConsent] = useState(false);

  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [school, setSchool] = useState({});
  const [license, setLicense] = useState([]);
  const [isValidLicense, setIsValidLicense] = useState(!IS_POLP_MEXICO);

  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const searchToken = queryParams.get('token');
    const searchEmail = decodeURIComponent(queryParams.get('email'));

    if (!searchToken || !searchEmail) {
      if (state.social.token.length > 0) {
        setSignUpProvider(state.social.provider);
        setSignUpToken(state.social.token);
        setSignUpEmail(state.social.email);
        dispatch({ type: 'SOCIAL_CLEAR_VALUES' });
      } else {
        dispatch({
          type: 'MESSAGE_SHOW_DRAWER',
          payload: {
            type: 'alert',
            title: getTranslation('error_generic_title'),
            text: getTranslation('signup_error_link'),
          },
        });
      }
    } else {
      setSignUpToken(searchToken);
      setSignUpEmail(searchEmail);
    }
  }, []);

  useEffect(() => {
    checkLicenseCode(license);
  }, [license]);

  async function checkLicenseCode(license) {
    if (license.length > 0) {
      const role = await checkLicense(license.toString());
      if (!role) {
        setIsValidLicense(false);
        dispatch({
          type: 'MESSAGE_SHOW_DRAWER',
          payload: {
            type: 'alert',
            title: getTranslation('error_generic_title'),
            text: getTranslation('signup_error_incorrect_license'),
          },
        });
        return;
      }
      if (role === 'student') {
        setIsValidLicense(false);
        dispatch({
          type: 'MESSAGE_SHOW_DRAWER',
          payload: {
            type: 'alert',
            title: getTranslation('error_generic_title'),
            text: getTranslation('signup_error_license_student'),
          },
        });
      } else if (role === 'teacher') {
        setIsValidLicense(true);
        dispatch({
          type: 'MESSAGE_HIDE_DRAWER',
        });
      }
    } else if (license.length === 0 && IS_POLP_MEXICO) {
      setIsValidLicense(false);
    }
  }

  const showErrorNetwork = () => {
    dispatch({
      type: 'MESSAGE_SHOW_DRAWER',
      payload: {
        type: 'alert',
        title: getTranslation('error_generic_title'),
        text: getTranslation('error_network'),
      },
    });
  };

  const handlePasswordForm = (formData) => {
    setPassword(formData.password);
    setRepeatPassword(formData.repeatPassword);
    setPrivacy(formData.privacy);
    setConsent(formData.consent);
  };

  const isValidPasswordForm = () => {
    if (signUpProvider === ORACULO_PROVIDER) {
      if (!validatePassword(password)) {
        dispatch({
          type: 'MESSAGE_SHOW_DRAWER',
          payload: {
            type: 'alert',
            title: getTranslation('error_invalid_password_title'),
            text: getTranslation('error_invalid_password_text'),
          },
        });

        return false;
      }

      if (password !== repeatPassword) {
        dispatch({
          type: 'MESSAGE_SHOW_DRAWER',
          payload: {
            type: 'alert',
            title: getTranslation('error_invalid_repeat_password_title'),
            text: getTranslation('error_invalid_repeat_password_text'),
          },
        });

        return false;
      }
    }

    if (!privacy) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_data_title'),
          text: getTranslation('signup_unchecked_privacy'),
        },
      });

      return false;
    }

    /* if (!consent) {      
      return false;
    } */

    return true;
  };

  const handleProfileForm = (formData) => {
    setName(formData.name);
    setSurname(formData.surname);
    setSchool(formData.school);
    setLicense(formData.license);
  };

  const isValidProfileForm = () => {
    if (name.length < 2) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_data_title'),
          text: getTranslation('signup_missing_name'),
        },
      });

      return false;
    }

    if (surname.length < 2) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_data_title'),
          text: getTranslation('signup_missing_surname'),
        },
      });

      return false;
    }

    if (!IS_POLP_MEXICO && (Object.keys(school).length === 0 || school.id === '')) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_data_title'),
          text: getTranslation('signup_missing_school'),
        },
      });

      return false;
    }

    if (IS_POLP_MEXICO && !isValidLicense) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text: getTranslation('signup_error_license_empty'),
        },
      });
      return false;
    }

    return true;
  };

  const handleCourseForm = (formData) => {
    setGrades(formData.grades);
    setSubjects(formData.subjects);
  };

  const isValidCourseForm = () => {
    if (grades.length === 0) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_data_title'),
          text: getTranslation('signup_missing_grades'),
        },
      });

      return false;
    }

    if (subjects.length === 0) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_data_title'),
          text: getTranslation('signup_missing_subjects'),
        },
      });

      return false;
    }

    return true;
  };

  const postSignup = (signupData) => {
    return post(POLP_API_URL + 'signup', signupData)
      .then((response) => {
        if (response.status === 'success') return { token: response.data.token };

        return { error: JSON.stringify(response) };
      })
      .catch((error) => {
        return { error: JSON.stringify(error) };
      });
  };

  const loginFailedRedirect = () => {
    setTimeout(() => {
      navigate('/login');
    }, 50);
  };

  const autoLogin = async (_provider) => {
    const oraculoResult =
      _provider === ORACULO_PROVIDER
        ? await loginOraculoWithCredentials(signUpEmail, password)
        : await loginOraculoWithToken(signUpToken, _provider);

    let errorText = '';
    switch (_provider) {
      case GOOGLE_PROVIDER:
        errorText = getTranslation('login_error_google');
        break;
      case MICROSOFT_PROVIDER:
        errorText = getTranslation('login_error_microsoftr_logging');
        break;
      default:
        errorText = getTranslation('login_error_logging');
        break;
    }

    if (oraculoResult.error === 'network error') {
      showErrorNetwork();

      loginFailedRedirect();
      return;
    }

    if (oraculoResult.error) {
      const isIncorrectPassword = oraculoResult.error === 'user and password mismatch';

      if (!isIncorrectPassword) {
        dispatch({
          type: 'MESSAGE_SHOW_DRAWER',
          payload: { type: 'alert', title: getTranslation('error_generic_title'), text: errorText },
        });
      } else {
        dispatch({
          type: 'MESSAGE_SHOW_DRAWER',
          payload: {
            type: 'alert',
            title: getTranslation('error_data_title'),
            text: getTranslation('login_error_incorrect_password'),
          },
        });
      }

      loginFailedRedirect();
      return;
    }

    const oauthResult = await loginOauth(oraculoResult.tokenId, ORACULO_PROVIDER);

    if (oauthResult.error === 'network error') {
      showErrorNetwork();

      loginFailedRedirect();
      return;
    }

    if (oauthResult.error) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text: errorText,
        },
      });

      loginFailedRedirect();
      return;
    }

    const userData = await getUserData(oauthResult.jwt);

    if (!userData) {
      dispatch({
        type: 'MESSAGE_SHOW_DRAWER',
        payload: {
          type: 'alert',
          title: getTranslation('error_generic_title'),
          text: errorText,
        },
      });

      loginFailedRedirect();
      return;
    }

    dispatch({ type: 'USER_SET_VALUES', payload: { ...userData, logged: true } });
    localStorage.setItem(POLP_JSON_WEB_TOKEN, oauthResult.jwt);
    setOraculoGlobalSession(oraculoResult.sid);

    // setTimeout(() => {
    navigate('/home');
    // }, 100);
  };

  const submitSignUp = async () => {
    if (isValidCourseForm()) {
      const gradesIds = grades.map((item) => item.value);
      const subjectsIds = subjects.map((item) => item.value);

      const signupData = {
        token: signUpToken,
        identity_provider: signUpProvider,
        email: signUpEmail,
        name: name,
        lastname: surname,
        password: password,
        role: 'teacher',
        school_id: school.id,
        grades: gradesIds,
        subjects: subjectsIds,
        has_commercial_consent: consent,
        country_guid: IS_POLP_MEXICO ? 'mx' : 'es',
        create_personal_school: !!IS_POLP_MEXICO,
        ...(IS_POLP_MEXICO && license.length > 0 && { licenses: [license] }),
      };

      const signupResult = await postSignup(signupData);

      if (signupResult.error === 'network error') {
        showErrorNetwork();

        return;
      }

      if (signupResult.error) {
        dispatch({
          type: 'MESSAGE_SHOW_DRAWER',
          payload: {
            type: 'alert',
            title: getTranslation('error_generic_title'),
            text: getTranslation('signup_error_signing'),
          },
        });

        return;
      }

      autoLogin(signUpProvider);
    }
  };

  const transition1to2 = () => {
    if (isValidPasswordForm()) {
      setLogoTransition('transition1to2');
      setStartLogoTransition(!startLogoTransition);
      setStep(2);
    }
  };

  const transition2to3 = () => {
    if (isValidProfileForm()) {
      setLogoTransition('transition2to3');
      setStartLogoTransition(!startLogoTransition);
      setStep(3);
    }
  };

  const transition2to1 = () => {
    setLogoTransition('transition2to1');
    setStartLogoTransition(!startLogoTransition);
    setStep(1);
  };

  const transition3to1 = () => {
    setLogoTransition('transition3to1');
    setStep(1);
  };

  const transition3to2 = () => {
    setLogoTransition('transition3to2');
    setStartLogoTransition(!startLogoTransition);
    setStep(2);
  };

  return (
    <div className='sign-up-view'>
      <AnimatedBackgroundLayout currentStep={step}>
        {/* Logo */}
        <div className='sign-up-view__logo'>
          <AnimatedLogo startTransition={startLogoTransition} transitionType={logoTransition} />
        </div>

        {/* Steps */}
        <div className='sign-up-view__steps'>
          <SignUpSteps currentStep={step} />
        </div>

        {/* Form */}
        {/* Footer */}
        {step === 1 && (
          <>
            <div className='sign-up-view__decoration animated fade-in'>
              <BackgroundDecoration type='palomitas' />
            </div>

            <div className='sign-up-view__form'>
              <SignUpFormPassword
                initialValues={{ password, repeatPassword, privacy, consent }}
                onFormChange={handlePasswordForm}
                identityProvider={signUpProvider}
              />
            </div>

            <div className='sign-up-view__footer'>
              <button className='sign-up-footer-btn-next' onClick={transition1to2}>
                <img src={ArrowIcon} alt='' />
              </button>
            </div>
          </>
        )}

        {step === 2 && (
          <>
            <div className='sign-up-view__decoration animated fade-in'>
              <BackgroundDecoration type='chica' />
            </div>

            <div className='sign-up-view__form'>
              <SignUpFormProfile
                initialValues={{ name, surname, school, license }}
                onFormChange={handleProfileForm}
                showLicenseInput={IS_POLP_MEXICO}
              />
            </div>

            <div className='sign-up-view__footer'>
              <button className='sign-up-footer-btn-dot' onClick={transition2to1}></button>
              <button className='sign-up-footer-btn-next' onClick={transition2to3}>
                <img src={ArrowIcon} alt='' />
              </button>
            </div>
          </>
        )}

        {step === 3 && (
          <>
            <div className='sign-up-view__decoration animated fade-in'>
              <BackgroundDecoration type='monstruo' />
            </div>

            <div className='sign-up-view__form'>
              <SignUpFormCourse initialValues={{ grades, subjects }} onFormChange={handleCourseForm} />
            </div>

            <div className='sign-up-view__footer'>
              <button className='sign-up-footer-btn-dot' onClick={transition3to1}></button>
              <button className='sign-up-footer-btn-dot' onClick={transition3to2}></button>
              <button className='sign-up-footer-btn-next' onClick={submitSignUp}>
                <img src={ArrowIcon} alt='' />
              </button>
            </div>
          </>
        )}
      </AnimatedBackgroundLayout>
    </div>
  );
}

export default SignUpView;
