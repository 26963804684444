export const DEVELOPMENT = {
  POLP_FEDERATE_APP_ID: 1726090626331533,
  POLP_FEDERATE_APP_NAME: 'Polp',
  POLP_FEDERATE_APP_SHARED_KEY: '7jDvvPMskAr7o4AGYZkOvEBkFnw3+qm/c1BDjWxFzWeUPCJ03K8bci21jQ9TeyMsaEWhHvrHU44GFzdZc7V4i',
  POLP_URL: 'https://polp-dev.oneclicklabs.es/',
  ORACULO_LIBRARIES_URL: 'https://auth.polp-dev.oneclicklabs.es/',
  ORACULO_API_AUTH: 'https://oraculo-dev.oneclicklabs.es/auth/',
  GOOGLE_CLIENT_ID: '578296205556-dbtij03p24hvpia9bmcjsce7sl0vd0bu.apps.googleusercontent.com',
  MICROSOFT_CLIENT_ID: 'a14b753b-44c4-4d29-af02-660563244d5b',
  TATUM_MORE_INFO_LINK: 'https://ta-tum.oneclicklabs.es',
  GO_STEAM_MORE_INFO_LINK: 'https://gosteam-dev.oneclicklabs.es',
  HELP_TECH_EMAIL: 'carmen.navarro@oneclick.es',
  HELP_SALES_EMAIL: 'carmen.navarro@oneclick.es',
  SUPPORT_EMAIL: 'carmen.navarro@oneclick.es',
  CUSTOMER_EXPERIENCE: 'carmen.navarro@oneclick.es',
};

export const DEVELOPMENT_MX = {
  POLP_FEDERATE_APP_ID: 1792117061306029,
  POLP_FEDERATE_APP_NAME: 'POLP_MX',
  POLP_FEDERATE_APP_SHARED_KEY: 'TiRwk5M3h1e/LC//XoBjRMhO2WsSsLlYpQPtLsG4amcxvIyDfHxfp8l1J5JL/R+Oq9CPlnNYRGrw1Bsy4ObZq',
  POLP_URL: 'https://polp-mx-dev.oneclicklabs.es/',
  ORACULO_LIBRARIES_URL: 'https://auth.polp-dev.oneclicklabs.es/',
  ORACULO_API_AUTH: 'https://oraculo-dev.oneclicklabs.es/auth/',
  GOOGLE_CLIENT_ID: '578296205556-dbtij03p24hvpia9bmcjsce7sl0vd0bu.apps.googleusercontent.com',
  MICROSOFT_CLIENT_ID: 'a14b753b-44c4-4d29-af02-660563244d5b',
  TATUM_MORE_INFO_LINK: 'https://ta-tum.oneclicklabs.es',
  GO_STEAM_MORE_INFO_LINK: 'https://gosteam-dev.oneclicklabs.es',
  HELP_TECH_EMAIL: 'carmen.navarro@oneclick.es',
  HELP_SALES_EMAIL: 'carmen.navarro@oneclick.es',
  SUPPORT_EMAIL: 'carmen.navarro@oneclick.es',
  CUSTOMER_EXPERIENCE: 'carmen.navarro@oneclick.es',
};
