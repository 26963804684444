import React from 'react';
import { useNavigate } from 'react-router-dom';
import Check from '../../../assets/icons/check-big.svg';
import { SUPPORT_EMAIL } from '../../../config/constants';
import { getTranslation } from '../../../locale/texts';
import BackgroundDecoration from '../../atoms/BackgroundDecoration';
import CloseButton from '../../atoms/CloseButton';

function EmailSentView() {
  const navigate = useNavigate();

  const onClose = () => navigate('/login');

  return (
    <div className='email-sent-view'>
      <div className='email-sent-view__decoration'>
        <BackgroundDecoration type='paloma' />
      </div>
      <div className='email-sent-view__close-btn'>
        <CloseButton onClick={onClose} />
      </div>
      <div className='email-sent-view__content'>
        <div className='email-sent-view__check'>
          <img className='email-sent-view__check-img' src={Check} alt='Polp' />
        </div>

        <div className='email-sent-view__title'>{getTranslation('email_sent_title')}</div>

        <div className='email-sent-view__subtitle'>
          {getTranslation('email_sent_subtitle') + ' '}
          <a href={'mailto:' + SUPPORT_EMAIL}>{SUPPORT_EMAIL}</a>
        </div>
      </div>
    </div>
  );
}

export default EmailSentView;
