import * as Msal from 'msal';
import { GOOGLE_CLIENT_ID, MICROSOFT_AUTHORITY, MICROSOFT_CLIENT_ID, REDIRECT_URI } from '../config/constants';

export function conectGoogle(element, onSubmitSSO, onErrorSSO) {
  const clientId = GOOGLE_CLIENT_ID;
  let auth2;

  if (window.gapi) {
    window.gapi.load('auth2', googleGapiInit);

    function googleGapiInit() {
      auth2 = window.gapi.auth2.init({
        client_id: clientId,
        cookiepolicy: 'single_host_origin',
        redirect_uri: REDIRECT_URI,
      });

      auth2.attachClickHandler(element, getSignInOptions(), googleSsoSuccess, googleSsoFailure);
    }
  }

  function getSignInOptions() {
    const options = new window.gapi.auth2.SigninOptionsBuilder();
    options.setPrompt('select_account'); // El servidor de autorización solicita al usuario que seleccione una cuenta de Google. Para usuario que tiene varias cuentas pueda seleccionar una antes de entrar.

    return options;
  }

  function googleSsoSuccess(googleUser) {
    const user = {
      name: googleUser.getBasicProfile().getGivenName(),
      lastname: googleUser.getBasicProfile().getFamilyName(),
      email: googleUser.getBasicProfile().getEmail(),
      sso_token: googleUser.getAuthResponse().id_token,
      identity_provider: 'google',
    };

    onSubmitSSO(user);
  }

  function googleSsoFailure(error) {
    onErrorSSO(error);
  }
}

export function loginMicrosoft(onSubmitSSO, onErrorSSO) {
  const config = {
    auth: { clientId: MICROSOFT_CLIENT_ID, authority: MICROSOFT_AUTHORITY, redirectUri: REDIRECT_URI },
  };

  const myMSALObj = new Msal.UserAgentApplication(config);
  const requestObj = { scopes: ['user.read'] };

  myMSALObj
    .loginPopup(requestObj)
    .then(async function (loginResponse) {
      myMSALObj
        .acquireTokenSilent(requestObj)
        .then(async (response) => {
          const user = {
            name: response.account.name,
            email: response.account.userName,
            sso_token: response.accessToken,
            identity_provider: 'microsoft',
          };
          onSubmitSSO(user);
        })
        .catch(function (_error) {
          myMSALObj
            .acquireTokenPopup(requestObj)
            .then(async function (response) {
              const user = {
                name: response.account.name,
                email: response.account.userName,
                sso_token: response.accessToken,
                identity_provider: 'microsoft',
              };
              onSubmitSSO(user);
            })
            .catch(function (error) {
              onErrorSSO && onErrorSSO(error);
            });
        });
    })
    .catch(function (error) {
      onErrorSSO && onErrorSSO(error);
    });
}
