import React, { useState } from 'react';
import { getTranslation } from '../../../locale/texts';

function InputLicense({ placeholder, onChange, onClick }) {
  const [inputValue, setInputValue] = useState('');

  const handleChange = (newValue) => {
    newValue = newValue.trim();
    setInputValue(newValue);
    onChange(newValue);
  };

  return (
    <div className='input-license'>
      <input
        className='input-license__input'
        type='text'
        maxLength='100'
        value={inputValue}
        placeholder={placeholder}
        onChange={(event) => handleChange(event.target.value)}
        onKeyDown={(event) => {
          if (event.key.toLowerCase() === 'enter') event.preventDefault();
        }}
      />

      <button
        className='input-license__button'
        onClick={() => {
          onClick(inputValue);
        }}
      >
        {getTranslation('home_license_activate')}
      </button>
    </div>
  );
}

InputLicense.defaultProps = {
  placeholder: 'Código de la licencia',
  onClick: () => console.log('Click!'),
};

export default InputLicense;
