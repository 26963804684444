const request = (url, params = {}, headers = {}, method = 'GET', isFormdata) => {
  const options = {
    method,
  };

  if (method === 'GET' && params !== null && Object.entries(params).length > 0) {
    url += '?' + new URLSearchParams(params).toString();
  }

  if (method !== 'GET') {
    if (!isFormdata) {
      options.headers = {};
      options.headers['Content-Type'] = 'application/json';
      options.body = JSON.stringify(params);
    } else {
      options.body = params;
    }
  }

  Object.entries(headers).forEach((item) => {
    if (!options.headers) options.headers = {};
    options.headers[item[0]] = item[1];
  });

  return fetch(url, options)
    .then((response) => response.json())
    .catch((error) => {
      return { networkError: true, message: error.message, stack: error.stack };
    });
};

export const get = (url, params, headers) => request(url, params, headers, 'GET');
export const post = (url, params, headers, isFormdata = false) => request(url, params, headers, 'POST', isFormdata);
export const put = (url, params, headers, isFormdata = false) => request(url, params, headers, 'PUT', isFormdata);
export const delete_ = (url, params, headers, isFormdata = false) =>
  request(url, params, headers, 'DELETE', isFormdata);
export const patch = (url, params, headers, isFormdata = false) => request(url, params, headers, 'PATCH', isFormdata);
