export const PRODUCTION = {
  POLP_FEDERATE_APP_ID: 1728082291326889,
  POLP_FEDERATE_APP_NAME: 'POLP',
  POLP_FEDERATE_APP_SHARED_KEY: 'PnBPE1KeTYcRsTlPey0ZmJoxbJAlDRSCegHThp24MLcSGAGwieyAEJs/EtVjbcJvfqgHs8UcHv9z399KzGATT',
  POLP_URL: 'https://polpbyedelvives.com/',
  ORACULO_API_AUTH: 'https://oraculo-polp.oneclick.es/auth/',
  ORACULO_LIBRARIES_URL: 'https://auth.polpbyedelvives.com/',
  GOOGLE_CLIENT_ID: '332548913064-be234rkmvej4d671jml2q2f906g6cr8h.apps.googleusercontent.com',
  MICROSOFT_CLIENT_ID: '226b3390-1e32-456d-a270-ec0f432f246b',
  TATUM_MORE_INFO_LINK: 'https://ta-tum.com',
  GO_STEAM_MORE_INFO_LINK: 'https://gosteamedelvives.com/',
  HELP_TECH_EMAIL: 'experienciacliente@edelvives.es',
  HELP_SALES_EMAIL: 'digital@edelvives.es',
  SUPPORT_EMAIL: 'digital@edelvives.es',
  CUSTOMER_EXPERIENCE: 'experienciacliente@edelvives.es',
};

export const PRODUCTION_MX = {
  POLP_FEDERATE_APP_ID: 1792209407632651,
  POLP_FEDERATE_APP_NAME: 'POLP_MX',
  POLP_FEDERATE_APP_SHARED_KEY: 'QK46l4I3ajj9GL4vSE14ExAnqwvjwuvZORUqp3+AmhMQaIkco9mRb2xOOJ6HoeK0JdTdWCsWBndcCt+Q6Csg1',
  POLP_URL: 'https://polpbyedelvives.com.mx/',
  ORACULO_LIBRARIES_URL: 'https://auth.polpbyedelvives.com/',
  ORACULO_API_AUTH: 'https://oraculo-polp.oneclick.es/auth/',
  GOOGLE_CLIENT_ID: '332548913064-be234rkmvej4d671jml2q2f906g6cr8h.apps.googleusercontent.com',
  MICROSOFT_CLIENT_ID: '226b3390-1e32-456d-a270-ec0f432f246b',
  TATUM_MORE_INFO_LINK: 'https://ta-tum.com',
  GO_STEAM_MORE_INFO_LINK: 'https://gosteamedelvives.com/',
  HELP_TECH_EMAIL: 'experienciacliente@edelvives.es',
  HELP_SALES_EMAIL: 'digital@edelvives.es',
  SUPPORT_EMAIL: 'digital@edelvives.es',
  CUSTOMER_EXPERIENCE: 'experienciacliente@edelvives.es',
};
