import React, { useRef, useState } from 'react';
import IconEye from '../../../assets/icons/eye.svg';

function InputPassword({ label, value, onChange, refFromParent, onEnterPressed, ...props }) {
  const inputRef = refFromParent !== undefined ? refFromParent : useRef(null);
  const [inputValue, setInputValue] = useState(value);
  const [inputVisible, setInputVisible] = useState(false);

  const handleChange = (newValue) => {
    setInputValue(newValue);
    onChange(newValue);
  };

  return (
    <div className='input-password'>
      <input
        ref={inputRef}
        className={`input-password__input ${inputValue ? 'input-password__input--active' : ''}`}
        type={inputVisible ? 'text' : 'password'}
        maxLength='100'
        value={inputValue}
        {...props}
        onChange={(event) => handleChange(event.target.value)}
        onKeyDown={(event) => {
          if (event.key.toLowerCase() === 'enter') {
            event.preventDefault();
            if (onEnterPressed) onEnterPressed();
          }
        }}
      />
      <label
        className='input-password__label'
        onClick={() => {
          inputRef.current?.focus();
        }}
      >
        {label}
      </label>
      <img className='input-password__toggle' src={IconEye} onClick={() => setInputVisible(!inputVisible)} />
    </div>
  );
}

InputPassword.defaultProps = {
  label: 'Label',
};

export default InputPassword;
