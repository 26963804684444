import React from 'react';
import Google from '../../../assets/icons/google-logo.svg';
import Microsoft from '../../../assets/icons/ms-logo.svg';

function SocialButton({ type, label, onClick, buttonId, disabled, isLinked }) {
  let image;
  const _buttonId = buttonId || 'sb-' + Date.now();

  switch (type) {
    case 'google':
      image = Google;
      break;
    case 'microsoft':
      image = Microsoft;
      break;

    default:
      image = Google;
  }

  return (
    <button
      id={_buttonId}
      className={`social-button ${isLinked && 'social-button--linked'}`}
      onClick={onClick}
      disabled={disabled}
    >
      <div className={`social-button__image`}>
        <img src={image} alt='' />
      </div>
      <div className={`social-button__label`}>{label}</div>
    </button>
  );
}

SocialButton.defaultProps = {
  type: 'google',
  label: 'Google',
  onClick: () => {},
};

export default SocialButton;
