import { GoogleLogin } from '@react-oauth/google';
import clsx from 'clsx';
import i18next from 'i18next';
import React from 'react';
import ArrowIcon from '../../../assets/icons/arrow-sm-right.svg';
import PolpLogoSmall from '../../../assets/icons/polp_logo.png';
import PolpLogo from '../../../assets/polp_logo_00.svg';
import { getTranslation } from '../../../locale/texts';
import AlertMessage from '../../atoms/AlertMessage/AlertMessage';
import CloseButton from '../../atoms/CloseButton';
import InputMailButton from '../../atoms/InputMailButton';
import InputPassword from '../../atoms/InputPassword';
import SocialButton from '../../atoms/SocialButton';
import useLoginView from './useLoginView';

// TODO: Login layout component

function LoginView() {
  const {
    isAppOrigin,
    isPasswordEnable,
    passwordRef,
    password,
    isPasswordLengthValid,
    state,
    dispatch,
    navigate,
    setEmail,
    checkIfUserIsRegisteredByEmail,
    setPassword,
    polpLogin,
    sendEmailForRecoverPassword,
    microsoftHandler,
    googleHandler,
  } = useLoginView();

  // useEffect(() => {
  //   if (isAppOrigin) return;
  //   conectGoogle(document.querySelector('#social-login-google'), googleHandler, googleHandler);
  // }, []);

  return (
    <div className={clsx('login-view', { 'login-view--app': isAppOrigin })}>
      <div className='login-view__close-btn'>
        <CloseButton onClick={() => navigate('/')} />
      </div>

      <div className='login-view__content'>
        <div className='login-view__logo'>
          <img className='login-view__logo-img' src={isAppOrigin ? PolpLogoSmall : PolpLogo} alt='Polp' />
        </div>

        <div className='login-view__title'>{getTranslation('login_title')}</div>

        <div className='login-view__subtitle'>
          {isAppOrigin ? getTranslation('login_subtitle_app') : getTranslation('login_subtitle')}
        </div>

        <div className='login-view__form'>
          <div className='login-view__email-input'>
            <InputMailButton
              placeholder={getTranslation('email')}
              label={getTranslation('email')}
              onChange={setEmail}
              onClick={checkIfUserIsRegisteredByEmail}
              showButton={!isPasswordEnable}
              onEnterPressed={checkIfUserIsRegisteredByEmail}
            />
          </div>

          {isPasswordEnable && (
            <div className='login-view__password-input'>
              <InputPassword
                refFromParent={passwordRef}
                value={password}
                onChange={setPassword}
                label={getTranslation('password')}
                onEnterPressed={polpLogin}
              />
            </div>
          )}

          {isPasswordEnable && (
            <div className='login-view__password-recovery'>
              <a href='#' onClick={sendEmailForRecoverPassword}>
                {getTranslation('login_password_forgotten')}
              </a>
            </div>
          )}

          {isPasswordEnable && (
            <button
              className={
                'login-view__button-login' +
                (isPasswordLengthValid ? ' login-view__button-login-enabled' : ' login-view__button-login-disabled')
              }
              onClick={polpLogin}
            >
              <img src={ArrowIcon} alt='' />
            </button>
          )}

          {state.message.visible && isAppOrigin && (
            <AlertMessage
              type={state.message.type}
              message={state.message.text}
              color={state.message?.color}
              onClose={() => {
                dispatch({ type: 'MESSAGE_HIDE_DRAWER' });
              }}
            />
          )}

          <div className='login-view__social-title'>{getTranslation('login_social_title')}</div>
          <div className='login-view__social-actions'>
            {/* <SocialButton buttonId='social-login-google' type='google' label='Google' /> */}
            <GoogleLogin
              width='353px'
              onSuccess={(credentialResponse) => {
                googleHandler(credentialResponse);
              }}
              onError={(credentialResponse) => {
                googleHandler(credentialResponse);
              }}
              locale={i18next.language}
            />
            <SocialButton type='microsoft' label='Microsoft' onClick={microsoftHandler} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginView;
