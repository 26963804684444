import React from 'react';
import LoadingIcon from '../../../assets/icons/loading.svg';

function Loading() {
  return (
    <div className='loading'>
      <div className='loading__icon'>
        <img src={LoadingIcon} alt='' />
      </div>
    </div>
  );
}

export default Loading;
