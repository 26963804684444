import React from 'react';
import ChevronDown from '../../../assets//icons/chevron_down.svg';
import PolpLogo from '../../../assets/polp_logo_home.svg';
import { getTranslation } from '../../../locale/texts';
import Button from '../../atoms/Button';

function HomeHeader({ handleAddLicense, handleGoToProfile, userData, logout }) {
  return (
    <div className='home-header'>
      <div className='home-header__content'>
        <Button text={getTranslation('home_header_add_license')} color='green' onClick={handleAddLicense} />
        <div className='home-header__logo'>
          <img src={PolpLogo} alt='Polp' />
        </div>
        <div className='home-header__profile-menu'>
          <div className='home-header__profile-menu-button'>
            <span className='truncate'>{userData && userData.name ? userData.name : ''}</span>
            <img src={ChevronDown} alt='' />
          </div>
          <div className='home-header__profile-menu-content'>
            <div className='home-header__profile-menu-item' onClick={handleGoToProfile}>
              {getTranslation('home_header_edit_profile')}
            </div>
            <div className='home-header__profile-menu-item' onClick={logout}>
              {getTranslation('home_header_sign_out')}
            </div>
          </div>
        </div>
      </div>
      <div className='home-header__separator'>
        <div className='line line--orange'></div>
        <div className='line line--blue'></div>
        <div className='line line--pink'></div>
        <div className='line line--green'></div>
      </div>
    </div>
  );
}

export default HomeHeader;
