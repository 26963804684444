import React, { useRef, useState } from 'react';
import ArrowIcon from '../../../assets/icons/arrow-sm-right.svg';

function InputMailButton({ label, placeholder, onChange, onClick, showButton, onEnterPressed, refFromParent }) {
  const inputRef = refFromParent !== undefined ? refFromParent : useRef(null);

  const [inputValue, setInputValue] = useState('');

  const handleChange = (newValue) => {
    newValue = newValue.trim();
    setInputValue(newValue);
    onChange(newValue);
  };

  return (
    <div className={'input-mail-button' + (!showButton ? ' input-mail-button__no-button' : '')}>
      <input
        ref={inputRef}
        className={'input-mail-button__input' + (inputValue ? ' input-mail-button__input--active' : '')}
        type='text'
        maxLength='100'
        value={inputValue}
        placeholder={placeholder}
        onChange={(event) => handleChange(event.target.value)}
        onKeyDown={(event) => {
          if (event.key.toLowerCase() === 'enter') {
            event.preventDefault();
            if (onEnterPressed) onEnterPressed();
          }
        }}
      />
      <label
        className='input-mail-button__label'
        onClick={() => {
          inputRef.current?.focus();
        }}
      >
        {label}
      </label>

      {showButton && (
        <button
          className='input-mail-button__button'
          onClick={() => {
            onClick();
          }}
        >
          <img src={ArrowIcon} alt='' />
        </button>
      )}
    </div>
  );
}

InputMailButton.defaultProps = {
  label: 'Label',
  placeholder: 'Email',
  onClick: () => console.log('Click!'),
};

export default InputMailButton;
