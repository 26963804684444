import React, { useRef, useState } from 'react';

function InputText({ label, value, onChange, onEnterPressed, refFromParent, disabled, ...props }) {
  const inputRef = refFromParent !== undefined ? refFromParent : useRef(null);
  const [inputValue, setInputValue] = useState(value);
  const handleChange = (newValue) => {
    setInputValue(newValue);
    onChange(newValue);
  };

  return (
    <div className={'input-text' + (disabled ? ' input-text-disabled' : '')} disabled={disabled}>
      <input
        ref={inputRef}
        className={`input-text__input ${inputValue ? 'input-text__input--active' : ''}`}
        type='text'
        maxLength='100'
        value={inputValue}
        disabled={disabled}
        {...props}
        onChange={(event) => handleChange(event.target.value)}
        onKeyDown={(event) => {
          if (event.key.toLowerCase() === 'enter') {
            event.preventDefault();
            if (onEnterPressed) onEnterPressed();
          }
        }}
      />
      <label
        className='input-text__label'
        onClick={() => {
          inputRef.current?.focus();
        }}
      >
        {label}
      </label>
    </div>
  );
}

InputText.defaultProps = {
  label: 'Label',
};

export default InputText;
