import React, { createRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import useScrollIntoView from '../../../hooks/useScrollIntoView';
import { getTranslation } from '../../../locale/texts';
import SectionLayout from '../../../modules/layout/SectionLayout';
import { StoreContext } from '../../../store/store';
import Accordion from '../../components/Accordion';
import ContactTabs from '../../components/ContactTabs';
import Footer from '../../components/Footer';
import HelpFormSales from '../../components/HelpFormSales';
import HelpFormTech from '../../components/HelpFormTech';
import SectionHeader from '../../components/SectionHeader';

function HelpView({ logout }) {
  const FAQ_LIST = [
    { title: getTranslation('help_faq_1_title'), content: getTranslation('help_faq_1_text') },
    { title: getTranslation('help_faq_2_title'), content: getTranslation('help_faq_2_text') },
    { title: getTranslation('help_faq_3_title'), content: getTranslation('help_faq_3_text') },
    { title: getTranslation('help_faq_4_title'), content: getTranslation('help_faq_4_text') },
    { title: getTranslation('help_faq_5_title'), content: getTranslation('help_faq_5_text') },
    { title: getTranslation('help_faq_6_title'), content: getTranslation('help_faq_6_text') },
  ];

  const [state, dispatch] = useContext(StoreContext);
  const navigate = useNavigate();

  const topElementRef = createRef();
  useScrollIntoView(topElementRef);

  const handleGoToProfile = () => {
    navigate('/profile');
  };

  const handleGoHome = () => {
    navigate('/home');
  };

  return (
    <div className='help-view'>
      {/* Como no hay ningún elemento superior para anclar topElementRef, creo este auxiliar */}
      <div style={{ visibility: 'hidden' }} ref={topElementRef}></div>

      <SectionLayout
        header={
          <SectionHeader
            handleGoHome={handleGoHome}
            handleGoToProfile={handleGoToProfile}
            userData={state.user}
            logout={logout}
          />
        }
        footer={<Footer type='help' />}
      >
        <div className='help-view__wrapper'>
          <div className='help-view__title'>{getTranslation('help_faq_title')}</div>
          <div className='help-view__subtitle'>{getTranslation('help_faq_subtitle')}</div>
          <div className='help-view__faq'>
            {FAQ_LIST.map((question, index) => (
              <Accordion key={index} title={question.title} htmlContent={question.content} />
            ))}
          </div>
          <div className='help-view__title'>{getTranslation('help_contact_title')}</div>
          <div className='help-view__subtitle'>{getTranslation('help_contact_subtitle')}</div>
          <div className='help-view__contact'>
            <ContactTabs
              firstTabTitle={getTranslation('help_contact_tech_tab_title')}
              firstTabContent={<HelpFormTech />}
              secondTabTitle={getTranslation('help_contact_commercial_tab_title')}
              secondTabContent={<HelpFormSales />}
            />
          </div>
        </div>
      </SectionLayout>
    </div>
  );
}

export default HelpView;
