import { POLP_API_URL } from '../config/constants';
import { get } from './requestService';

export const checkLicense = async (license) => {
  const newHeaders = {
    'access-control-allow-origin': '*',
    'content-type': 'application/json',
  };
  const headers = new Headers(newHeaders);
  return get(POLP_API_URL + `license-check?code=${license}`, headers).then((res) => {
    if (res.status === 'success') {
      return res.data?.role;
    } else {
      return false;
    }
  });
};
